/* eslint-disable no-mixed-spaces-and-tabs */
// COMPONENTS
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { withLoader } from "../../../utils/hoc/withLoader.js";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb.jsx";
import WarningModal from "../modals/WarningModal.js";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer.js";
import { useDispatch, useSelector } from "react-redux";
import SelectInvoice from "./header/SelectInvoice.jsx";
import {
  createInvoicePO,
  getAllPOByID,
  getInvoiceDetails,
  saveInvoiceFile,
  setAdditionalDetails,
  setInvoiceType,
  setInvoiceTypeInInvoicePo,
  setPurchasePaymentDetails,
  setUploadedInvoiceFileName,
  updateInvoicePO,
  uploadInvoicePoAttachment,
} from "../../../data/reducers/purchaseOrders/po.reducer.js";
import {
  getAccountIdDetails,
  setSelectedAccountDetails,
  setSelectedTicketDetails,
} from "../../../data/reducers/accounts/accounts.reducers.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import Content from "./content/Content.jsx";
import { formatPlanDate } from "../../../utils/formatter/dateFormatter.js";
import { formatDollarMoney } from "../../../utils/formatter/currencyFormatter.js";

const subtitle = [
  "Scan the invoice to auto fill the form or enter the form details manually.",
  "Select an account to retrieve details from Monday CRM and QuickTix, or enter account details manually.",
  "Please enter ticket details as listed on the invoice.",
  "Please enter today's payment and/or create a payment plan.",
  "",
  "Please provide any additional information relaed to your purchase.",
];

const AddFromInvoiceWizard = ({ setBusy }) => {
  const { id: InvoicePoId, step: initialStep } = useParams();

  const { ticketDetails } = useSelector((state) => state.accounts);

  const dispatch = useDispatch();
  const [step, setStep] = useState(parseInt(initialStep));
  const { UploadInvoice } = useSelector((state) => state.purchaseOrder);
  const { invoiceFile } = useSelector((state) => state.purchaseOrder);
  const fileInputRef = useRef(null);
  const [isVisible, setIsvisible] = useState(false);
  const [modalfor, setModalFor] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(-1);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(-1);
  const [clickedInvoice, setClickedInvoice] = useState(-1);

  const [nextButtonLoader, setNextButtonLoader] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useHistory();

  const data = useSelector((state) => state.accounts);
  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const { allPurchaseOrderDetails } = useSelector(
    (state) => state.purchaseOrder
  );
  const { selectedAccountDetails } = useSelector((state) => state.accounts);

  const setInvoiceGlobalStateFromApi = async () => {
    if (!isNaN(Number(InvoicePoId))) {
      setBusy(true);
      await dispatch(getInvoiceDetails(InvoicePoId));
      setBusy(false);
    }
  };

  useEffect(() => {
    setInvoiceGlobalStateFromApi();
  }, []);

  useEffect(() => {
    var addressbook = allPurchaseOrderDetails?.addressBook;
    var purchaseOrderDetail = allPurchaseOrderDetails?.purchaseOrder;
    dispatch(setUploadedInvoiceFileName(purchaseOrderDetail?.invoiceFileName));
    if (
      purchaseOrderDetail?.subInvoiceType == "Full Season" ||
      purchaseOrderDetail?.subInvoiceType == "Half Season" ||
      (purchaseOrderDetail?.subInvoiceType == "Partial Season" &&
        purchaseOrderDetail.mainInvoiceType == "Regular Season-Full") ||
      (purchaseOrderDetail?.subInvoiceType == "Flex Purchase" &&
        purchaseOrderDetail.mainInvoiceType == "Regular Season-Full")
    ) {
      setSelectedInvoice(-1);
      setSelectedOption1({
        label: purchaseOrderDetail?.subInvoiceType,
        value: purchaseOrderDetail?.subInvoiceType,
      });
      setSelectedOption2(null);
      dispatch(setInvoiceType(2));
    } else if (
      purchaseOrderDetail?.subInvoiceType == "Full Strip" ||
      purchaseOrderDetail?.subInvoiceType == "Half Strip" ||
      purchaseOrderDetail?.subInvoiceType == "Single Round" ||
      purchaseOrderDetail?.subInvoiceType == "Flex Purchase"
    ) {
      dispatch(setInvoiceType(2));
      setSelectedInvoice(-1);
      setSelectedOption1(null);
      setSelectedOption2({
        label: purchaseOrderDetail?.subInvoiceType,
        value: purchaseOrderDetail?.subInvoiceType,
      });
    } else if (purchaseOrderDetail?.subInvoiceType == "Deposit") {
      setSelectedInvoice(3);
      dispatch(setInvoiceType(3));
      setSelectedOption1(null);
      setSelectedOption2(null);
    } else if (purchaseOrderDetail?.subInvoiceType == "Group Purchase") {
      setSelectedInvoice(4);
      dispatch(setInvoiceType(4));
      setSelectedOption1(null);
      setSelectedOption2(null);
    } else if (purchaseOrderDetail?.subInvoiceType == "PSL") {
      setSelectedInvoice(5);
      dispatch(setInvoiceType(5));
      setSelectedOption1(null);
      setSelectedOption2(null);
    } else if (purchaseOrderDetail?.subInvoiceType == "Ticket Associates") {
      setSelectedInvoice(6);
      dispatch(setInvoiceType(6));
      setSelectedOption1(null);
      setSelectedOption2(null);
    }
    dispatch(
      setInvoiceTypeInInvoicePo({
        data: {
          subInvoiceType: purchaseOrderDetail?.subInvoiceType,
        },
      })
    );

    // setting account global details
    dispatch(
      setSelectedAccountDetails({
        name: allPurchaseOrderDetails?.account?.name,
        accountId: allPurchaseOrderDetails?.account?.accountId,
        email: allPurchaseOrderDetails?.account?.email,
        mobileNo: allPurchaseOrderDetails?.account?.mobileNo,
        addressBookId: allPurchaseOrderDetails?.account?.addressBookId,
        address: addressbook
          ? `${addressbook?.address1 ?? ""} ${addressbook?.address2 ?? ""} ${
              addressbook?.city ?? ""
            } ${addressbook?.state ?? ""} ${addressbook?.postalCode ?? ""}`
          : undefined,
        vendorId: allPurchaseOrderDetails?.account?.vendorId,
        vendorName: allPurchaseOrderDetails?.vendorName,
        sportId: allPurchaseOrderDetails?.account?.sportId,
        sportName: allPurchaseOrderDetails?.sportName,
        teamId: allPurchaseOrderDetails?.account?.teamId,
        teamName: allPurchaseOrderDetails?.teamName,
      })
    );

    // setting ticket details here.
    dispatch(
      setSelectedTicketDetails({
        season: {
          value: allPurchaseOrderDetails?.purchaseOrder?.seasonId ?? null,
          label: allPurchaseOrderDetails?.seasonName ?? null,
        },
        team: {
          value: allPurchaseOrderDetails?.account?.teamId ?? null,
          label: allPurchaseOrderDetails?.teamName ?? null,
        },
        sport: {
          value: allPurchaseOrderDetails?.account?.sportId ?? null,
          label: allPurchaseOrderDetails?.sportName ?? null,
        },
        bestPrice: allPurchaseOrderDetails?.purchaseOrder?.bestPrice ?? "",
        regionalFees:
          allPurchaseOrderDetails?.purchaseOrder?.regionalFees ?? "",
        taxFees: allPurchaseOrderDetails?.purchaseOrder?.taxFees ?? "",
        processingFees:
          allPurchaseOrderDetails?.purchaseOrder?.processingFees ?? "",
        convenienceFees:
          allPurchaseOrderDetails?.purchaseOrder?.convenienceFees ?? "",
        additionalFees:
          allPurchaseOrderDetails?.purchaseOrder?.additionalFees ?? "",
        isTicketPurchasesInCurrentSeason:
          allPurchaseOrderDetails?.purchaseOrder
            ?.isTicketPurchasesInCurrentSeason,
        firstPuchaseDate: allPurchaseOrderDetails?.purchaseOrder
          ?.firstPuchaseDate
          ? formatDate(allPurchaseOrderDetails?.purchaseOrder?.firstPuchaseDate)
          : null ?? null,
        TableData: allPurchaseOrderDetails?.accountLocations ?? [],
      })
    );

    //setting purchase Order details.
    dispatch(
      setPurchasePaymentDetails({
        data: {
          description: allPurchaseOrderDetails?.purchaseOrder?.description,
          purchaseOrderDate: allPurchaseOrderDetails?.purchaseOrder
            ?.purchaseOrderDate
            ? formatDate(
                allPurchaseOrderDetails?.purchaseOrder?.purchaseOrderDate
              )
            : null,
          totalScheduled:
            allPurchaseOrderDetails?.purchaseOrder?.totalScheduled,
          amountPaid: parseFloat(
            allPurchaseOrderDetails?.purchaseOrder?.amountPaidToday
          ),
          remember: allPurchaseOrderDetails?.purchaseOrder?.amountPaidToday
            ? true
            : false,
        },
        calculated: allPurchaseOrderDetails?.paymentPlans?.map((val) => ({
          id: val?.id,
          amount: parseFloat(val.amount),
          date: formatPlanDate(val.paymentDate),
          paymentPlansId: val?.paymentPlansId,
          chargesId: val?.chargesId,
        })),
      })
    );

    // setting payment plan details.
    dispatch(
      setPurchasePaymentDetails({
        calculated: allPurchaseOrderDetails?.paymentPlans?.map((val) => ({
          id: val?.id,
          amount: parseFloat(val.amount),
          date: formatPlanDate(val.paymentDate),
          paymentPlansId: val?.paymentPlansId,
          chargesId: val?.chargesId,
        })),
      })
    );

    const maskingData = allPurchaseOrderDetails?.maskingInstuctions?.map(
      (values) => {
        let generateMaskingData = {};
        for (let i = 0; i < values?.generateMasking?.length; i++) {
          generateMaskingData = {
            ...generateMaskingData,
            [values?.generateMasking[i].day]:
              values?.generateMasking[i].maskingRow,
          };
        }

        return {
          ...values,
          maskinggenerated:
            values?.generateMasking &&
            values?.generateMasking?.length > 0 &&
            values.generateMasking[0].maskingRow
              ? true
              : false,
          generateMasking: generateMaskingData,
        };
      }
    );

    const listSkyBoxMappedData =
      allPurchaseOrderDetails?.purchaseOrderSkyBoxRef?.map((item, index) => {
        return {
          id: index + 1,
          value: {
            id: item.skyBoxPurchaseId,
            allocatableAmount: item.allocatedSkyBoxAmount,
            total: item.totalSkyboxValue,
            staticAllocatableAmount:
              item.remainingSkyboxValue + item.allocatedSkyBoxAmount,
          },
        };
      });
    dispatch(
      setAdditionalDetails({
        inventory: {
          internalNotes:
            allPurchaseOrderDetails?.purchaseOrder?.internalNotes
              ?.split(",")
              .map((item) => item.trim()) ?? [],
          attendanceBreakdown:
            allPurchaseOrderDetails?.purchaseOrder?.attendenceBreakdown ?? null,
          additionalTags:
            allPurchaseOrderDetails?.purchaseOrder?.inventorytag
              ?.split(",")
              ?.filter((x) => x)
              ?.map((item) => {
                return { label: item.trim(), value: item.trim() };
              }) ?? [],
          isGoogleSheet: allPurchaseOrderDetails?.purchaseOrder?.isgooglesheet
            ? true
            : false,
          isMondayCRM: allPurchaseOrderDetails?.purchaseOrder?.isMondaycrm
            ? true
            : false,
        },
        masking: maskingData,
        listSkyBoxRefs: listSkyBoxMappedData ?? [],
      })
    );
  }, [allPurchaseOrderDetails]);
  useEffect(() => {
    navigate.push(`/purchase/add-invoice/${InvoicePoId}/${step}`);
  }, [step]);

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatAmount = (amount) => {
    if (typeof amount === "string") {
      let formattedAmount = amount;
      if (formattedAmount.includes("$")) {
        formattedAmount = formattedAmount.replace("$", "").trim();
      }
      if (formattedAmount.startsWith("USD")) {
        formattedAmount = formattedAmount.replace("USD", "").trim();
      }
      // Remove commas
      formattedAmount = formattedAmount.replace(/,/g, "");
      return formattedAmount;
    }
    return amount;
  };

  const handleUpdateInvoicePO = async (isIncrease, initialStep = step) => {
    try {
      setSubmitLoading(true);
      const ticketDetails = data.ticketDetails;
      setNextButtonLoader(true);
      let obj = {};
      if (initialStep == 0) {
        obj = {
          Id: Number(InvoicePoId),
          Step: 0,
          PurchaseOrderTypeRequest: {
            mainType: selectedOption1
              ? "Regular Season-Full"
              : selectedOption2
              ? "Post Season"
              : selectedInvoice == 3
              ? "Deposit"
              : selectedInvoice == 4
              ? "Group Purchase"
              : selectedInvoice == 5
              ? "PSL"
              : selectedInvoice == 6
              ? "Ticket Associates"
              : null,
            subType: selectedOption1?.label
              ? selectedOption1?.value
              : selectedOption2?.value
              ? selectedOption2?.value
              : selectedInvoice == 3
              ? "Deposit"
              : selectedInvoice == 4
              ? "Group Purchase"
              : selectedInvoice == 5
              ? "PSL"
              : selectedInvoice == 6
              ? "Ticket Associates"
              : null,
            isClear: purchaseOrderData?.UploadInvoiceFileName ? false : true,
          },
        };
      }
      if (initialStep == 1) {
        const account = selectedAccountDetails;
        if (
          !account.name ||
          !account.accountId ||
          !account.email ||
          !account.addressBookId ||
          !account.vendorId
        ) {
          setSubmitLoading(false);
          setNextButtonLoader(false);
          dispatch(errorMessage("Please fill required fields."));
          return false;
        }
        const feeDetailsInformation = purchaseOrderData?.globalFeeInputs;
        obj = {
          Id: Number(InvoicePoId),
          Step: 1,
          PurchaseOrderAccountRequest: {
            accountId: selectedAccountDetails?.accountId,
            vendorId: selectedAccountDetails?.vendorId,
          },
          PurchaseOrderFeesRequest: feeDetailsInformation
            ? feeDetailsInformation.map((item) => {
                return {
                  label: item?.name,
                  fees: formatDollarMoney(item?.code),
                };
              })
            : [],
        };
      }
      if (initialStep === 2) {
        const {
          season,
          sport,
          team,
          firstPuchaseDate,
          isTicketPurchasesInCurrentSeason,
          TableData,
        } = ticketDetails;

        const feeDetailsInformation = purchaseOrderData?.globalFeeInputs;
        const masking = purchaseOrderData.additionalDetails.masking;
        // Collect missing required fields
        const missingFields = [];
        if (selectedInvoice != 3 && selectedInvoice != 4) {
          if (!season?.value) missingFields.push("Season");
        }
        if (!sport?.value) missingFields.push("Sport");
        if (!team?.value) missingFields.push("Team");
        if (isTicketPurchasesInCurrentSeason && !firstPuchaseDate)
          missingFields.push("First Purchase Date");

        feeDetailsInformation?.forEach((data) => {
          if (!data?.name) {
            missingFields.push(`Fee detail name is not provided`);
          }
          if (!data?.code) {
            missingFields.push(`Fee detail value is not provided`);
          }
        });

        // Validate TableData and collect missing fields
        TableData?.forEach((data, index) => {
          if (!data.row)
            missingFields.push(`Row in Ticket Details Table item ${index + 1}`);
          if (!data.lowSeat)
            missingFields.push(
              `Low Seat in Ticket Details Table item ${index + 1}`
            );
          if (!data.highSeat)
            missingFields.push(
              `High Seat in Ticket Details Table item ${index + 1}`
            );
          if (!data.section)
            missingFields.push(
              `Section in Ticket Details Table item ${index + 1}`
            );
          if (!data.qty)
            missingFields.push(
              `Quantity in Ticket Details Table item ${index + 1}`
            );
        });

        if (missingFields.length > 0) {
          const msg = `Please fill the required fields:`;
          setNextButtonLoader(false);
          dispatch(errorMessage(msg));
          return false;
        }
        for (let i = 0; i < ticketDetails.TableData.length; i++) {
          if (
            parseFloat(ticketDetails.TableData[i].highSeat) <
            parseFloat(ticketDetails.TableData[i].lowSeat)
          ) {
            setNextButtonLoader(false);
            dispatch(
              errorMessage("High seat should be greater than low seat.")
            );
            return false;
          }
        }
        console.log(ticketDetails.TableData);
        console.log(feeDetailsInformation);
        console.log(masking);
        obj = {
          Id: Number(InvoicePoId),
          Step: 2,
          PurchaseOrderTicketRequest: {
            SeasonId: ticketDetails.season?.value,
            SportId: ticketDetails.sport?.value,
            TeamId: ticketDetails.team?.value,
            isTicketPurchasesInCurrentSeason:
              ticketDetails.isTicketPurchasesInCurrentSeason ?? false,
            firstPuchaseDate: ticketDetails.isTicketPurchasesInCurrentSeason
              ? ticketDetails.firstPuchaseDate
              : undefined,
            Location: ticketDetails.TableData.map((data) => ({
              Id: data.id,
              Row: data.row,
              LowSeat: data.lowSeat,
              HighSeat: data.highSeat,
              Section: data.section,
              qty: Number(data.qty),
              price: Number(data.price),
              EventDate: data.eventDate,
            })),
            maskingInstructions: masking?.length
              ? masking.map((mask) => ({
                  id: mask.id,
                  maskingRow: mask.maskingRow?.toString(),
                  section: mask.section?.toString(),
                  maximumRow: mask.maximumRow?.toString(),
                  rowExclusion: mask.rowExclusion?.toString(),
                  possibleMaskingRows: mask.possibleMaskingRows?.toString(),
                  generateMasking: Object.entries(mask.generateMasking)?.map(
                    ([day, value]) => ({
                      Day: day,
                      MaskingRow: value?.toString(),
                    })
                  ),
                }))
              : undefined,
          },
          PurchaseOrderFeesRequest: feeDetailsInformation
            ? feeDetailsInformation.map((item) => {
                return {
                  id: item.id,
                  label: item?.name,
                  fees: item?.code,
                };
              })
            : [],
        };
      } else if (initialStep === 3) {
        // allow user to navigate on next page
        if (!purchaseOrderData.additionalDetails?.inventory?.isGoogleSheet) {
          dispatch(
            setAdditionalDetails({
              ...purchaseOrderData.additionalDetails,
              inventory: {
                ...purchaseOrderData.additionalDetails?.inventory,
                isGoogleSheet: true,
              },
            })
          );
        }
        const purchasePaymentPlan = purchaseOrderData?.PaymentPlan;
        const purchase = purchaseOrderData?.purchasePaymentDetails;
        if (
          !purchase?.description ||
          !purchase?.totalScheduled ||
          !purchase?.purchaseOrderDate
        ) {
          setNextButtonLoader(false);
          dispatch(errorMessage("Please fill required fields."));
          return false;
        }
        obj = {
          Id: Number(InvoicePoId),
          Step: 3,
          purchasePaymentPlan: {
            plans: purchasePaymentPlan?.length
              ? purchasePaymentPlan?.map((plan) => ({
                  id: plan?.id ? plan?.id : null,
                  paymentDate: formatDate(plan.date),
                  amount: formatAmount(plan.amount)
                    ? formatAmount(plan.amount)
                    : 0,
                  chargesId: plan.chargesId ? plan.chargesId : null,
                  // matchingTransaction: plan.matchingTransaction, // Uncomment if needed
                }))
              : [],
          },
          purchaseDetails: {
            description:
              purchaseOrderData?.purchasePaymentDetails?.description ?? "",
            totalScheduled:
              purchaseOrderData?.purchasePaymentDetails?.totalScheduled ?? 0,
            purchaseOrderDate:
              purchaseOrderData?.purchasePaymentDetails?.purchaseOrderDate,
            amountPaidToday: purchaseOrderData?.purchasePaymentDetails
              ?.amountPaid
              ? parseFloat(
                  purchaseOrderData?.purchasePaymentDetails?.amountPaid
                )
              : null,
          },
        };
      } else if (initialStep === 4) {
        const additionalDetails = purchaseOrderData.additionalDetails.inventory;
        const listSkyBoxRefs =
          purchaseOrderData?.additionalDetails?.listSkyBoxRefs;
        const masking = purchaseOrderData.additionalDetails.masking;
        const errors = [];

        if (
          !additionalDetails.isGoogleSheet &&
          !additionalDetails.isMondayCRM
        ) {
          errors.push(
            "At least one of Google Sheet or Monday CRM must be selected."
          );
        }

        if (errors.length > 0) {
          setNextButtonLoader(false);

          dispatch(errorMessage(errors.join(" ")));
          return false;
        }
        var listskybox = listSkyBoxRefs?.filter((item) => {
          return item?.value?.id;
        });
        //showing message in the frontend if any of the allocated skybox has more value than allocatable value
        let shouldReturn = false;
        for (let i = 0; i < listskybox?.length; i++) {
          if (
            listskybox[i]?.value?.allocatableAmount >
            listskybox[i]?.value?.staticAllocatableAmount
          ) {
            dispatch(
              errorMessage(
                "Allotted value should be less than allocatable value"
              )
            );
            setNextButtonLoader(false);
            shouldReturn = true;
            break;
          }
        }

        if (shouldReturn) {
          return false;
        }
        obj = {
          Id: Number(InvoicePoId),
          Step: 5,
          purchaseOrderAdditionalDetailsRequest: {
            internalNotes: additionalDetails?.internalNotes?.join(","),
            attendenceBreakdown: additionalDetails?.attendanceBreakdown,
            maskingInstructions: masking?.length
              ? masking.map((mask) => ({
                  id: mask.id,
                  maskingRow: mask.maskingRow?.toString(),
                  section: mask.section?.toString(),
                  maximumRow: mask.maximumRow?.toString(),
                  rowExclusion: mask.rowExclusion?.toString(),
                  possibleMaskingRows: mask.possibleMaskingRows?.toString(),
                  generateMasking: Object.entries(mask.generateMasking)?.map(
                    ([day, value]) => ({
                      Day: day,
                      MaskingRow: value?.toString(),
                    })
                  ),
                }))
              : undefined,
            inventoryTag: additionalDetails?.additionalTags
              ?.map((item) => item.value)
              ?.join(","),
            isgooglesheet: additionalDetails.isGoogleSheet ? true : false,
            isMondaycrm: additionalDetails.isMondayCRM ? true : false,

            listSkyboxRefs: listskybox?.map((item) => {
              if (item?.value?.id) {
                return {
                  SkyBoxPurchaseId: parseInt(item?.value?.id),
                  SkyBoxValue: item?.value?.total,
                  AllocatedSkyBoxAmount: item?.value?.allocatableAmount,
                  AutoSkyBoxMatch: true,
                };
              }
            }),
          },
        };
      } else if (initialStep === 5) {
        obj = {
          Id: Number(InvoicePoId),
          Step: 6,
          purchaseOrderSubmit: {
            isDraft: false,
          },
        };
      }
      const resultAction = await dispatch(updateInvoicePO(obj));
      if (updateInvoicePO.fulfilled.match(resultAction) && obj.Step <= 5) {
        dispatch(
          successMessage(
            obj.Step == 0
              ? "Upload details updated successfully"
              : obj.Step == 1
              ? "Account details updated successfully"
              : obj.Step == 2
              ? "Ticket details updated successfully"
              : obj.Step == 3
              ? "Purchase details updated successfully"
              : obj.Step == 4
              ? "Attachment added successfully"
              : obj.Step == 5
              ? "Additional details updated successfully"
              : ""
          )
        );
        if (
          initialStep == 0 &&
          purchaseOrderData?.UploadInvoiceFileName &&
          UploadInvoice
        ) {
          dispatch(
            saveInvoiceFile({ file: invoiceFile, id: Number(InvoicePoId) })
          );
        }
        if (!isIncrease) {
          setStep((prev) => prev + 1);
        }
      } else {
        if (resultAction.error) {
          dispatch(errorMessage(resultAction.error.message));
        }
      }
      setNextButtonLoader(false);
      return true;
    } catch (error) {
      setNextButtonLoader(false);
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
      return false;
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleButtonClick = (event, value) => {
    if (event) {
      event.preventDefault();
      event.target.value = "";
    }

    setIsvisible(true);
    setModalFor("file");

    if (value) {
      fileInputRef.current.click();
      setIsvisible(false);
      setModalFor("");
    } else if (!value && !event) {
      setIsvisible(false);
      setModalFor("");
    }
  };

  const HandleInvoiceType = (number, value) => {
    if (selectedInvoice == number) {
      return;
    }
    if (number) {
      setSelectedNumber(number);
    }

    if (value) {
      setSelectedInvoice(selectedNumber);
      setSelectedOption1(null);
      setSelectedOption2(null);
      setIsvisible(false);
      setModalFor("invoice");
    } else {
      setIsvisible(true);
      setModalFor("invoice");
    }

    if (!value && !number) {
      setIsvisible(false);
      setModalFor("");
    }
  };

  // final step
  const handleSubmitPoInvoice = async () => {
    try {
      setSubmitLoading(true);
      const option =
        selectedOption1?.label || selectedOption2?.label || selectedInvoice;
      if (option == -1) {
        return dispatch(errorMessage("Please select the Invoice Type"));
      }

      setNextButtonLoader(true);
      if (purchaseOrderData.UploadInvoiceFileName) {
        const purchasePaymentPlan = purchaseOrderData.PaymentPlan;
        const ticketDetails = data.ticketDetails;
        const additionalDetails = purchaseOrderData.additionalDetails.inventory;
        const masking = purchaseOrderData.additionalDetails.masking;
        const obj = {
          Id: Number(InvoicePoId),
          Step: 6,
          description: "string",
          vendorId: data?.selectedAccountDetails?.vendorId,
          accountId: Number(data?.selectedAccountDetails?.accountId),
          totalScheduled: 0,
          purchaseOrderDate: "2024-08-21T06:10:08.150Z",
          seasonId: 0,
          listSkyboxRefs: [
            {
              skyBoxPurchaseId: 0,
              allocatedSkyBoxAmount: 0,
              autoSkyBoxMatch: true,
            },
          ],
          mainInvoiceType: selectedOption1
            ? "Regular Season-Full"
            : selectedOption2
            ? "Post Season"
            : selectedInvoice == 3
            ? "Deposit"
            : selectedInvoice == 4
            ? "Group Purchase"
            : selectedInvoice == 5
            ? "PSL"
            : selectedInvoice == 6
            ? "Ticket Associates"
            : null,
          subInvoiceType: selectedOption1?.label
            ? selectedOption1?.value
            : selectedOption2?.value
            ? selectedOption2?.value
            : selectedInvoice == 3
            ? "Deposit"
            : selectedInvoice == 4
            ? "Group Purchase"
            : selectedInvoice == 5
            ? "PSL"
            : selectedInvoice == 6
            ? "Ticket Associates"
            : null,

          purchaseOrderSubmit: {
            isDraft: false,
          },
          purchasePaymentPlan: {
            plans: purchasePaymentPlan?.map((plan) => ({
              paymentDate: formatDate(plan.date),
              amount: formatAmount(plan.amount),
              // matchingTransaction: plan.matchingTransaction, // Uncomment if needed
            })),
          },
          purchaseOrderAdditionalDetailsRequest: {
            internalNotes: additionalDetails?.internalNotes?.join(","),
            attendenceBreakdown: additionalDetails?.attendanceBreakdown,
            maskingInstructions: masking.length
              ? masking.map((mask) => ({
                  maskingRow: mask.maskingRow?.toString(),
                  maskingVariation: mask.maskingVariation?.toString(),
                  maximumRow: mask.maximumRow?.toString(),
                  rowExclusion: mask.rowExclusion?.toString(),
                  generateMasking: Object.entries(mask.generateMasking)?.map(
                    ([day, value]) => ({
                      Day: day,
                      MaskingRow: value?.toString(),
                    })
                  ),
                }))
              : undefined,
            inventoryTag: additionalDetails?.additionalTags
              ?.map((item) => item.value)
              ?.join(","),
            isgooglesheet: additionalDetails?.isGoogleSheet ? true : false,
            isMondaycrm: additionalDetails?.isMondayCRM ? true : false,
          },
          PurchaseOrderTicketRequest: {
            SeasonId: ticketDetails.season?.value,
            SportId: ticketDetails.sport?.value,
            TeamId: ticketDetails.team?.value,
            isTicketPurchasesInCurrentSeason:
              ticketDetails.isTicketPurchasesInCurrentSeason ?? false,
            firstPuchaseDate: ticketDetails.isTicketPurchasesInCurrentSeason
              ? ticketDetails.firstPuchaseDate
              : undefined,
            Location: ticketDetails.TableData.map((data) => ({
              Row: data.row,
              LowSeat: data.lowSeat,
              HighSeat: data.highSeat,
              Section: data.section,
              qty: Number(data.qty),
              price: Number(data.price),
              date: data.date,
            })),
            BestPrice: ticketDetails.bestPrice
              ? ticketDetails.bestPrice
              : undefined,
            RegionalFees: ticketDetails.regionalFees
              ? ticketDetails.regionalFees
              : undefined,
            TaxFees: ticketDetails.taxFees ? ticketDetails.taxFees : undefined,
            ProcessingFees: ticketDetails.processingFees
              ? ticketDetails.bestPrice
              : undefined,
            ConvenienceFees: ticketDetails.convenienceFees
              ? ticketDetails.convenienceFees
              : undefined,
            AdditionalFees: ticketDetails.additionalFees
              ? ticketDetails.additionalFees
              : undefined,
          },
        };
        const response = await dispatch(updateInvoicePO(obj));
        if (response.payload.data.id) {
          navigate.push("/purchases");

          return dispatch(
            successMessage("Successfully created invoice purchase order")
          );
        } else
          return dispatch(
            errorMessage("Failed to create invoice purchase order")
          );
      } else {
        const obj = {
          Id: Number(InvoicePoId),
          Step: 6,
          purchaseOrderSubmit: {
            isDraft: false,
          },
        };

        const resultAction = await dispatch(updateInvoicePO(obj));

        if (updateInvoicePO.fulfilled.match(resultAction)) {
          navigate.push("/purchases");

          dispatch(
            successMessage("Successfully created invoice purchase order")
          );
        } else {
          setNextButtonLoader(false);
          if (resultAction.error) {
            dispatch(errorMessage(resultAction.error.message));
          }
        }
      }
      setNextButtonLoader(false);
    } catch (error) {
      setNextButtonLoader(false);
      return dispatch(errorMessage(error?.message ?? "Something went wrong"));
    } finally {
      setSubmitLoading(false);
    }
  };
  const { ispendingPo } = useSelector((state) => state.purchaseOrder);

  return (
    <>
      <div
        className="px-4"
        style={
          {
            // height: "90vh",
          }
        }
      >
        <ModalBreadcrumb
          ispendingPo={ispendingPo}
          breadcrumbLinks={[
            {
              to: ispendingPo ? "/pendingPO" : "/purchases", // Link based on isPendingPO
              name: ispendingPo ? "Pending PO" : "Purchase Order", // Name based on isPendingPO
              active: false,
            },
            {
              name: ispendingPo
                ? allPurchaseOrderDetails?.purchaseOrder?.subInvoiceType
                : "Add From Invoice",
              active: true,
            },
          ]}
          fontSize={14}
          padding={13}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 24,
          }}
        >
          {/* Header Section */}
          <SelectInvoice
            HandleInvoiceType={HandleInvoiceType}
            selectedInvoice={selectedInvoice}
            selectedOption1={selectedOption1}
            selectedOption2={selectedOption2}
            setSelectedInvoice={setSelectedInvoice}
            setSelectedOption1={setSelectedOption1}
            setSelectedOption2={setSelectedOption2}
            setStep={setStep}
            step={step}
          />

          {/* Render sections */}
          <Content
            setNextButtonLoader={setNextButtonLoader}
            selectedOption1={selectedOption1}
            selectedOption2={selectedOption2}
            step={step}
            setStep={setStep}
            selectedInvoice={selectedInvoice}
            purchaseOrderData={purchaseOrderData}
            handleUpdateInvoicePO={handleUpdateInvoicePO}
            handleSubmitPoInvoice={handleSubmitPoInvoice}
            submitLoading={submitLoading}
            subtitle={subtitle}
            nextButtonLoader={nextButtonLoader}
          />
        </div>
      </div>

      {isVisible && (
        <WarningModal
          isVisible={isVisible}
          handleSubmit={handleButtonClick}
          handleVisibility={() => setIsvisible(false)}
          type={modalfor}
          hanldeSubmitchangeInvoice={HandleInvoiceType}
          selectedInvoice={selectedInvoice}
          selectedNumber={selectedNumber}
          selectedOption1={selectedOption1}
          selectedOption2={selectedOption2}
        />
      )}
    </>
  );
};

export default withLoader(AddFromInvoiceWizard);
