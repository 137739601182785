import React, { useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import Down from "../../images/downarrow.svg";
import Up from "../../images/uparrow.svg";
import HeaderPart from "../../shared/components/table/parts/HeaderPart";
import FooterPart from "../../shared/components/table/parts/FooterPart";
import TableHead from "../../shared/components/table/parts/TableHead";
import TableRowCard from "../../shared/components/table/TableRowCard";
import Loader from "../../shared/components/loader/Loader";
import { columnError } from "../../shared/components/table/data/error";
import moment from "moment";
import NextWindowIcon from "../../images/next_window.svg";
import EyeIcon from "../../images/view.svg";
import { Button } from "react-bootstrap";


function Row(props) {
  const {
    ind,
    row,
    open,
    setOpen,
    data,
    setAddEditEventModalVisibility,
    setSelectedTickets,
    selectedRecord,
    setSelectedRecord,
    setShowRevokeEventModal,
  } = props;

  const eventDropCol = [
    {
      columnName: "Email",
      render: (data) => <>{data?.email}</>,
    },
    {
      columnName: "Section",
      render: (data) => <>{data?.section}</>,
    },
    {
      columnName: "Row",
      render: (data) => <>{data?.row}</>,
    },
    {
      columnName: "Seat",
      render: (data) => (
        <>
          {data?.seat?.split("-")[0] === data?.seat?.split("-")[1]
            ? data?.seat?.split("-")[0]
            : data?.seat}
        </>
      ),
    },

    {
      columnName: "Qty",
      render: (data) => <>{data?.quantity}</>,
    },

    {
      columnName: "Status",
      render: (data) => <>{data?.statusEmail}</>,
    },

    {
      columnName: "Actions",
      render: (data) => (
        <>
          {selectedRecord?.status === "Active" ? (
            <>
              <small className="table-row-card-actions d-flex">
                {data?.statusEmail !== "Unavailable" && (
                  <Button
                    variant="link"
                    className="table-action-button blue_bg"
                    onClick={() => {
                      setSelectedRecord({
                        ...selectedRecord,
                        eventName: selectedRecord?.name,
                        data,
                      });
                      setSelectedTickets({
                        open: null,
                        section: null,
                        row: null,
                        tickets: [],
                        ticketsId: [],
                      });
                      setAddEditEventModalVisibility(true);
                    }}
                  >
                    <img src={NextWindowIcon} alt="Next Window Icon" />
                  </Button>
                )}
                {data?.statusEmail !== "Available" && (
                  <Button
                    variant="link"
                    className="table-action-button gray_bg"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedRecord({
                        ...selectedRecord,
                        eventName: selectedRecord?.name,
                        data,
                      });
                      setShowRevokeEventModal(true);
                    }}
                  >
                    <img
                      src={EyeIcon}
                      style={{
                        marginLeft: "-35%",
                        marginTop: "-30%",
                        zoom: "130%",
                      }}
                      alt="Eye Icon"
                    />
                  </Button>
                )}
              </small>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const customStyles = { flexGrow: 1, flexBasis: 0, textAlign: "center" };

  return (
    <>
      <div
        className="my-3 d-flex justify-content-between px-3 align-items-center"
        style={{
          minHeight: "64px",
          boxShadow: "0px 2px 6px rgb(0 0 0 / 5%)",
          backgroundColor: "white",
        }}
      >
        <small style={customStyles} className="mx-2">
          {row?.league}
        </small>
        <small style={customStyles} className="mx-2">
          {row?.name}
        </small>
        <small style={customStyles} className="mx-2">
          {row?.teamName}
        </small>
        <small style={customStyles} className="mx-2" align="center">
          {row?.venueName}
        </small>
        <small style={customStyles} className="mx-2" align="center">
          {moment(row?.eventDate).format("MM/DD/YYYY h:mm A")}
        </small>
        <small style={customStyles} className="mx-2" align="center">
          {row?.quantity}
        </small>
        <small style={customStyles} className="mx-2" align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setSelectedRecord(row);
              if (open[ind]) {
                setOpen(Array(data?.length).fill(false));
              } else {
                const temp = Array(data?.length).fill(false);
                temp[ind] = !temp[ind];
                setOpen(temp);
              }
            }}
          >
            <img src={open[ind] ? Up : Down} alt="up down image" />
          </IconButton>
        </small>
      </div>
      <div
        style={{
          flexGrow: 1,
          flexBasis: 0,
          backgroundColor: "#FBFBFB",
        }}
      >
        <Collapse in={open[ind]} timeout="auto" unmountOnExit>
          <div style={{ maxWidth: row?.eventsLists?.length > 3 && "98.6%" }}>
            <TableHead columns={eventDropCol} bcolor="#e8f1fb" color="black" />
          </div>

          <TableContainer
            sx={{
              maxHeight: 300,
            }}
          >
            <div className="table-body">
              {row?.eventsLists?.length > 0 ? (
                row?.eventsLists.map((d) => (
                  <TableRowCard
                    key={d?.id}
                    columns={d?.nodata ? columnError : eventDropCol}
                    rowData={d}
                  />
                ))
              ) : (
                <h5 className="p-5 text-center">No records to display</h5>
              )}
            </div>
          </TableContainer>
        </Collapse>
      </div>
    </>
  );
}

export default function CollapsibleTable({
  headerName,
  paging,
  columns,
  data,
  selectedTabIndex,
  tabs,
  onTabSelectionChange,
  headOptions,
  headButton,
  setpageNumber,
  setSortField,
  sortDir,
  middleOptions,
  setSortDir,
  sortField,
  setAddEditEventModalVisibility,
  selectedRecord,
  setSelectedRecord,
  setSelectedTickets,
  setShowRevokeEventModal,
  previousPage,
  nextPage,
  paramerterNavigation,
  loading = false,

}) {
  const [open, setOpen] = React.useState([]);

  useEffect(() => {
    setOpen(Array(data?.length).fill(false));
  }, [paging]);

  useEffect(() => {
    setOpen(Array(data?.length).fill(false));
  }, [selectedTabIndex]);

  return (
    <div className="quicktix-table">
      <HeaderPart
        headerName={headerName}
        tabs={tabs}
        headButton={headButton}
        selectedTabIndex={selectedTabIndex}
        onTabSelectionChange={onTabSelectionChange}
        headOptions={headOptions}
        middleOptions={middleOptions}
      />

      <TableHead
        columns={columns}
        setSortField={setSortField}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
      />

      <TableContainer>
        <div className="table-body">
        {loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
            >
              <Loader size="xl" />
            </div>
          )}

          {!loading &&(data?.length > 0 ?(
            data?.map((row, ind) => (
              <Row
                key={ind}
                ind={ind}
                row={row}
                open={open}
                setOpen={setOpen}
                data={data}
                selectedRecord={selectedRecord}
                setSelectedRecord={setSelectedRecord}
                setSelectedTickets={setSelectedTickets}
                setAddEditEventModalVisibility={setAddEditEventModalVisibility}
                setShowRevokeEventModal={setShowRevokeEventModal}
              />
            ))
          ) : (
            <h5 className="p-5 text-center">No records to display</h5>
          ))}
        </div>
      </TableContainer>

      {paging && (
        <FooterPart
          previousPage={previousPage}
          nextPage={nextPage}
          paramerterNavigation={true}
          paging={paging}
          setpageNumber={setpageNumber}
          setSortDir={setSortDir}
          sortDir={sortDir}
          sortField={sortField}
          setSortField={setSortField}
        />
      )}
    </div>
  );
}
