import "./upload-email.scss";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { withLoader } from "../../utils/hoc/withLoader";
import Loader from "../../shared/components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//components
import FilterPanel from "../../shared/components/panels/filter-panel/FilterPanel";

// images
import Download from "../../images/download.svg";
import Reset from "../../images/reset.svg";
import AddBtn from "../../images/AddBtn.svg";
import UploadEmail from "../../images/UploadEmail.svg";
import sendEmail from "../../images/send_email.svg";

//reducers
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import EmailRow from "./EmailRow";
import {
  downloadEmailTemplate,
  getUploadEmails,
} from "../../data/reducers/email/upload.email.reducer";
import { successMessage } from "../../data/reducers/alerts/alert.reducer";
import AddEmailModal from "./modal/AddEmailModal";
import UploadModal from "./modal/UploadModal";
import FooterPart from "../../shared/components/table/parts/FooterPart";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";

const EmailUploadDashBoard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//

  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");
  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(40);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [minorLoading, setMinorLoading] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.UPLOAD,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.UPLOAD,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);
  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "name", value: filterUrlData?.name },
        { key: "lastLogin", value: filterUrlData?.lastLogin },
        { key: "email", value: filterUrlData?.email },
      ]);
    }
  }, [filterUrlData]);

  useEffect(() => {
    onSubmit(filterUrlData);
    setFilterArray([
      { key: "searchText", value: filterUrlData?.searchText },
      { key: "name", value: filterUrlData?.name },
      { key: "lastLogin", value: filterUrlData?.lastLogin },
      { key: "email", value: filterUrlData?.email },
    ]);
    history.push(`/upload/email?page=1${searchQuery()}`);
  }, [resultPerPage]);

  //-------------------- Param navigation ---------------//
  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        name: queryParameters.get("name"),
        email: queryParameters.get("email"),
        lastLogin: queryParameters.get("lastLogin"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/upload/email?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(
        `/upload/email?page=${Number(pageNumber) - 1}${searchQuery()}`
      );
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(
      `/upload/email?page=${Number(pageNumber) + 1}${searchQuery()}`
    );
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//
  const filterNavigation = (data) => {
    var url = `/upload/email?page=1`;
    if (data.searchText) url += `&&searchText=${data.searchText}`;
    if (data.name) url += `&&name=${data.name}`;
    if (data.email) url += `&&email=${data.email}`;
    if (data.lastLogin) url += `&&lastLogin=${data.lastLogin}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    const search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  const changePageSizeHandler = (e) => {
    setpageNumber(1);
    setResultPerPage(e.target.value);
  };
  //---------------------------------------------------------------------------------------------------------------//

  // search data
  const onSubmit = async (data) => {
    getEmailData({
      ...data,
      pageNumber: pageNumber,
      PageSize: resultPerPage,
      sortDir,
      sortField,
    });
  };

  const handleRefersh = async () => {
    setloading(true);
    await getEmailData(filterUrlData);
    setloading(false);
    dispatch(successMessage("Refresh Successful"));
  };

  const getEmailData = async (payload) => {
    try {
      setloading(true);
      await dispatch(getUploadEmails(payload));
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  //----------------------------- State and check ----------------------------//

  const [emailArray, setEmailArray] = useState([]);
  const [all, setAll] = useState(false);
  const [showSendAll, setShowAll] = useState(false);
  const { uploadEmailArray, paging } = useSelector(
    (state) => state.uploadEmail
  );

  useEffect(() => {
    if (uploadEmailArray) {
      const checkedArray = uploadEmailArray.map((obj) => {
        return { ...obj, checked: false };
      });
      setEmailArray(checkedArray);
      setAll(false);
    }
  }, [uploadEmailArray]);

  const handleAllCheck = (e) => {
    setAll(e.target.checked);
    selectAll(e.target.checked);
  };

  const selectAll = (state) => {
    const checkedAll = uploadEmailArray.map((obj) => {
      return { ...obj, checked: state };
    });
    setEmailArray(checkedAll);
    setAll(state);
    setShowAll(state);
  };

  const selectOne = (id, state) => {
    var count = 0;
    const checkedOne = emailArray.map((obj) => {
      if (obj.id == id) {
        return { ...obj, checked: state };
      }
      if (obj.checked) count++;
      return obj;
    });

    if (state) count++;

    if (count > 0) setShowAll(true);
    else setShowAll(false);

    setAll(count == checkedOne.length);

    setEmailArray(checkedOne);
  };

  //------------------- Modal ----------------------//
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const closeAddEmailModal = () => {
    setAddModalVisible(false);
  };

  const downloadTemplate = async () => {
    try {
      setMinorLoading(true);
      await dispatch(downloadEmailTemplate());
      setMinorLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="container-fluid pt-3"
      style={{ fontWeight: "500", padding: "1rem 1.5rem" }}
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.UPLOAD,
        });
      }}
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            defaultValues={filterArray}
            setpageNumber={setpageNumber}
            searchPlaceholder="Search for first name, last name, username, or mobile number..."
            onReset={() => {
              navigateToMainPage();
            }}
            dashboard={mixpanel_contants.UPLOAD}
            onSubmit={filterNavigation}
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control placeholder="Name" {...register("name")} />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Email
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        placeholder="Email"
                        {...register("email")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Last Login
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="date"
                        placeholder="Last Login"
                        {...register("lastLogin")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      <div className="emailUpperContainer">
        <div className="headerCon">
          Results per page
          <select
            defaultValue={resultPerPage}
            onChange={(e) => {
              changePageSizeHandler(e);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={40}>40</option>
          </select>
        </div>

        <div className="uploadCon" fluid="sm">
          <Row>
            <Col sm="5" className="title">
              UPLOAD EMAIL
            </Col>
            <Col sm="7">
              <div className="btnSection">
                <Button
                  className="button ms-2 ss-light-button"
                  onClick={handleRefersh}
                >
                  <img
                    className="filter-reset-svg"
                    style={{ left: "-8px" }}
                    src={Reset}
                    alt=" "
                  />
                  Refresh
                </Button>
                <Button
                  disabled={minorLoading}
                  className="button ms-2 ss-light-button"
                  onClick={downloadTemplate}
                >
                  <img
                    className="filter-reset-svg"
                    style={{ left: "-8px" }}
                    src={Download}
                    alt=" "
                  />
                  <span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      Download Template
                      {minorLoading && <Loader />}
                    </div>
                  </span>
                </Button>
                <Button
                  className="button ms-2 ss-light-button"
                  onClick={() => setUploadModalVisible(true)}
                >
                  <img
                    className="filter-reset-svg"
                    style={{ left: "-5px" }}
                    src={UploadEmail}
                    alt=" "
                  />
                  Upload
                </Button>
                <Button
                  className="button ms-2 ss-light-button"
                  onClick={() => setAddModalVisible(true)}
                >
                  <img
                    className="filter-reset-svg"
                    style={{ left: "-5px" }}
                    src={AddBtn}
                    alt=" "
                  />
                  Add
                </Button>
                {/* {showSendAll && <Button className="sendBt" onClick={() => { console.log('multiple send email cliked') }}><img style={{ left: "-5px" }} src={sendEmail} alt=" " />Send Email</Button>} */}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="emailContainer">
        <Row className="headerBar">
          {/* <Col style={{ width: '20px', maxWidth: '20px' }}></Col> */}
          <Col className="left">Email</Col>
          <Col className="left">Password</Col>
          <Col className="left">TM Password</Col>
          <Col className="center">Recovery Email</Col>
          <Col className="center">Last Login</Col>
          <Col className="center">Date Uploded</Col>
          <Col className="center">Last Email Sent</Col>
          <Col className="center">Status</Col>

          {/* <Col className="center">Send Email</Col> */}
        </Row>
        {loading == true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
            }}
          >
            <Loader size="xl" />
          </div>
        ) : (
          emailArray
            .sort((a, b) => {
              const dateA = new Date(a.uploadedOn);
              const dateB = new Date(b.uploadedOn);
              return dateB - dateA;
            })
            .map((val, index) => {
              return <EmailRow data={val} key={index} selectOne={selectOne} />;
            })
        )}
      </div>

      {paging && (
        <FooterPart
          paramerterNavigation={true}
          nextPage={nextPage}
          previousPage={previousPage}
          paging={paging}
          setpageNumber={setpageNumber}
          setSortDir={setSortDir}
          sortDir={sortDir}
          sortField={sortField}
          setSortField={setSortField}

        />
      )}

      {/* ------ Modals -------- */}

      {addModalVisible && (
        <AddEmailModal
          isVisible={addModalVisible}
          minorLoading={minorLoading}
          setMinorLoading={setMinorLoading}
          handleThisClose={closeAddEmailModal}
          onCompleted={() => getEmailData(filterUrlData)}
        />
      )}

      {uploadModalVisible && (
        <UploadModal
          isVisible={uploadModalVisible}
          handleThisClose={() => setUploadModalVisible(false)}
          onUploadCompleted={() => getEmailData(filterUrlData)}
        />
      )}
    </div>
  );
};

export default withLoader(EmailUploadDashBoard);
