import VerticalChargesCard from "../../shared/components/accountActions/charges-card";
import Link from "../../images/Link.svg";
import "../../shared/styles/DashboardStyles.scss";
import { Container } from "react-bootstrap";
import { Link as RouteLink } from "react-router-dom";

const PODetails = ({ details, cards }) => {
  return (
    <>
      <Container className="root pt-3 mb-3 mt-3" fluid>
        <RouteLink className="my-title h-20 d-flex w-25 justify-content-between align-items-center" to={"/vendors/" + details.vendorId}>
          <img className="logo-svg" src={details?.url} alt="logo" />
          <p className="mylogo-title mx-2">{details?.vendorName}</p>
          <img className="link-svg" src={Link} alt="link" />
        </RouteLink>
        <hr className="mt-2 mb-3 hr align-self-center" />
        <div className="body h-75 w-100 d-flex flex-row justify-content-evenly ">
          <div className="my-col d-flex flex-column justify-content-evenly">
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Name</p>
              {details?.accountName && <RouteLink className="text-decoration-none color-black d-flex my-row flex-row align-items-top justify-content-center w-100 h-25" to={"/accounts/" + details.accountId + "?vendorId=" + details?.vendorId}>
                <p className="my-p h-100 w-50">&nbsp;&nbsp;&nbsp;{details?.accountName}</p>
                <img className="link-svg my-p h-100" src={Link} alt="link" width={15} height={15} />
              </RouteLink>}            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Vendor ID</p>
              <p className="my-p h-100 w-50">{details?.vendorId}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Account</p>
              <p className="my-p h-100 w-50">{details?.accountId}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Description</p>
              <p className="my-p h-100 w-50">{details?.description}</p>
            </div>

          </div>

          <div className="my-col d-flex flex-column justify-content-evenly">
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">SkyBox Ref</p>
              <p className="my-p h-100 w-50">{details?.skyBoxRef}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Purchase Order</p>
              <p className="my-p h-100 w-50">{details?.purchaseOrderId}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Source ID</p>
              <p className="my-p h-100 w-50">{details?.sourceId}</p>
            </div>
          </div>

          <div className="my-col d-flex flex-column justify-content-between align-items-end">
            <VerticalChargesCard
              bg="rgba(24, 144, 255, 0.2)"
              fontbg="#0241A0"
              text="Scheduled"
              price={cards?.scheduled}
            />
            <VerticalChargesCard
              bg="rgba(188, 219, 1, 0.2)"
              fontbg="#849900"
              text="Paid"
              price={cards?.paid}
            />
            <VerticalChargesCard
              bg="rgba(255, 0, 0, 0.2)"
              fontbg="#990000"
              text="Balance"
              price={parseFloat(cards.scheduled) - parseFloat(cards.paid)}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default PODetails;
