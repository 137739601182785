import VerticalChargesCard from "../../../shared/components/accountActions/charges-card";
import LinkImg from "../../../images/Link.svg";
import "../../../shared/styles/DashboardStyles.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const AccountDetails = ({ details, cards, tabIndex, paymentPlansCards }) => {
  const getMobileNumbers = (data) => {
    if (!data?.mobileNo) {
      return "-";
    }
    const length = data?.mobileNo?.length;
    const mbNum = data?.mobileNo?.slice(length - 10);
    const formattedMbNO = mbNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    return formattedMbNO;
  };

  return (
    <>
      <Container className="root pt-3 mb-3 mt-3" fluid>
        <Link
          className="my-title h-20 d-flex justify-content-between align-items-center text-decoration-none"
          to={"/vendors/" + details.vendorId}
        >
          <img className="logo-svg" src={details?.url} alt="logo" />
          <p className="mylogo-title">{details?.vendorName}</p>
          <img className="link-svg" src={LinkImg} alt="link" />
        </Link>
        <hr className="mt-2 mb-3 hr align-self-center" />
        <div className="body h-75 w-100 d-flex flex-row justify-content-evenly ">
          <div className="my-col d-flex flex-column justify-content-evenly">
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Name</p>
              {details?.name && (
                <Link
                  className="text-decoration-none color-black d-flex my-row flex-row align-items-top justify-content-center w-100 h-25"
                  to={"/accounts/" + details.accountId + "?vendorId=" + details?.vendorId}
                >
                  <p className="my-p h-100 w-50">
                    &nbsp;&nbsp;&nbsp;{details?.name}
                  </p>
                  <img
                    className="link-svg my-p h-100"
                    src={LinkImg}
                    alt="link"
                    width={15}
                    height={15}
                  />
                </Link>
              )}
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Id</p>
              <p className="my-p h-100 w-50">{details?.accountId ?? "-"}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Sport</p>
              <p className="my-p h-100 w-50">{details?.sportName ?? "-"}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Description</p>
              <p className="my-p h-100 w-50">{details?.description ?? "-"}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Mobile No.</p>
              <p className="my-p h-100 w-50">
                {getMobileNumbers(details) ?? "-"}
              </p>
            </div>
          </div>
          <div className="my-col d-flex flex-column justify-content-evenly">
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Address</p>
              <p className="my-p h-100 w-50">{details?.address ?? "-"}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Email</p>
              <p className="my-p h-100 w-50">{details?.email ?? "-"}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Team</p>
              <p className="my-p h-100 w-50">{details?.teamName ?? "-"}</p>
            </div>
            <div className="my-row d-flex flex-row align-items-center justify-content-between w-100 h-25">
              <p className="my-ph h-100 w-50">Category</p>
              <p className="my-p h-100 w-50">{details?.category ?? "-"}</p>
            </div>
          </div>
          <div className="my-col d-flex flex-column justify-content-between align-items-end">
            <VerticalChargesCard
              bg="rgba(24, 144, 255, 0.2)"
              fontbg="#0241A0"
              text={tabIndex != 4 ? "Past Charges" : "Scheduled"}
              price={
                tabIndex != 4
                  ? cards?.pastCharges
                  : paymentPlansCards?.totalScheduled
              }
            />
            <VerticalChargesCard
              bg="rgba(188, 219, 1, 0.2)"
              fontbg="#849900"
              text={tabIndex != 4 ? "Future Charges" : "Paid"}
              price={
                tabIndex != 4 ? cards?.futureCharges : paymentPlansCards?.paid
              }
            />
            <VerticalChargesCard
              bg="rgba(255, 0, 0, 0.2)"
              fontbg="#990000"
              text={tabIndex != 4 ? "Inventory" : "Balance"}
              price={
                tabIndex != 4 ? cards?.inventory : paymentPlansCards?.balance
              }
            />
            {tabIndex != 4 ? (
              <VerticalChargesCard
                bg="rgba(231, 128, 9, 0.2)"
                fontbg="#B26000"
                text="Account Credits"
                price={cards?.accountCredits}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default AccountDetails;
