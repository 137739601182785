import React, { useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import HeaderPart from "../../../shared/components/table/parts/HeaderPart";
import FooterPart from "../../../shared/components/table/parts/FooterPart";
import TableHead from "../../../shared/components/table/parts/TableHead";
import Row from "../modals/Row";
import EmailRow from "../../email/EmailRow";
import Loader from "../../../shared/components/loader/Loader";

export default function CollapsibleTable({
  headerName,
  paging,
  columns,
  data,
  selectedTabIndex,
  tabs,
  onTabSelectionChange,
  headOptions,
  headButton,
  setpageNumber,
  setSortField,
  onChangeFieldDirection,
  sortDir,
  middleOptions,
  setSortDir,
  sortField,
  renderButtons,
  previousPage,
  nextPage,
  bottomOptions,

  paramerterNavigation,
  loading = false,
  rowLoading = false,
}) {
  const [open, setOpen] = React.useState([]);

  useEffect(() => {
    setOpen(Array(data?.length).fill(false));
  }, []);

  useEffect(() => {
    setOpen(Array(data?.length).fill(false));
  },[paging])

  return (
    <div className="quicktix-table">
      <HeaderPart
        headerName={headerName}
        tabs={tabs}
        headButton={headButton}
        selectedTabIndex={selectedTabIndex}
        onTabSelectionChange={onTabSelectionChange}
        headOptions={headOptions}
        middleOptions={middleOptions}
        bottomOptions={bottomOptions}
      />

      <TableHead
        onChangeFieldDirection={onChangeFieldDirection}
        columns={columns}
        setSortField={setSortField}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
      />

      <TableContainer>
        <div className="table-body">
          {loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
            >
              <Loader size="xl" />
            </div>
          )}

          {!loading &&
            (data?.length > 0 ? (
              data.map((row, ind) =>
                headerName !== "EMAILS" ? (
                  <Row
                    key={ind}
                    ind={ind}
                    row={row}
                    open={open}
                    setOpen={setOpen}
                    loading={rowLoading}
                    data={data}
                    renderButtons={renderButtons}
                    columns={columns}
                  />
                ) : (
                  <EmailRow
                    key={ind}
                    ind={ind}
                    row={row}
                    open={open}
                    setOpen={setOpen}
                    loading={rowLoading}
                    data={data}
                    renderButtons={renderButtons}
                    columns={columns}
                  />
                )
              )
            ) : (
              <h5 className="p-5 text-center">No records to display</h5>
            ))}
        </div>
      </TableContainer>

      {paging && (
        <FooterPart
          previousPage={previousPage}
          nextPage={nextPage}
          paramerterNavigation={true}
          paging={paging}
          setpageNumber={setpageNumber}
          setSortDir={setSortDir}
          sortDir={sortDir}
          sortField={sortField}
          setSortField={setSortField}
        />
      )}
    </div>
  );
}
