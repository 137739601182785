import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";

import Loader from "../loader/Loader";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
import { getAccountCardsInfo } from "../../../data/reducers/accounts/accounts.reducers";
import { setDefaultCard } from "../../../data/services/accounts/default-account.service";

import "./TableStyle.scss";

const TableRowCard = ({ ind,columns, rowData, email, accountId }) => {
  const dispatch = useDispatch();
 
  //----------------- set Default ---------------//
  const setDefault = async () => {
    const response = await setDefaultCard({
      accountId: Number(accountId),
      cardId: rowData.cardId,
    });
    if (response.isSuccessful) {
      dispatch(
        successMessage(
          response.message ? response.message : "Default Card Updated!"
        )
      );
      dispatch(getAccountCardsInfo({ accountId, email }));
    } else {
      dispatch(
        errorMessage(
          response.message ? response.message : "Something Went Wrong!"
        )
      );
    }
  };


  return (
    <div
      className="table-row-card my-3 d-flex justify-content-between px-3 py-2"
      style={{ boxShadow: "1px 1px 10px rgb(50, 50, 50, 0.25)" }}
    >
      {columns?.map((column, index) => (
        <React.Fragment key={index + column.columnName}>
          {column.columnName != "Action" && (
            <small
              key={index}
              style={{
                flexGrow: column.flexGrow > 0 ? column.flexGrow : 1,
                flexBasis: 0,
                textAlign: "center",
                wordWrap: "break-word",
              }}
              className="mx-2"
            >
              {column.render
                  ? // render column according to the `render` function if configured
                    column.render(rowData, index)
                  : // else, render column according to the `columnKey` parameter
                    rowData[column.columnKey]
                }
            </small>
          )}

          {column.columnName == "Action" && rowData.isDefaultCard == true && (
            <small className="mx-4">
              {" "}
              <Button
                className="default"
                variant="outlined"
                id="default"
                disabled
              >
                Default
              </Button>
            </small>
          )}
          {column.columnName == "Action" && rowData.isDefaultCard == false && (
            <small className="mx-4">
              <Button
                className="default"
                variant="outlined"
                id="setDefault"
                onClick={setDefault}
              >
                Set Default
              </Button>
            </small>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default TableRowCard;
