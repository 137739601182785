import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import Add from "../../images/add_blue.svg";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";

import AddEditSeasonModal from "./modals/AddEditSeasonModal";
import { withLoader } from "../../utils/hoc/withLoader";
import Loader from "../../shared/components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

//components
import FilterPanel from "../../shared/components/panels/filter-panel/FilterPanel";
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";

// images
import Export from "../../images/export.svg";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";

import "../../shared/styles/ModalStyles.scss";

//reducers
import { getAccountSportList } from "../../data/reducers/accounts/accounts.reducers";

import Select from "react-select";
import { Controller } from "react-hook-form";
import {
  createSeason,
  deleteSeason,
  exportCSVSeason,
  exportPdfSeason,
  exportSelectedAsCSVSeason,
  exportSelectedAsPdfSeason,
  filterSeason,
  getAllSeason,
  updateSeason,
} from "../../data/reducers/season/season.reducer";
import { formatDate } from "../../utils/formatter/dateFormatter";
import { Link } from "react-router-dom";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";

const SeasonDashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [minorLoading, setMinorLoading] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.SEASON,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.SEASON,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "seasonCode", value: filterUrlData?.seasonCode },
        { key: "seasonName", value: filterUrlData?.seasonName },
        { key: "sportName", value: filterUrlData?.sportName },
        { key: "startDate", value: filterUrlData?.startDate },
        { key: "endDate", value: filterUrlData?.endDate },
      ]);
    }
  }, [filterUrlData, sortDir, sortField]);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        seasonCode: queryParameters.get("seasonCode"),
        seasonName: queryParameters.get("seasonName"),
        sportName: queryParameters.get("sportName"),
        startDate: queryParameters.get("startDate"),
        endDate: queryParameters.get("endDate"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/season?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(`/season?page=${Number(pageNumber) - 1}${searchQuery()}`);
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(`/season?page=${Number(pageNumber) + 1}${searchQuery()}`);
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//

  const filterNavigation = (data) => {
    // var url = `/season?page=${data.searchText ? 1 : page}`
    var url = `/season?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.seasonCode) url += `&&seasonCode=${data.seasonCode}`;
    if (data.seasonName) url += `&&seasonName=${data.seasonName}`;
    if (data.sportName) url += `&&sportName=${data.sportName}`;
    if (data.startDate) url += `&&startDate=${data.startDate}`;
    if (data.endDate) url += `&&endDate=${data.endDate}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    const search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  //selectors
  const seasonList = useSelector((state) => state.season).season;

  const paging = useSelector((state) => state.season).paging;
  const sportDetails = useSelector((state) => state.accounts).sportList;

  //use states
  const [addAccountModalVisible, setAddEditSeasonModalVisibility] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [dropVal, setdropVal] = useState({
    sport: "",
  });
  const [searchData, setsearchData] = useState(null);
  const [removeLocations, setRemoveLocations] = useState(false);

  useEffect(async () => {
    await dispatch(getAccountSportList());
  }, []);

  //----------------------- set Default value ----------------//

  useEffect(() => {
    if (filterUrlData && sportDetails) {
      if (filterUrlData.sportName) {
        sportDetails.map((obj) => {
          if (obj.sportName == filterUrlData.sportName) {
            setdropVal({ ...dropVal, sport: obj });
          }
        });
      }
    }
  }, [filterUrlData, sportDetails]);

  // fetch all address information
  const fetchSeasonsData = async () => {
    try {
      setloading(true);
      const data = {
        pageNumber: page,
        pageSize,
        sortDir,
        sortField,
      };
      const response = await dispatch(getAllSeason(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setloading(false);
    }
  };

  // delete account
  const onDeleteAccount = async (id) => {
    try {
      setMinorLoading("delete")
      const response = await dispatch(deleteSeason(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteConfirmationVisibility(false);
        dispatch(successMessage("Season was deleted successfully"));
        if (searchData) {
          onSubmit(searchData);
        } else {
          fetchSeasonsData();
        }
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false)
    }
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      pageNumber,
      pageSize,
      sortField,
      sortDir,
      searchText: data?.searchText?.trim() || "",
    };

    setloading(true);

    if (
      data?.searchText ||
      data?.seasonCode ||
      data?.seasonName ||
      data?.sportName ||
      data?.startDate ||
      data?.endDate
    ) {
      setsearchData(formData);
      await dispatch(filterSeason(formData));
    } else {
      setsearchData(null);
      await fetchSeasonsData();
    }

    setloading(false);
  };

  // add or update account details
  const onEntrySubmitted = async (data) => {
    if (data?.startDate > data?.endDate) {
      dispatch(errorMessage("Start Date Cannot be Greater Than End Date"));
    } else {
      try {
        const message = data.id
          ? `Season Updated Successfully! 
                    ${removeLocations && data?.copyLocationSeasonId === null
            ? "Locations Removed Successfully"
            : removeLocations && data?.copyLocationSeasonId > 0
              ? "Locations removed and updated successfully"
              : !removeLocations &&
                data?.copyLocationSeasonId !==
                selectedRecord?.copyLocationSeasonId
                ? "Locations copied successfully from selected season."
                : ""
          }`
          : `New Season Created Successfully! ${data.copyLocationSeasonId > 0
            ? "Locations copied successfully from selected season."
            : ""
          }`;
        setMinorLoading("add");
        const action = data.id
          ? updateSeason({ data: data, removeLocations: removeLocations })
          : createSeason(data);
        const response = await dispatch(action);
        if (response.error) {
          dispatch(errorMessage(response.error.message));
        } else {
          dispatch(successMessage(message));
          setAddEditSeasonModalVisibility(false);
          fetchSeasonsData();
        }
      } catch (e) {
        dispatch(errorMessage(e));
      } finally {
        setMinorLoading(false)
        setRemoveLocations(false);
      }
    }
  };

  //address table data
  const seasonCol = [
    {
      columnName: (
        <p className="mb-0">
          Season <br /> Name
        </p>
      ),
      columnKey: "seasonName",
      sort: true,
      sortName: "SeasonName",
      render: (data) => (
        <>
          <Link variant="link" className="ss-link" to={`/season/${data.id}`}>
            {data.seasonName}
          </Link>
        </>
      ),
    },
    {
      columnName: (
        <p className="mb-0">
          Season <br /> Code
        </p>
      ),
      columnKey: "seasonCode",
      sort: true,
      sortName: "SeasonCode",
    },
    {
      columnName: "Sport",
      sort: true,
      sortName: "SportName",
      render: (data) => <>{data.sportName}</>,
    },
    {
      columnName: (
        <p className="mb-0">
          {" "}
          Start <br /> Date{" "}
        </p>
      ),
      sort: true,
      sortName: "StartDate",
      render: (data) => <>{formatDate(data.startDate)}</>,
    },
    {
      columnName: (
        <p className="mb-0">
          End <br /> Date
        </p>
      ),
      sort: true,
      sortName: "EndDate",
      render: (data) => <>{formatDate(data.endDate)}</>,
    },
    {
      columnName: "Actions",
      flexGrow: 0.5,
      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setAddEditSeasonModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt=" " />
          </Button>
          <Button
            variant="link"
            className="table-action-button"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setDeleteConfirmationVisibility(true);
            }}
          >
            <img src={DeleteIcon} alt=" " />
          </Button>
        </small>
      ),
    },
  ];

  const exportSelectedAsCSV = async () => {
    setMinorLoading(true);
    if (searchData) {
      await dispatch(exportSelectedAsCSVSeason(searchData));
    } else {
      await dispatch(exportCSVSeason());
    }
    setMinorLoading(false)
  };

  const onExportSelectedAsPdf = async () => {
    setMinorLoading(true);
    if (searchData) {
      await dispatch(exportSelectedAsPdfSeason(searchData));
    } else {
      await dispatch(exportPdfSeason());
    }
    setMinorLoading(false);
  };

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };
  //table head
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          <Dropdown onClick={(e) => e.currentTarget.blur()}>
            <Dropdown.Toggle
              disabled={minorLoading}
              className="ms-2 ss-light-button button"
              id="dropdown-basic"
            >
              <img className="filter-reset-svg" src={Export} alt=" " />
              <span>
              <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Export
                    {minorLoading && (
                      <Loader />
                    )}
                  </div>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  exportSelectedAsCSV();
                }}
              >
                Export as CSV
              </Dropdown.Item>
              <hr className="hr-full" />
              <Dropdown.Item onClick={onExportSelectedAsPdf}>
                Export as PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            onClick={async (e) => {
              e.currentTarget.blur();
              await setSelectedRecord(undefined);
              await setSelectedRecordId(undefined);
              await setAddEditSeasonModalVisibility(true);
            }}
            variant="primary"
            className="button ss-light-button"
          >
            <img className="filter-reset-svg" src={Add} alt=" " />
            <span>Add</span>
          </Button>
        </div>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.SEASON,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            defaultValues={filterArray}
            dashboard={mixpanel_contants.SEASON}
            setPageNumber={setpageNumber}
            searchPlaceholder="Search for Season Name, Season Code or Sport..."
            onReset={() => {
              setpageNumber(1);
              setsearchData(null);
              navigateToMainPage();
              setdropVal({
                sport: "",
              });
            }}
            onSubmit={filterNavigation}
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Season Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Season Name"
                        {...register("seasonName")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Start Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        // dateFormat="mm-dd-yyyy"
                        placeholder="Start Date"
                        type="date"
                        {...register("startDate")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Season Code
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Season Code"
                        {...register("seasonCode")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      End Date
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="End Date"
                        type="date"
                        {...register("endDate")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Sport
                    </Form.Label>
                    <Col sm={7}>
                      <Controller
                        name="sportName"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <Select
                                required
                                isClearable
                                isInvalid={!!errors.sportName}
                                value={dropVal?.sport}
                                onChange={async (e) => {
                                  field.onChange(e?.sportName);
                                  setdropVal({ ...dropVal, sport: e });
                                }}
                                styles={customStyles}
                                // here we are filtering only those sports which have id more than zero because, sports having id less then or equal to zero are for other purpose
                                options={sportDetails?.filter((x) => x.id > 0)}
                                getOptionLabel={(option) => option.sportName}
                              />
                            </>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      {/* Data Table */}
      <QuickTixTable
        loading={loading}
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="SEASONS"
        paging={paging}
        columns={seasonCol}
        data={seasonList || []}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        renderRow={(rowData, index) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : seasonCol}
            rowData={rowData}
          />
        )}
      />

      {/* Modal Popups */}
      <AddEditSeasonModal
        isVisible={addAccountModalVisible}
        handleClose={() => setAddEditSeasonModalVisibility(false)}
        minorLoading={minorLoading}
        setMinorLoading={setMinorLoading}
        onSubmit={onEntrySubmitted}
        selectedRecord={selectedRecord}
        setRemoveLocation={setRemoveLocations}
      />

      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDeleteAccount(selectedRecordId)}
        minorLoading={minorLoading}
      />
    </div>
  );
};

export default withLoader(SeasonDashboard);
