/* eslint-disable no-mixed-spaces-and-tabs */
//default imports
import React, { useState, useEffect } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

//reducers
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";

import {
  getAllLoans,
  deleteLoan,
  updateLoan,
  createLoan,
  getBankBalance,
  filterLoan,
  headerInfo,
  syncBanking,
  exportSelectedAsCSVLoans,
  exportSelectedAsPdfLoans,
  exportCSVLoan,
  exportPdfLoan,
} from "../../data/reducers/finance/loans.reducer";

//components
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import { withLoader } from "../../utils/hoc/withLoader";
import Loader from "../../shared/components/loader/Loader";
import FilterPanel from "../../shared/components/panels/filter-panel/FilterPanel";
import CardPanel from "../../shared/components/panels/card-panel/CardPanel";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import AddLoan from "./modals/AddEditLoanModal";

// images
import Export from "../../images/export.svg";
import Add from "../../images/add_blue.svg";
import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";

//css
import "./FinanceDashboard.scss";
import { formatDate } from "../../utils/formatter/dateFormatter";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";

function FinanceDashboard() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.FINANCE,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.FINANCE,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    setLoansPageNumber(page);
  }, [page, location]);

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/finance?page=1`);
  };

  const previousPage = () => {
    if (Number(loansPageNumber) > 1) {
      history.push(`/finance?page=${Number(loansPageNumber) - 1}`);
      setLoansPageNumber(Number(loansPageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(`/finance?page=${Number(loansPageNumber) + 1}`);
    setLoansPageNumber(Number(loansPageNumber + 1));
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    const search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  //selectors
  const loansRowData = useSelector((state) => state.loans).loans;
  const bankBalance = useSelector((state) => state.loans).banking;
  const loansPaging = useSelector((state) => state.loans).paging;
  const cards = useSelector((state) => state.loans).cards;
  const syncDate = useSelector((state) => state.loans).syncDate;
  //usestate hooks
  const [addLoanModalVisible, setAddLoanModalVisibility] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [loansPageSize, setLoansPageSize] = useState(40);
  const [loansPageNumber, setLoansPageNumber] = useState(1);
  const [bankpageNumber, setBankpageNumber] = useState(1);
  const [bsortField, setBSortField] = useState(null);
  const [bsortDir, setbSortDir] = useState(true);
  const [lsortField, setlSortField] = useState(null);
  const [lsortDir, setlSortDir] = useState(true);
  const [searchData, setsearchData] = useState(null);
  const [loading, setloading] = useState(false);
  const [minorLoading, setMinorLoading] = useState(false);
  //useeffect
  useEffect(() => {
    fetchLoansData();
    dispatch(getBankBalance());
    dispatch(headerInfo());
    dispatch(syncBanking());
  }, []);

  useEffect(() => {
    if (searchData) {
      onSubmit(searchData);
    } else {
      fetchLoansData();
    }
  }, [loansPageSize, loansPageNumber, lsortDir, lsortField]);

  const fetchLoansData = async () => {
    try {
      setloading(true);
      const data = {
        pageNumber: page,
        pageSize: loansPageSize,
        sortDir: lsortDir,
        sortField: lsortField,
      };
      const response = await dispatch(getAllLoans(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setloading(false);
    }
  };
  const onDeleteLoan = async (id) => {
    try {
      setMinorLoading("delete")
      setloading(true);
      const response = await dispatch(deleteLoan(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteConfirmationVisibility(false);
        fetchLoansData();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setloading(false);
      setMinorLoading(false);
      setSelectedRecordId(null);
    }
  };
  const onSubmit = async (data) => {
    const formData = {
      ...data,
      pageNumber: loansPageNumber,
      pageSize: loansPageSize,
      searchText: data.searchText || "",
      sortField: lsortField,
      sortDir: lsortDir,
    };
    setsearchData(formData);
    setloading(true);
    await dispatch(filterLoan(formData));
    setloading(false);
  };

  const exportSelectedAsCSV = async () => {
    setMinorLoading(true);

    if (searchData) {
      await dispatch(exportSelectedAsCSVLoans(searchData));
    } else {
      await dispatch(exportCSVLoan());
    }
    setMinorLoading(false);
  };

  const onExportSelectedAsPdf = async () => {
    setMinorLoading(true);
    if (searchData) {
      await dispatch(exportSelectedAsPdfLoans(searchData));
    } else {
      await dispatch(exportPdfLoan());
    }
    setMinorLoading(false);
  };

  const onEntrySubmitted = async (data) => {
    try {
      const message = data?.id
        ? `Loan Updated Succesfully`
        : `New Loan Created Succesfully`;
      setloading(true);
      setMinorLoading("add")
      let amount;
      if (typeof data.repaidAmount == "undefined") {
        amount = data.loanAmount;
      } else if (typeof data.loanAmount == "undefined") {
        amount = -data.repaidAmount;
      }

      const action = data?.id
        ? updateLoan({
            id: data.id,
            name: data.name,
            amount: amount,
            date: data.date,
          })
        : createLoan({
            id: data.id,
            name: data.name,
            amount: amount,
            date: data.date,
          });
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        await dispatch(successMessage(message));
        await setAddLoanModalVisibility(false);
        fetchLoansData();
        dispatch(headerInfo());
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false);
      setloading(false);
    }
  };

  const bankingPaging = {
    currentPage: 1,
    totalPages: 1,
    pageSize: bankBalance.length,
    itemsCount: bankBalance.length,
    totalItemsCount: bankBalance.length,
  };
  const cardsData = [
    {
      head: "Total CC balance",
      val:
        cards?.totalCcBalance === null
          ? "N/A"
          : formatMoney(cards.totalCcBalance),
    },
    {
      head: "Total Bank Balance",
      val:
        cards?.totalBankBalance === null
          ? "N/A"
          : formatMoney(cards.totalBankBalance),
    },
    {
      head: "Total Loan Amount",
      val: cards?.loanBalance === null ? "N/A" : formatMoney(cards.loanBalance),
    },
    {
      head: "Total Assets",
      val: cards?.totalAsset === null ? "N/A" : cards.totalAsset,
    },
  ];

  const bankingHeadOptions = () => {
    return (
      <>
        <div className="text-end mt-2 ">
          <Button
            variant="secondary"
            className="ms-2"
            onClick={async () => {
              await dispatch(syncBanking());
              await dispatch(getBankBalance());
            }}
          >
            <span>Sync Accounts</span>
          </Button>
          <br />
          <small style={{ color: "green" }}>Last synced on {syncDate}</small>
        </div>
      </>
    );
  };
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setLoansPageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          <Dropdown>
            <Dropdown.Toggle
              disabled={minorLoading}
              className="ss-light-button button"
              id="dropdown-basic"
            >
              <img className="filter-reset-svg" src={Export} alt=" " />
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Export
                  {minorLoading && <Loader />}
                </div>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => exportSelectedAsCSV()}>
                Export as CSV
              </Dropdown.Item>
              <hr className="hr-half" />
              <Dropdown.Item onClick={() => onExportSelectedAsPdf()}>
                Export as PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            onClick={async () => {
              await setSelectedRecord(undefined);
              await setSelectedRecordId(undefined);
              await setAddLoanModalVisibility(true);
            }}
            variant="primary"
            className="button ss-light-button"
          >
            <img className="filter-reset-svg" src={Add} alt=" " />
            <span>Add</span>
          </Button>
        </div>
      </>
    );
  };
  const bankingCol = [
    {
      columnName: "Date",
      flexGrow: 0.5,
      render: (data) => <>{formatDate(data?.date)}</>,
    },
    {
      columnName: (
        <>
          {" "}
          Bank <br /> Name{" "}
        </>
      ),
      columnKey: "bankName",
    },
    {
      columnName: "Balance",
      render: (data) => <>{formatMoney(data?.balance)}</>,
      flexGrow: 0.5,
    },
    {
      columnName: "Type",
      columnKey: "type",
      flexGrow: 0.5,
    },
  ];

  const loansCol = [
    {
      columnName: (
        <>
          Date of <br /> Loan{" "}
        </>
      ),
      render: (data) => <>{formatDate(data?.date)}</>,
    },
    {
      columnName: "Name",
      columnKey: "name",
    },
    {
      columnName: (
        <>
          {" "}
          Loan <br /> Amount{" "}
        </>
      ),
      render: (data) => <>{formatMoney(data?.amount)}</>,
    },
    {
      columnName: "Actions",
      flexGrow: 0.5,

      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setAddLoanModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt=" " />
          </Button>
          <Button
            variant="link"
            className="table-action-button"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setDeleteConfirmationVisibility(true);
            }}
          >
            <img src={DeleteIcon} alt=" " />
          </Button>
        </small>
      ),
    },
  ];

  return (
    <>
      <div
        onClick={() => {
          Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
            page: mixpanel_contants.FINANCE,
          });
        }}
        className="container-fluid pt-3"
      >
        <CardPanel data={cardsData} />
        <Row>
          <Col>
            <FilterPanel
              setPageNumber={setLoansPageNumber}
              searchPlaceholder="Search for Name, Date or Loan Amount..."
              onReset={() => {
                setLoansPageNumber(1);
                setsearchData(null);
                fetchLoansData();
              }}
              onSubmit={onSubmit}
              dashboard={mixpanel_contants.FINANCE}
              renderAdvancedPanel={(register) => (
                <Row>
                  <Col>
                    <Form.Group as={Row} className="pt-3">
                      <Form.Label column sm="3">
                        Name
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          placeholder="Name"
                          {...register("name")}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Row} className="pt-3">
                      <Form.Label column sm="3">
                        Date
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="date"
                          placeholder="Date"
                          {...register("date")}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group as={Row} className="pt-3">
                      <Form.Label column sm="3">
                        Amount
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          placeholder="Amount"
                          {...register("amount")}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              )}
            />
          </Col>
        </Row>

        <div className="container-fluid d-flex gap-20">
          <div className="banking_div">
            <QuickTixTable
              loading={loading}
              data={bankBalance || []}
              headerName="Banking"
              paging={bankingPaging}
              renderRow={(rowData) => (
                <TableRowCard
                  key={rowData.id}
                  columns={bankingCol}
                  rowData={rowData}
                />
              )}
              setpageNumber={setBankpageNumber}
              headOptions={bankingHeadOptions}
              columns={bankingCol}
            />
          </div>
          <div className="loans_div">
            <QuickTixTable
              loading={loading}
              previousPage={previousPage}
              nextPage={nextPage}
              paramerterNavigation={true}
              data={loansRowData || []}
              headerName="Loans"
              paging={loansPaging}
              renderRow={(rowData, index) => (
                <TableRowCard
                  key={rowData.id}
                  columns={loansCol}
                  rowData={rowData}
                />
              )}
              setSortDir={setlSortDir}
              sortDir={lsortDir}
              setSortField={setlSortField}
              sortField={lsortField}
              setpageNumber={setLoansPageNumber}
              headOptions={headOptions}
              columns={loansCol}
            />
          </div>
        </div>
        <AddLoan
          isVisible={addLoanModalVisible}
          setMinorLoading={setMinorLoading}
          minorLoading={minorLoading}
          handleClose={() => setAddLoanModalVisibility(false)}
          selectedRecord={selectedRecord}
          onSubmit={onEntrySubmitted}
        />
        <DeleteConfirmationModal
          isVisible={deleteConfirmationVisible}
          minorLoading={minorLoading}
          setMinorLoading={setMinorLoading}
          handleClose={() => setDeleteConfirmationVisibility(false)}
          onConfirmation={() => onDeleteLoan(selectedRecordId)}
        />
      </div>
    </>
  );
}

export default withLoader(FinanceDashboard);
