import { Link } from "react-router-dom";
import { formatMoney } from "../../../../utils/formatter/currencyFormatter";
import { formatDate } from "../../../../utils/formatter/dateFormatter";
import { formatTime } from "../../../../utils/formatter/timeFormatter";

const transactions = (onEdit, onDelete) => {
  return (
    [
      {
        columnName: "Description",
        columnKey: "description",
        sort: true,
        sortName: "Description"
      },

      {
        columnName: "Date/Time",
        flexGrow: 0.5,
        sort: true,
        sortName: "DateAndTime",
        render: (data) => (
          <>
            {formatDate(data?.dateAndTime)}
          </>
        )
      },
      {
        columnName: "Amount",
        render: (data) => (
          <>
            {formatMoney(data?.amount)}
          </>
        ),
        sort: true,
        sortName: "Amount"
      },
      {
        columnName: "Notes",
        render: (data) => (
          <span
          >
            {data.notes}
          </span>
        ),
      },

      {
        columnName: "Vendor",
        sort: true,
        sortName: "VendorId",
        render: (data) => (
          <Link
            variant="link"
            className="ss-link"
            to={"/vendors/" + data.vendorId}
          >
            {data.vendorName}
            <br />
            {data.vendorId ? data?.vendorId : ""}
          </Link>
        ),
      },
      {
        columnName: "Account",
        render: (data) => (
          <>
            {data?.autoGenerated == true ? (
              <span>
                {data.accountName}
                <br />
                {data.accountId == 0 ? "" : data.accountId}
              </span>
            ) : (
              <Link
                variant="link"
                className="ss-link"
                to={"/accounts/" + data.accountId + "?vendorId=" + data?.vendorId}>
                {data.accountName}
                <br />
                {data.accountId == 0 ? "" : data.accountId}
              </Link>
            )}
          </>
        ),
        sort: true,
        sortName: "AccountId"
      },
      {
        columnName: "Purchase Order",
        render: (data) => (
          <>
            <Link
              variant="link"
              className="ss-link"
              to={"/purchases/" + data.id}
            >
              {data.purchaseOrdersId}
            </Link>
          </>
        ),
        sort: true,
        sortName: "PurchaseOrderId"
      },



    ]
  );
};

export { transactions };