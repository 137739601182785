import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
import React, { useCallback, useEffect } from "react";
import { Modal, Button, Col, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { withLoader } from "../../../utils/hoc/withLoader";
import UploadEmail from "../../../images/UploadCsv.svg";
import Download from "../../../images/DownloadEmail.svg";
import VectorImg from "../../../images/VectorImg.svg";
import {
  downloadEmailTemplate,
  uploadEmailCsvFiles,
} from "../../../data/reducers/email/upload.email.reducer";
import { useDropzone } from "react-dropzone";

const UploadModal = ({
  setBusy,
  isVisible,
  handleThisClose,
  onUploadCompleted,
}) => {
  const dispatch = useDispatch();

  const [fileData, setFileData] = useState([]);
  const [progressBar, setProgressBar] = useState(0);

  const onUploadProgress = (event) => {
    const percentCompleted = Math.round((event.loaded * 100) / event.total);
    setProgressBar(percentCompleted);
  };

  const simulateUpload = () => {
    let progress = 0;

    const interval = setInterval(() => {
      if (progress < 100) {
        progress += Math.floor(Math.random() * 10) + 1;
        if (progress >= 100) {
          progress = 100;
        }
        onUploadProgress({ loaded: progress, total: 100 });
      } else {
        clearInterval(interval);
      }
    }, 500);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        if (file.size > 4 * 1024 * 1024) {
          const message = `File is too large. Maximum size is 4MB.`;
          dispatch(errorMessage(message));
        } else {
          await simulateUpload();
          setFileData(file);
        }
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
  });

  const onUploadData = async () => {
    try {
      const message = `The ${fileData.name} file uploaded successfully.`;
      setBusy(true);
      const formdata = new FormData();
      formdata.append("file", fileData);
      const action = uploadEmailCsvFiles(formdata);
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        onUploadCompleted();
        handleThisClose();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // cancel button
  const removeFile = () => {
    setFileData(null);
    setProgressBar(0);
    setValue("");
    reset();
    handleThisClose();
  };

  // download Tamplate
  const downloadTemplate = async () => {
    try {
      setBusy(true);
      await dispatch(downloadEmailTemplate());
      setBusy(false);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={handleThisClose}
      >
        <Form
          onSubmit={handleSubmit(onUploadData)}
          style={{ fontWeight: "500" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Emails</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/upload/email",
                name: "Upload Email",
                active: false,
              },
              {
                name: "Upload files",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Col>
                {progressBar ? (
                  <div>{/* {fileData?.name} */}</div>
                ) : (
                  <div className="uploadFile">
                    <div
                      {...getRootProps()}
                      className="dropzone-area"
                      style={{ cursor: "pointer" }}
                    >
                      <input {...getInputProps()} />
                      <img src={UploadEmail} alt=" " className="uploadImg" />
                      <br />
                      <p
                        style={{
                          fontWeight: "500",
                          color: "#00000",
                          fontSize: "20px",
                        }}
                      >
                        Please upload .csv file.
                      </p>
                      <p className="uploadFileName">
                        {isDragActive
                          ? "Drop the file here"
                          : "Drag and Drop file here or "}
                        {!isDragActive && (
                          <a id="input-file-now" style={{ color: "#0241A0" }}>
                            browse the file
                          </a>
                        )}
                      </p>
                      <p
                        style={{
                          color: "#A7A7A7",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        max file size 4 MB
                      </p>
                    </div>
                  </div>
                )}

                <br />
                {progressBar ? (
                  <div>
                    <div className="progress mb-2">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-label="progressbar"
                        aria-valuenow={progressBar}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: `${progressBar}%` }}
                      ></div>
                    </div>
                    File upload - {progressBar}%
                    {fileData && (
                      <div className="d-flex mt-3 mb-2">
                        <img
                          src={VectorImg}
                          alt=" "
                          style={{ paddingRight: "10px" }}
                        />
                        {fileData?.name} -{" "}
                        {(fileData?.size / 1024 / 1024).toFixed(4)} MB
                      </div>
                    )}
                  </div>
                ) : null}
              </Col>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex justify-content-evenly my-4 mx-2">
              <Button
                type="submit"
                variant="primary"
                disabled={!fileData || progressBar < 100}
                className="ss-modal-primary-btn mx-2 w-auto"
              >
                Upload
              </Button>
              <Button
                variant="light"
                onClick={removeFile}
                className="ss-modal-secondary-btn mx-2 w-auto"
              >
                Cancel
              </Button>
            </div>
            <div>
              <div
                onClick={downloadTemplate}
                style={{
                  background: "#0241A0",
                  height: "46px",
                  width: "219px",
                  color: "#fff",
                  borderRadius: "8px",
                  paddingTop: "10px",
                  textAlign: "center",
                  cursor: "pointer",
                  justifyContent: "space-between",
                  marginRight: "1.5rem",
                }}
                className="my-4 mx-3"
              >
                <img
                  src={Download}
                  alt=" "
                  style={{ color: "#fff", paddingRight: "10px" }}
                />
                Download template
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(UploadModal);
