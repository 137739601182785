import { Link } from "react-router-dom";
import { formatMoney } from "../../../../utils/formatter/currencyFormatter";
import { formatDate } from "../../../../utils/formatter/dateFormatter";

const transactions = (onSelect) => {
  return (
    [{
      columnName: "",
      flexGrow: 0.5,
      render: (data) => (
        <>
          <input
            type="radio"
            name="matched"
            onClick={() => onSelect(data)}
          />
        </>
      )
    },
    {
      columnName: "Date",
      render: (data) => (
        <>
          {formatDate(data?.date)}
        </>
      )
    },
    {
      columnName: "Purchase Order",
      render: (data) => (
        <>
          <Link
            variant="link"
            className="ss-link"
            to={"/purchases/" + data.id}
          >
            {data.purchaseOrderId}
          </Link>
        </>
      ),
      columnKey: "purchaseOrderId"
    },
    {
      columnName: "Vendor",
      render: (data) => (
        <Link
          variant="link"
          className="ss-link"
          to={"/vendors/" + data.vendorId}
        >
          {data.vendorName}
          <br />
          {data.vendorId ? data?.vendorId : ""}
        </Link>
      ),
      sort: true,
      sortName: "VendorId",
    },
    {
      columnName: "Account",
      sort: true,
      sortName: 'AccountId',
      render: (data) => (
        <Link
          variant='link'
          className='ss-link'
          to={'/accounts/' + data.accountId + "?vendorId=" + data?.vendorId}>
          {data.accountName}<br />
          {data.accountId == 0 ? "" : data.accountId}
        </Link>
      ),
    },
    {
      columnName: "Description",
      columnKey: "description"
    },
    {
      columnName: "Amount",
      render: (data) => (
        <>
          {formatMoney(data.amount)}
        </>
      )
    },
    ]
  );
};

export { transactions };