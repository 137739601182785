import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "../../../date-range-calendar.scss";
import { isDatePartEqual } from "../../../utils/formatter/dateFormatter";
import { useDispatch, useSelector } from "react-redux";
import { errorMessage } from "../../../data/reducers/alerts/alert.reducer";
import DatePicker from "react-datepicker";

const DateFilter = ({
  monthArray,
  monthSelect,
  setStartDate,
  setEndDate,
  setMonthSelect,
  startDate,
  endDate,
  loadButtonAction,
  loadButtonText,
  showLoadButton = false,
}) => {
  const dispatch = useDispatch();
  const fontSize = 15;
  // const futureMessage = "Can not pick a future date";

  const startDateMessage = "Start date cannot be greater than the end date.";
  const endDateMessage = "End date cannot be earlier than the start date.";

  useEffect(() => {
    let changed = false;
    if (Array.isArray(monthArray) && monthArray.length > 0) {
      for (let i = 0; i < monthArray.length; i++) {
        if (!monthArray[i].value) continue;
        let tempStartDate = new Date();
        tempStartDate.setMonth(tempStartDate.getMonth() - monthArray[i].value);
        let tempEndDate = new Date();
        if (
          isDatePartEqual(startDate, tempStartDate) &&
          isDatePartEqual(endDate, tempEndDate)
        ) {
          setMonthSelect(monthArray[i].value);
          changed = true;
          break;
        }
      }
    }

    if (!changed) {
      setMonthSelect("Custom Date");
    }
  }, [monthArray, startDate, endDate]);

  return (
    <Row className="mb-2 justify-content-end">
      {showLoadButton && (
        <Col
          lg={2}
          xs={4}
          className="d-flex flex-column justify-content-end text-center"
        >
          <Form.Group>
            <Col>
              <button
                onClick={() => {
                  dispatch(loadButtonAction);
                }}
                className=" ss-light-button-white d-flex justify-content-center"
                style={{
                  width: "100%",
                  padding: "6px 12px",
                  borderRadius: "6px",
                }}
              >
                <span
                  style={{
                    whiteSpace: "nowrap",
                    textAlign: "center",
                  }}
                >
                  {loadButtonText}
                </span>
              </button>
            </Col>
          </Form.Group>
        </Col>
      )}
      <Col lg={2} xs={4}>
        <Form.Group>
          <Form.Label style={{ fontSize: fontSize }}>Start Date</Form.Label>
          <Col>
            <DatePicker
              style={{ width: "100%", color: "black" }}
              dateFormat="MM/dd/yyyy"
              className="form-control"
              selected={startDate}
              showYearDropdown
              onChange={async (e) => {
                if (e) {
                  const selectedDate = new Date(e);
                  const endDateObj = endDate ? new Date(endDate) : null;

                  if (endDateObj && selectedDate > endDateObj) {
                    dispatch(errorMessage(startDateMessage));
                  } else {
                    setStartDate(e);
                  }
                }
              }}
              onKeyDown={(e) => {
                const key = e.key;
                if (
                  !/[0-9]|Backspace|Delete|Tab|ArrowLeft|ArrowRight/.test(key)
                ) {
                  e.preventDefault();
                }
              }}
            />
          </Col>
        </Form.Group>
      </Col>
      <Col lg={2} xs={4}>
        <Form.Group>
          <Form.Label style={{ fontSize: fontSize }}>End Date</Form.Label>
          <Col>
            <DatePicker
              style={{
                width: "100%",
                color: "black",
                border: "1px solid white",
              }}
              className="form-control"
              dateFormat="MM/dd/yyyy"
              selected={endDate}
              showYearDropdown
              onChange={(e) => {
                if (e) {
                  const selectedDate = new Date(e);
                  const startDateObj = startDate ? new Date(startDate) : null;

                  if (startDateObj && selectedDate < startDateObj) {
                    dispatch(errorMessage(endDateMessage));
                  } else {
                    setEndDate(e);
                  }
                }
              }}
              onKeyDown={(e) => {
                const key = e.key;
                if (
                  !/[0-9]|Backspace|Delete|Tab|ArrowLeft|ArrowRight/.test(key)
                ) {
                  e.preventDefault();
                }
              }}
            />
          </Col>
        </Form.Group>
      </Col>
      <Col lg={2} xs={4}>
        <Form.Group>
          <Form.Label style={{ fontSize: fontSize }}>
            Showing Data For
          </Form.Label>
          <Col>
            <select
              value={monthSelect}
              className="form-control"
              style={{ marginLeft: "auto", fontSize: "14px" }}
              onChange={(e) => {
                let today = new Date();
                today.setMonth(today.getMonth() - Number(e.target.value));
                setStartDate(today);
                setEndDate(new Date());
              }}
            >
              {monthArray?.map((val) => {
                if (!val.value) {
                  return null;
                }
                return (
                  <option key={val.id} value={val.value}>
                    {val.name}
                  </option>
                );
              })}
              <option style={{ display: "none" }} value={"Custom Date"}>
                Custom Date
              </option>
            </select>
          </Col>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DateFilter;
