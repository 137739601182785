import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { successMessage } from "../../../data/reducers/alerts/alert.reducer";
import React from "react";
import Loader from "../../../shared/components/loader/Loader";

const AddEditVendorRulesModal = ({
  isVisible,
  handleClose,
  onSubmit,
  selectedRecord,
  minorLoading,
}) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.vendors).category;

  const categerizationOptions = useSelector(
    (state) => state.vendors
  ).categerizationOptions;

  const [isEdit, setEdit] = useState(false);
  const [type, setType] = useState(undefined);
  const [categorizationId, setCategorizationId] = useState(undefined);

  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    if (selectedRecord?.categoryId && categories?.length > 0)
      setType(
        categories?.filter((c) => c?.id === selectedRecord?.categoryId)[0]
      );
    if (selectedRecord?.categorizationId && categerizationOptions?.length > 0) {
      setCategorizationId(
        categerizationOptions?.find(
          (c) => c.id === selectedRecord.categorizationId
        )
      );
    }
  };

  // reset form on visibility toggle
  useEffect(() => {
    reset();
  }, [isVisible]);

  // update values in form if selected record is changed
  useEffect(() => {
    if (selectedRecord) {
      // as soon as selected record is changed, set values in modal popup
      setEdit(true);
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      if (selectedRecord?.categoryId && categories?.length > 0)
        setType(
          categories?.filter((c) => c?.id === selectedRecord?.categoryId)[0]
        );

      if (
        selectedRecord?.categorizationId &&
        categerizationOptions?.length > 0
      ) {
        setCategorizationId(
          categerizationOptions?.find(
            (c) => c.id === selectedRecord.categorizationId
          )
        );
      } else {
        setCategorizationId(null);
        setValue("categorizationId", null, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    } else {
      // if selected record is undefined, reset
      setEdit(false);
      reset();
    }
  }, [selectedRecord, isVisible]);

  useEffect(() => {
    setValue("categoryId", type?.id);
    setValue("type", type?.type);
  }, [type]);

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      name: selectedRecord?.name,
    },
  });

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  const validateSubmit = async (data) => {
    if (
      getValues("accountUrl") !== null &&
      getValues("accountUrl").trim() === ""
    ) {
      setValue("accountUrl", null);
      data.accountUrl = null;
    }

    if (getValues("otherUrl") !== null && getValues("otherUrl").trim() === "") {
      setValue("otherUrl", null);
      data["otherUrl"] = null;
    }
    const categoryId = isEdit ? data.categoryId : 1;
    if (
      selectedRecord &&
      selectedRecord.teamId == data.teamId &&
      selectedRecord.categoryId == categoryId &&
      selectedRecord.accountUrl === data.accountUrl &&
      selectedRecord.name === data.name &&
      selectedRecord?.otherUrl == data.otherUrl
    ) {
      handleClose();
      dispatch(successMessage("Vendor Updated Successfully"));
    } else {
      onSubmit({
        ...data,
        categorizationId: selectedRecord?.categorizationId ?? 0,
        abbreviation: selectedRecord.abbreviation ?? "",
      });
    }
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(validateSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>VENDOR</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/vendors",
                name: "Vendors",
                active: false,
              },
              {
                name: isEdit ? "Edit Vendor" : "New Vendor",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col md="6">
                  <Form.Group as={Row}>
                    <Form.Label column md="4">
                      Vendor
                    </Form.Label>
                    <Col md="7">
                      {selectedRecord?.source == "Quicktix" ? (
                        <Form.Control {...register("name")} />
                      ) : (
                        <Form.Control
                          value={
                            selectedRecord?.id + " " + selectedRecord?.name
                          }
                          disabled
                        />
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row}>
                    <Form.Label column md="5">
                      Type
                    </Form.Label>
                    <Col md="7">
                      <Controller
                        name="categoryId"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={type}
                              onChange={(e) => {
                                field.onChange(e.id);
                                setType(e);
                              }}
                              styles={customStyles}
                              options={categories}
                              getOptionLabel={(option) => `${option.type}`}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={8}>
                  <Form.Group as={Row}>
                    <Form.Label column md="3">
                      Ticketmaster URL
                    </Form.Label>
                    <Col
                      md="3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      am.ticketmaster.com/
                    </Col>
                    <Col md="5">
                      <Form.Control
                        {...register("accountUrl")}
                        placeholder="Vendor URL"
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  {/* <Form.Group as={Row}>
                                        <Form.Label column md="5">
                                        Vendor Abbreviation
                                        </Form.Label>
                                        <Col md="7">
                                            <Form.Control
                                                isInvalid={!!errors.abbreviation}
                                                {...register("abbreviation",{
													required: true,
								
												})}
                                                placeholder="Vendor Abbreviation"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Vendor Abbreviation is required
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group> */}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group as={Row}>
                    <Form.Label column md="4">
                      URL
                    </Form.Label>

                    <Col md="7">
                      <Form.Control
                        {...register("otherUrl")}
                        placeholder="URL"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row className="mt-3">
                                <Col>
                                    <Form.Group as={Row}>
                                        <Form.Label column md="4">
                                        Categorization
                                        </Form.Label>
                                        <Col md="7">
                                            <Controller
                                            name="categorizationId"
                                            control={control}
                                            rules={{ required: true, message: "error message" }}
                                            render={({ field, fieldState }) => {
                                                return (
                                                <div>
                                                    <Select
                                                    value={categorizationId ?? null}
                                                    onChange={async (e) => {
                                                        field.onChange(e?.id);
                                                        setCategorizationId(e);
                                                    }}
                                                    isClearable
                                                    styles={customStyles}
                                                    options={categerizationOptions}
                                                    getOptionLabel={option => `${option.categorization}`}
                                                    />
                                                    { fieldState.error ? <div className="custom-invalid-feedback">Categorization is required.</div> : ""}

                                                </div>
                                                );
                                            }}
                                            />
                                    </Col>
                                    </Form.Group>
                                </Col>
                                <Col />
                            </Row> */}
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              disabled={minorLoading === "edit"}
              className="ss-modal-primary-btn mx-2"
            >
              {isEdit ? "Save" : "Add"}{minorLoading === "edit" && (
                <Loader style={{ marginLeft: "8px", display: "inline-block" }} />
              )}
            </Button>
            <Button
              variant="light"
              onClick={() => (isEdit ? handleReset() : reset())}
              className="ss-modal-secondary-btn mx-2"
            >
              {isEdit ? "Reset" : "Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEditVendorRulesModal;
