import React, { useEffect, useState } from "react";
import { Form, Button, Table, InputGroup } from "react-bootstrap";
import Select from "react-select";
import DeleteIcon from "../../../../../../images/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
// import 'primeicons/primeicons.css';
import {
  getSeasonsList,
  setSeasonBySport,
} from "../../../../../../data/reducers/season/season.reducer";
import {
  getAccountSportList,
  getAccountTeamList,
  setSelectedTicketDetails,
} from "../../../../../../data/reducers/accounts/accounts.reducers";
import { createSeason } from "../../../../../../data/reducers/season/season.reducer";
import {
  createSport,
  createTeam,
  filterTeams,
  getAllTeams,
} from "../../../../../../data/reducers/sports&teams/sports&teams.reducer";

import {
  errorMessage,
  successMessage,
} from "../../../../../../data/reducers/alerts/alert.reducer";
import AddEditSeasonModal from "../../../../../season/modals/AddEditSeasonModal";
import AddEditSportsModal from "../../../../../settingstab/sports-teams/modals/AddEditSportsModal";
import AddEditTeamsModal from "../../../../../settingstab/sports-teams/modals/AddEditTeamsModal";
import { formatDate } from "../../../../../../utils/formatter/dateFormatter";
import {
  getInvoiceFeeDropdown,
  setAdditionalDetails,
  setGlobalFeeInputs,
  setInvoiceType,
} from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import { reactSelectStyle } from "../../../style";
import {
  formatDollarMoney,
  formatDollarMoneyWithoutFloat,
  formatMoneyWithCommas,
  handleMoneyChangeWithCommas,
} from "../../../../../../utils/formatter/currencyFormatter";
import { withLoader } from "../../../../../../utils/hoc/withLoader";
import { GoMultiSelect } from "react-icons/go";
import { clone, cloneDeep } from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const custom_styles = {
  font_label: {
    fontSize: "14px",
    color: "#404040",
  },
  add_btn: {
    width: "32px",
    height: "32px",
  },
};

const InvoiceTicketDetails = ({ type, setBusy }) => {
  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const { seasonList } = useSelector((state) => state.season);
  const {
    sportList,
    teamList: allTeams,
    ticketDetails,
  } = useSelector((state) => state.accounts);
  const dispatch = useDispatch();
  const [openSeasonModal, setOpenSeasonModal] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [openSportModal, setOpenSportModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [TableData, setTableData] = useState([]);

  const [currentRow, setCurrentRow] = useState(-1);
  const [selelectedData, setSelectedData] = useState([]);
  const [selectedSportId, setSelectedSportId] = useState(null);
  const [selectedSportName, setSelectedSportName] = useState(null);
  const [feeDetails, setFeeDetails] = useState([]);
  const [showSameFeeMessage, setShowSameFeeMessage] = useState(false);
  const {
    allPurchaseOrderDetails,
    globalInvoiceType,
    globalFeeDropdown,
    globalFeeInputs,
    globalSubInvoiceType,
  } = useSelector((state) => state.purchaseOrder);

  const { id: InvoicePoId } = useParams();
  // State to store fee details
  const [isFeeDetail, setIsFeeDetail] = useState(false);
  const [feeList, setFeeList] = useState([]);

  useEffect(() => {
    if (
      (ticketDetails?.bestPrice ||
        ticketDetails?.regionalFees ||
        ticketDetails?.taxFees ||
        ticketDetails?.processingFees ||
        ticketDetails?.convenienceFees ||
        ticketDetails?.additionalFees) &&
      feeDetails == null
    ) {
      setIsFeeDetail(true);
    }

    if (
      (!selelectedData || selelectedData?.length == 0) &&
      ticketDetails?.sport?.value
    ) {
      setSelectedData({
        season: ticketDetails?.season,
        sport: ticketDetails?.sport,
        team: ticketDetails?.team,
        isTicketPurchasesInCurrentSeason:
          ticketDetails?.isTicketPurchasesInCurrentSeason,
        ticketPurchaseCheck: ticketDetails?.isTicketPurchasesInCurrentSeason,
        firstPuchaseDate: ticketDetails?.firstPuchaseDate,
      });
      setSelectedSportId(ticketDetails?.sport?.value);
      setSelectedSportName(ticketDetails?.sport?.label);
    }

    //condition to update the state of showing the payment plan on the purchase Details page.
    if (
      (!TableData || TableData.length == 0) &&
      ticketDetails?.TableData &&
      ticketDetails?.TableData?.length > 0
    ) {
      setTableData(ticketDetails?.TableData ? ticketDetails?.TableData : []);
    }
  }, [ticketDetails]);

  const handleDelete = () => {
    // Clear fee details
    setFeeDetails({
      bestPrice: "",
      regionalFees: "",
      taxFees: "",
      processingFees: "",
      convenienceFees: "",
      additionalFees: "",
    });
    // Hide fee details table
    setIsFeeDetail(false);
  };
  useEffect(() => {
    if (seasonList) {
      setSeasons(seasonList);
      if (selectedSportId) {
        setSeasons(
          seasonList.filter((season) => season.sportName === selectedSportName)
        );
      }
    }
    if (allTeams) {
      setTeamList(allTeams);
      if (selectedSportId) {
        setTeamList(
          allTeams.filter((team) => team.sportId === selectedSportId)
        );
      }
    }
  }, [seasonList, sportList, selectedSportId, allTeams]);

  useEffect(() => {
    if (selectedSportId) {
      if (!allTeams) {
        dispatch(getAllTeams());
        setTeamList(
          allTeams.filter((team) => team.sportId === selectedSportId)
        );
      }
      if (!seasonList) {
        dispatch(getSeasonsList());
        setSeasons(
          seasonList.filter((season) => season.sportName === selectedSportName)
        );
      }
      // dispatch(getSeasonsList(selectedSportId));
    }
  }, [selectedSportId]);

  useEffect(() => {
    // If you want to fetch something before page completely loads then put that api call inside this below function.
    fetchNecessaryLists();
  }, []);

  const fetchNecessaryLists = async () => {
    await dispatch(getAccountSportList());
    await dispatch(getAccountTeamList());
    await dispatch(getSeasonsList());
  };

  useEffect(() => {
    if (!type) {
      dispatch(setInvoiceType(type));
    }
  }, [type]);

  // add new season
  const onEntrySubmitted = async (data) => {
    if (data?.startDate > data?.endDate) {
      dispatch(errorMessage("Start Date Cannot be Greater Than End Date"));
    } else {
      setBusy(true);
      try {
        const message = `New Season Created Successfully! ${
          data.copyLocationSeasonId > 0
            ? "Locations copied successfully from selected season."
            : ""
        }`;

        const action = createSeason(data);
        const response = await dispatch(action);
        setSelectedData((prev) => ({
          ...prev,
          season: {
            value: response?.payload?.id,
            label: response?.payload?.seasonName,
          },
        }));
        if (response.error) {
          dispatch(errorMessage(response.error.message));
        } else {
          dispatch(successMessage(message));
          setOpenSeasonModal(false);
          await dispatch(getSeasonsList());
        }
      } catch (e) {
        dispatch(errorMessage(e));
      } finally {
        setBusy(false);
      }
    }
  };

  // Create new sport
  const onSubmitted = async (data, option) => {
    setBusy(true);
    try {
      const message = `Sport Created Successfully`;
      const action = createSport(data);
      const response = await dispatch(action);

      setSelectedData((prev) => ({
        ...prev,
        sport: {
          value: response?.payload?.id,
          label: response?.payload?.sportName,
        },
      }));

      setSelectedSportId(response?.payload?.id);
      setSelectedSportName(response?.payload?.sportName);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        dispatch(getAccountSportList());
        setOpenSportModal(false);
      }
    } catch (e) {
      dispatch(errorMessage(e));
      setOpenSportModal(false);
    } finally {
      setBusy(false);
    }
  };

  // add or update account details
  const CreateTeamSubmit = async (data, _) => {
    setBusy(true);
    try {
      let action = createTeam(data);
      let response = await dispatch(action);
      setSelectedData((prev) => ({
        ...prev,
        team: {
          value: response?.payload?.id,
          label: response?.payload?.teamName,
        },
      }));

      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage("Success in creating team."));
        await dispatch(getAccountTeamList());
        setOpenTeamModal(false);
      }
    } catch (e) {
      dispatch(errorMessage(e));
      setOpenTeamModal(false);
    } finally {
      setBusy(false);
    }
  };

  const HandleTableOperation = (type, val, ind, Id) => {
    console.log(type, val, ind, Id);
    switch (type) {
      case "add": {
        const newRow = {
          date: "",
          section: "",
          row: "",
          lowSeat: "",
          highSeat: "",
          qty: "",
          price: "",
        };
        setTableData((prev) => [...prev, newRow]);

        //here we are adding a new entry in the masking global state.
        const oldMaskingList = cloneDeep(
          purchaseOrderData?.additionalDetails?.masking ?? []
        );
        //default data for adding in the new masking entry which is initiated when the entry added inside the
        oldMaskingList.push({
          section: "",
          maskingRow: "",
          maximumRow: "",
          possibleMaskingRows: "",
          RowExclusion: "",
          maskinggenerated: false,
          generateMasking: {
            sunday: "",
            monday: "",
            tuesday: "",
            wednesday: "",
            thursday: "",
            friday: "",
            saturday: "",
          },
        });
        dispatch(
          setAdditionalDetails({
            ...purchaseOrderData?.additionalDetails,
            masking: oldMaskingList,
          })
        );

        break;
      }

      case "delete": {
        setTableData((prev) => prev.filter((row, index) => index !== ind));
        //Here we are gonna delete one entry in the global state of the masking instruction.
        const filteredMasking = cloneDeep(
          purchaseOrderData?.additionalDetails?.masking?.filter(
            (item, index) => index !== ind
          ) ?? []
        );
        dispatch(
          setAdditionalDetails({
            ...purchaseOrderData?.additionalDetails,
            masking: filteredMasking,
          })
        );
        break;
      }

      case "update": {
        if (val.target.name == "section" || val.target.name == "row") {
          // old state of the masking list
          const oldMaskingList = cloneDeep(
            purchaseOrderData?.additionalDetails?.masking ?? []
          );

          if (
            (val.target.name == "section" &&
              oldMaskingList[ind].section != val.target.value) ||
            (val.target.name == "row" &&
              oldMaskingList[ind].row != val.target.value)
          ) {
            //updating the section and row information for the masking information.
            oldMaskingList[ind] = {
              ...oldMaskingList[ind],
              maximumRow: "",
              possibleMaskingRows: "",
              RowExclusion: "",
              maskinggenerated: false,
              generateMasking: {
                sunday: "",
                monday: "",
                tuesday: "",
                wednesday: "",
                thursday: "",
                friday: "",
                saturday: "",
              },
              [val.target.name == "row" ? "maskingRow" : val.target.name]:
                val.target.value,
            };
            dispatch(
              setAdditionalDetails({
                ...purchaseOrderData?.additionalDetails,
                masking: oldMaskingList,
              })
            );
          }
        }

        setTableData((prev) =>
          prev.map((row, index) => {
            return index === ind
              ? {
                  ...row,
                  [val.target.name]: val.target.value,
                  price:
                    val.target.name === "price"
                      ? handleMoneyChangeWithCommas(val)
                      : row?.price,
                  qty:
                    val.target.name === "highSeat"
                      ? (
                          parseFloat(val.target.value) -
                          parseFloat(row.lowSeat || 0) +
                          1
                        ).toString()
                      : val.target.name === "lowSeat"
                      ? (
                          parseFloat(row.highSeat || 0) -
                          parseFloat(val.target.value) +
                          1
                        ).toString()
                      : row.qty,
                  id: Id,
                }
              : row;
          })
        );
        setCurrentRow(-1);
        break;
      }

      default:
        console.error(`Unhandled table operation type: ${type}`);
    }
  };

  useEffect(() => {
    if (selelectedData) {
      dispatch(
        setSelectedTicketDetails({
          ...selelectedData,
          isFeeDetail,
          TableData,
          ...feeDetails,
        })
      );
    }
  }, [selelectedData, TableData, feeDetails]);

  const custom_styles = {
    font_label: {
      fontSize: "14px",
      color: "#404040",
      minWidth: "120px",
    },
    details: {
      fontWeight: "600",
      fontSize: "14px",
    },
    titles: {
      fontSize: "20px",
      fontWeight: "500",
    },
    font_label2: {
      fontSize: "14px",
      color: "#a7a7a7",
    },
    border1: {
      backgroundColor: "#c1c1c1",
      height: "1px",
      opacity: "0.8",
    },
    border2: {
      backgroundColor: "#dfdfdf",
      height: "1px",
      opacity: "0.5",
    },
    formInput: {
      width: "100%",
      height: "32px",
      boxShadow: "none",
    },
  };

  const [amount, setAmount] = useState("");

  const addNewFeeOption = () => {};
  function convertPriceToInteger(priceString) {
    let cleanedString = priceString.replace(/^USD\s*/, "");

    cleanedString = cleanedString.replace(/,/g, "");
    const integerPrice = parseInt(parseFloat(cleanedString), 10);

    return integerPrice;
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const fetchInvoiceFeeDropdown = async () => {
    setBusy(true);
    // it is static needs to be changed.
    await dispatch(getInvoiceFeeDropdown(Number(InvoicePoId)));
    setBusy(false);
  };

  useEffect(() => {
    fetchInvoiceFeeDropdown();
  }, []);
  useEffect(() => {
    const transformedFees = globalFeeDropdown.map((item) => ({
      name: item.feeLabel,
      code: item.fees,
    }));
    setFeeList(transformedFees);
    dispatch(
      setGlobalFeeInputs(
        globalFeeDropdown
          .filter((x) => x.fees != null)
          .map((item) => ({
            name: item.feeLabel,
            code: item.fees,
          }))
      )
    );
  }, [globalFeeDropdown]);

  // global field is changing the local field, and in the onchange we are setting the global field only.
  useEffect(() => {
    setFeeDetails(globalFeeInputs);

    const oldFeeList = cloneDeep(feeList);
    for (let i = 0; i < globalFeeInputs?.length; i++) {
      for (let j = 0; j < oldFeeList.length; j++) {
        if (globalFeeInputs[i]?.name == oldFeeList[j].name) {
          oldFeeList[j] = { ...globalFeeInputs[i] };
        }
      }
    }
    setFeeList(oldFeeList);

    // Here we are
    const set = new Set();
    let duplicateExists = false;
    for (let i = 0; i < globalFeeInputs?.length; i++) {
      const feeLabel = globalFeeInputs[i]?.name?.trim()?.toLowerCase();
      if (feeLabel) {
        if (set.has(feeLabel)) {
          duplicateExists = true;
          break;
        } else {
          set.add(feeLabel);
        }
      }
    }
    if (duplicateExists) {
      setShowSameFeeMessage(true);
    } else {
      setShowSameFeeMessage(false);
    }
  }, [globalFeeInputs]);

  const isReadOnly = (item) => {
    const feeLabels = globalFeeDropdown.map((item) => item.feeLabel);
    if (feeLabels.includes(item.name)) {
      return true;
    }
  };

  return (
    <div className="px-4 pt-4 d-flex flex-column gap-4">
      {/* Sport And Team  | Season */}
      <div className="d-flex flex-column gap-2">
        {/* Sport And Team */}
        <div className="d-flex justify-content-between ">
          {/* Sport */}
          <div className="form-group d-flex align-items-center w-50">
            <label
              className="form-label col-form-label "
              style={custom_styles.font_label}
            >
              Sport <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex align-items-center gap-2  w-75">
              <Select
                // here we are filtering only those sports which have id more than zero because, sports having id less then or equal to zero are for other purpose
                options={sportList
                  ?.filter((x) => x.id > 0)
                  ?.map((val) => {
                    return {
                      value: val?.id,
                      label: val?.sportName,
                      data: val,
                    };
                  })}
                isClearable
                placeholder="Type & Select"
                className="w-75"
                styles={reactSelectStyle}
                value={
                  selelectedData?.sport && selelectedData?.sport?.value
                    ? selelectedData?.sport
                    : null
                }
                onChange={(e) => {
                  if (!e) {
                    // condition for isclear
                    setTeamList([]);
                    setSeasons([]);
                  }
                  if (
                    e?.value != selectedSportId &&
                    e?.label != selectedSportName
                  ) {
                    setSelectedData((prev) => ({
                      ...prev,
                      sport: e,
                      team: null,
                      season: null,
                    }));
                    setSelectedSportId(e?.value ?? "value");
                    setSelectedSportName(e?.label ?? "label");
                  }
                }}
              />
              <AddButton onClick={() => setOpenSportModal(true)} />
            </div>
          </div>

          {/* Team */}
          <div className="form-group d-flex justify-content-end w-50">
            <div className="form-label w-25 text-start col-form-label">
              <label style={custom_styles.font_label}>
                Team <span style={{ color: "red" }}>*</span>
              </label>
            </div>

            <div className="d-flex gap-2 align-items-center w-75">
              <Select
                options={teamList.map((val) => {
                  return {
                    label: val?.teamName,
                    value: val?.id,
                  };
                })}
                isClearable
                className="w-75"
                placeholder="Type & Select"
                styles={reactSelectStyle}
                value={
                  selelectedData?.team && selelectedData?.team?.value
                    ? selelectedData?.team
                    : null
                }
                onChange={(e) => {
                  setSelectedData((prev) => ({ ...prev, team: e }));
                }}
              />
              <AddButton onClick={() => setOpenTeamModal(true)} />
            </div>
          </div>
        </div>

        {/* Season */}
        {globalInvoiceType != 4 && globalInvoiceType != 3 && (
          <div className="form-group d-flex align-items-center w-50">
            <label
              className="form-label col-form-label"
              style={custom_styles.font_label}
            >
              Season <span style={{ color: "red" }}>*</span>
            </label>
            <div className="d-flex gap-2 align-items-center w-75">
              <Select
                options={seasons.map((val) => {
                  return {
                    value: val?.id,
                    label: val?.seasonName,
                  };
                })}
                isClearable
                className="w-75"
                styles={reactSelectStyle}
                placeholder="Type & Select"
                value={
                  selelectedData?.season && selelectedData?.season?.value
                    ? selelectedData?.season
                    : null
                }
                onChange={(e) => {
                  setSelectedData((prev) => ({ ...prev, season: e }));
                }}
              />
              <AddButton onClick={() => setOpenSeasonModal(true)} />
            </div>
          </div>
        )}
      </div>

      <div style={{ ...custom_styles.border2, margin: "0" }}></div>

      {type !== 3 && (
        <>
          {/* Ticket Purchase in current session */}
          <div className="d-flex justify-content-between align-items-center ">
            {/* Checkbox */}
            <Form.Group className="w-50 d-flex justify-content-start ">
              <Form.Check
                className="remember-forgotpass"
                type="checkbox"
                id="current_season"
                checked={
                  selelectedData
                    ? selelectedData?.isTicketPurchasesInCurrentSeason
                    : false
                }
                onChange={(e) => {
                  setSelectedData((prev) => ({
                    ...prev,
                    isTicketPurchasesInCurrentSeason: e.target.checked,
                  }));
                }}
              />
              <label
                htmlFor="current_season"
                style={{ ...custom_styles.font_label, cursor: "pointer" }}
                className=" me-4 w-75"
              >
                Ticket Purchased in current season
              </label>
            </Form.Group>

            {/* First Purchased Input */}
            {selelectedData?.isTicketPurchasesInCurrentSeason && (
              <div
                className="form-group d-flex justify-content-end align-items-center w-50"
                style={{ ...custom_styles.font_label }}
              >
                <label
                  className="form-label col-form-label w-25"
                  style={{ color: "#000000B2" }}
                >
                  First Game Date
                </label>
                <div className="d-flex  justify-content-end w-75">
                  <Form.Control
                    type="date"
                    value={selelectedData?.firstPuchaseDate}
                    onChange={(e) => {
                      setSelectedData((prev) => ({
                        ...prev,
                        firstPuchaseDate: e.target.value,
                      }));
                    }}
                    required
                    style={{
                      width: "80%",
                      marginRight: "18%",
                      height: "32px",
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div style={{ ...custom_styles.border2, margin: "0" }}></div>

          {/* Ticket Details - Table */}
          <div className="d-flex flex-column gap-2">
            <span className="small text-muted">Ticket details</span>
            <Table bordered hover>
              <thead style={{ backgroundColor: "#f5f5f5" }}>
                <tr>
                  {globalSubInvoiceType != "Full Season" &&
                  globalSubInvoiceType != "Half Season" &&
                  globalSubInvoiceType != "Full Strip" &&
                  globalSubInvoiceType != "Half Strip" &&
                  globalSubInvoiceType != "Single Round" ? (
                    <th
                      className="text-center"
                      style={{
                        ...custom_styles.font_label,
                        color: "black",
                        width: "200px",
                      }}
                    >
                      Event Date <span className="text-muted">(Optional)</span>
                    </th>
                  ) : null}
                  <th
                    className="text-center"
                    style={{ ...custom_styles.font_label, color: "black" }}
                  >
                    Section{" "}
                    <span style={{ color: "red", fontWeight: "normal" }}>
                      *
                    </span>
                  </th>
                  <th
                    className="text-center"
                    style={{ ...custom_styles.font_label, color: "black" }}
                  >
                    Row{" "}
                    <span style={{ color: "red", fontWeight: "normal" }}>
                      *
                    </span>
                  </th>
                  <th
                    className="text-center"
                    style={{ ...custom_styles.font_label, color: "black" }}
                  >
                    Low Seat{" "}
                    <span style={{ color: "red", fontWeight: "normal" }}>
                      *
                    </span>
                  </th>
                  <th
                    className="text-center"
                    style={{ ...custom_styles.font_label, color: "black" }}
                  >
                    High Seat{" "}
                    <span style={{ color: "red", fontWeight: "normal" }}>
                      *
                    </span>
                  </th>
                  <th
                    className="text-center"
                    style={{ ...custom_styles.font_label, color: "black" }}
                  >
                    Qty{" "}
                    <span style={{ color: "red", fontWeight: "normal" }}>
                      *
                    </span>
                  </th>
                  <th
                    className="text-center"
                    style={{ ...custom_styles.font_label, color: "black" }}
                  >
                    Price($){" "}
                    {/* <span style={{ color: "red", fontWeight: "normal" }}>
                      *
                    </span> */}
                  </th>
                  <th
                    className="text-center"
                    style={{ ...custom_styles.font_label, color: "black" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!TableData?.length ? (
                  <tr>
                    <td colSpan="8" className="no-border">
                      <center>No Data</center>
                    </td>
                  </tr>
                ) : (
                  TableData?.map((data, ind) => {
                    return (
                      <tr
                        key={ind}
                        style={{
                          ...custom_styles.font_label,
                        }}
                      >
                        {globalSubInvoiceType != "Full Season" &&
                        globalSubInvoiceType != "Half Season" &&
                        globalSubInvoiceType != "Full Strip" &&
                        globalSubInvoiceType != "Half Strip" &&
                        globalSubInvoiceType != "Single Round" ? (
                          <td style={{ position: "relative", height: "32px" }}>
                            {currentRow === ind ||
                            data?.eventDate === "" ||
                            !data.eventDate ? (
                              <input
                                name="eventDate"
                                className="border-0 bg-transparent text-center cursor"
                                type="date"
                                onChange={(e) =>
                                  HandleTableOperation(
                                    "update",
                                    e,
                                    ind,
                                    data.id
                                  )
                                }
                                value={data?.eventDate ?? ""}
                                onMouseOut={() => setCurrentRow(-1)}
                                style={{ height: "32px" }}
                              />
                            ) : (
                              <label
                                title="Click to edit"
                                onMouseOut={() => setCurrentRow(-1)}
                                onMouseEnter={() => setCurrentRow(ind)}
                                style={{ width: "155px", cursor: "pointer" }}
                                className="text-center"
                              >
                                {formatDate(data?.eventDate)}
                              </label>
                            )}
                          </td>
                        ) : null}

                        <td style={{ height: "32px" }}>
                          <Form.Control
                            name="section"
                            className="border-0 bg-transparent text-center"
                            type="text"
                            value={data?.section != "NONE" ? data?.section : ""}
                            style={custom_styles.formInput}
                            onChange={(e) =>
                              HandleTableOperation("update", e, ind, data.id)
                            }
                            placeholder="Enter"
                          />
                        </td>
                        <td style={{ height: "32px" }}>
                          <Form.Control
                            name="row"
                            className="border-0 bg-transparent text-center"
                            placeholder="0"
                            type="text"
                            value={data?.row}
                            style={custom_styles.formInput}
                            onChange={(e) => {
                              const regex = /^[A-Za-z0-9]*$/;

                              if (!regex.test(e.target.value)) {
                                return;
                              }
                              HandleTableOperation("update", e, ind, data.id);
                            }}
                          />
                        </td>
                        <td style={{ height: "32px" }}>
                          <Form.Control
                            name="lowSeat"
                            className="border-0 bg-transparent text-center"
                            placeholder="0"
                            type="number"
                            value={data?.lowSeat}
                            style={custom_styles.formInput}
                            onChange={(e) =>
                              HandleTableOperation("update", e, ind, data.id)
                            }
                          />
                        </td>
                        <td style={{ height: "32px" }}>
                          <Form.Control
                            name="highSeat"
                            className="border-0 bg-transparent text-center"
                            placeholder="0"
                            type="number"
                            value={data?.highSeat}
                            style={custom_styles.formInput}
                            onChange={(e) =>
                              HandleTableOperation("update", e, ind, data.id)
                            }
                          />
                        </td>
                        <td style={{ height: "32px" }}>
                          <Form.Control
                            name="qty"
                            className="border-0 bg-transparent text-center"
                            placeholder="0"
                            type="number"
                            value={data?.qty}
                            style={custom_styles.formInput}
                            onChange={(e) =>
                              HandleTableOperation("update", e, ind, data.id)
                            }
                          />
                        </td>
                        <td style={{ height: "32px" }}>
                          <Form.Control
                            name="price"
                            className="border-0 bg-transparent text-center"
                            placeholder="$0.0"
                            type="text"
                            value={formatMoneyWithCommas(
                              formatDollarMoneyWithoutFloat(data?.price)
                            )}
                            style={custom_styles.formInput}
                            onChange={(e) => {
                              HandleTableOperation("update", e, ind, data.id);
                            }}
                          />
                        </td>
                        <td>
                          <div
                            style={{ height: "32px" }}
                            className="d-flex  align-items-center justify-content-center"
                          >
                            <img
                              src={DeleteIcon}
                              style={{
                                cursor: "pointer",
                                width: "16px",
                                height: "16px",
                                objectFit: "contain",
                              }}
                              onClick={() =>
                                HandleTableOperation("delete", null, ind, null)
                              }
                              alt="deleteicon"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
            <Button
              onClick={() => HandleTableOperation("add")}
              className=""
              style={{
                ...custom_styles.add_btn,
                fontSize: "14px",
                width: "81px",
              }}
            >
              + Add
            </Button>
          </div>
        </>
      )}

      {
        <>
          <div style={{ ...custom_styles.border2, margin: "0" }}></div>
          <div className="d-flex flex-column gap-2">
            <span className="text-muted">Fee Details (Optional)</span>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between ">
                {/* Select Fees */}
                <div className="form-group d-flex align-items-center w-50">
                  <label
                    className="form-label col-form-label "
                    style={custom_styles.font_label}
                  >
                    Select Fees
                  </label>
                  <div className="d-flex align-items-center gap-2  w-75">
                    <MultiSelect
                      options={feeList}
                      optionLabel="name"
                      selectAllLabel="Select All"
                      che
                      panelFooterTemplate={
                        <div className=" d-flex justify-content-center">
                          {" "}
                          <button
                            className=" border-none  px-4 py-3 border border-top-2 mx-auto"
                            onClick={() => {
                              const transformedFees = globalFeeDropdown.map(
                                (item) => ({
                                  name: item.feeLabel,
                                  code: item.fees,
                                })
                              );
                              setFeeList(transformedFees);
                              dispatch(
                                setGlobalFeeInputs(
                                  globalFeeDropdown
                                    .filter((x) => x.fees != null)
                                    .map((item) => ({
                                      name: item.feeLabel,
                                      code: item.fees,
                                    }))
                                )
                              );
                            }}
                            style={{
                              color: "#C42D2D",
                              backgroundColor: "white",
                              cursor: "pointer",
                            }}
                          >
                            Reset Selection
                          </button>
                        </div>
                      }
                      value={feeDetails}
                      onChange={(e) => dispatch(setGlobalFeeInputs(e.value))}
                      placeholder="Select"
                      maxSelectedLabels={0}
                      className="w-75"
                    />
                    <AddButton
                      onClick={() => {
                        dispatch(
                          setGlobalFeeInputs([
                            ...globalFeeInputs,
                            {
                              name: "",
                              code: null,
                            },
                          ])
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap justify-content-between">
                {feeDetails?.map((item, index) => {
                  let labelStyle = {};
                  if (item?.name?.length > 11) {
                    labelStyle = {
                      minHeight: "40px",
                    };
                  } else {
                    labelStyle = {
                      height:
                        item?.name && item?.name?.length >= 1 ? "40px" : "50px",
                    };
                  }
                  return (
                    <div
                      key={index}
                      className="form-group d-flex align-items-center w-50 my-2"
                    >
                      <Form.Control
                        readOnly={isReadOnly(item)}
                        as="textarea"
                        placeholder="Enter Fee Type"
                        maxLength={30}
                        className="form-label col-form-label"
                        value={item?.name}
                        style={{
                          ...custom_styles.font_label,
                          border: "none",
                          outline: "none",
                          width: "15%",
                          boxShadow: "none",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          resize: "none",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          ...labelStyle,
                        }}
                        onChange={(e) => {
                          const oldFeeDetails = clone(globalFeeInputs);
                          oldFeeDetails[index] = {
                            ...oldFeeDetails[index],
                            name: e?.target?.value,
                          };
                          dispatch(setGlobalFeeInputs(oldFeeDetails));
                        }}
                      />

                      <div className="d-flex align-items-center gap-2  w-75">
                        <InputGroup style={{ width: "75%" }}>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{ ...custom_styles.form_input }}
                              className="rounded-0 rounded-start"
                            >
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="number"
                            // step="0.0001"
                            placeholder="0"
                            className="rounded-end"
                            value={item?.code == null ? "" : item?.code}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^\d{0,8}(\.\d*)?$/;
                              if (!regex.test(value)) {
                                return;
                              }
                              const oldFeeDetails = clone(feeDetails);
                              oldFeeDetails[index] = {
                                ...oldFeeDetails[index],
                                code:
                                  e?.target?.value === ""
                                    ? null
                                    : Number(e?.target?.value),
                              };
                              dispatch(setGlobalFeeInputs(oldFeeDetails));
                            }} // Handle onChange event
                            style={{ with: "50%" }}
                          />
                        </InputGroup>
                        <div className="d-flex justify-content-center px-2">
                          <img
                            src={DeleteIcon}
                            style={{
                              cursor: "pointer",
                              width: "16px",
                              height: "16px",
                              objectFit: "contain",
                            }}
                            onClick={() => {
                              let oldFeeDetails = clone(feeDetails);
                              oldFeeDetails = oldFeeDetails.filter(
                                (x, currentIndex) => currentIndex != index
                              );
                              dispatch(setGlobalFeeInputs(oldFeeDetails));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {showSameFeeMessage && (
                <div style={{ color: "red" }}>
                  Two fee with the same name exists
                </div>
              )}
            </div>
          </div>
        </>
      }

      {openSeasonModal && (
        <AddEditSeasonModal
          isVisible={openSeasonModal}
          handleClose={() => setOpenSeasonModal(false)}
          onSubmit={onEntrySubmitted}
        />
      )}

      {openSportModal && (
        <AddEditSportsModal
          isVisible={openSportModal}
          handleClose={() => setOpenSportModal(false)}
          onSubmit={(data) => onSubmitted(data, 1)}
        />
      )}

      {openTeamModal && (
        <AddEditTeamsModal
          isVisible={openTeamModal}
          handleClose={() => setOpenTeamModal(false)}
          onSubmit={(data) => CreateTeamSubmit(data, 2)}
        />
      )}
    </div>
  );
};

export default withLoader(InvoiceTicketDetails);

const AddButton = ({ onClick }) => {
  return (
    <span
      className="btn d-flex justify-content-center align-items-center btn-primary text-light"
      style={{
        height: "32px",
      }}
      onClick={onClick}
    >
      <span
        className="fs-3"
        style={{
          lineHeight: 0,
        }}
      >
        +
      </span>
    </span>
  );
};
