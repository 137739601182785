// MODALS
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import {
  setisAddFromInvoice,
  setIsPendingPOChanged,
  setIsPreview,
} from "../../data/reducers/purchaseOrders/po.reducer";
import { useHistory, useLocation, Link } from "react-router-dom";
import Redirect from "../../images/redirect.svg";
import { MdOutlineIndeterminateCheckBox } from "react-icons/md";
import Reset from "../../images/reset.svg";
import Up from "../../images/uparrow.svg";
import Down from "../../images/downarrow.svg";
import AddEditPOModal from "./modals/AddEditPOModal";
import PendingPOModal from "./modals/PendingPOModal";
import { columnError } from "../../shared/components/table/data/error";
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import MovePOConfirmationModal from "./modals/MovePOConfirmationModal";
import DeleteIcon from "../../images/Remove.svg";
import EditIcon from "../../images/edit.svg";
import HistoryIcon from "../../images/history1.svg";
import AddEditSeasonModal from "../season/modals/AddEditSeasonModal";
import SelectMatch from "../transactions/modals/SelectMatch";
import CollapsibleTable from "./CollapsibleTable";
import IconButton from "@mui/material/IconButton";
import HistoryPO from "./modals/HistoryPO";
import CardPanel from "../../shared/components/panels/card-panel/CardPanel";
import TableRowCard from "../../shared/components/table/TableRowCard";
import DateFilter from "../transactions/modals/DateFilter";
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import { withLoader } from "../../utils/hoc/withLoader";
import FilterPanelPO from "./filter-panel";
import Loader from "../../shared/components/loader/Loader";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import {
  createSeason,
  getSeasonsList,
} from "../../data/reducers/season/season.reducer";
import {
  formatDate,
  dateSlashFormatToDateObject,
  formatPlanDate,
} from "../../utils/formatter/dateFormatter";
import {
  loadPendingPurchaseOrderTransactionCards,
  loadPurchaseOrderTransactionCards,
} from "../../data/reducers/purchaseOrders/cached-po-cards.reducer";
import {
  createPO,
  updatePO,
  deletePO,
  togglePOSkyboxRef,
  setpendingPo,
  setshowStepperPO,
  setAllPendingPOTab,
  setSelectedPurchaseOrders,
} from "../../data/reducers/purchaseOrders/po.reducer";
import "../../shared/styles/ModalStyles.scss";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import {
  fetchPurchaseOrders,
  flushPurchaseOrders,
} from "../../data/reducers/purchaseOrders/cached-po.reducer";
import {
  fetchPurchaseOrdersCards,
  flushPurchaseOrdersCards,
} from "../../data/reducers/purchaseOrders/cached-po-cards.reducer";
import {
  setSelectedAccountDetails,
  setSelectedTicketDetails,
} from "../../data/reducers/accounts/accounts.reducers";
import { constants } from "../../config";

const getValidFilters = (filters) => {
  const arr = Object.entries(filters).filter(([key, value]) => {
    if (key === "PageNumber") {
      return value !== 1;
    }
    if (key === "PageSize") {
      return value !== 40;
    }
    if (key === "SortDir") {
      return value !== "Ascending";
    }
    if (key === "isReviewed") {
      return value !== "ALL";
    }

    if (
      key === "PurchaseOrderId" ||
      key === "Description" ||
      key === "SkyBoxPurchaseId" ||
      key === "SeasonId" ||
      key === "month" ||
      key === "SortField" ||
      key === "searchString" ||
      key === "SportId" ||
      key === "AccountId" ||
      key === "TeamId" ||
      key === "VendorId"
    ) {
      return value !== "";
    }
    return true;
  });

  const newFilters = Object.fromEntries(arr);
  if (newFilters.StartDate) {
    newFilters.StartDate = formatDate(newFilters.StartDate);
  }
  if (newFilters.EndDate) {
    newFilters.EndDate = formatDate(newFilters.EndDate);
  }
  return newFilters;
};

const MONTH_ARRAY = [
  {
    id: 1,
    name: "Last 12 Month",
    value: 12,
  },
  {
    id: 2,
    name: "Last 24 Month",
    value: 24,
  },
  {
    id: 3,
    name: "Last 36 Month",
    value: 36,
  },
  {
    id: 4,
    name: "Custom Range",
    value: null,
  },
];

const PendingPODashboard = ({ setBusy }) => {
  //dispatch
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // SELECTORS

  const {
    data: cachedPurchaseOrdersCards,
    loading: cardsLoading,
    callPendingPoCardsApi,
  } = useSelector((state) => state.cachedPurchaseOrdersCards);

  const { data: cachedPurchaseOrders, loading } = useSelector(
    (state) => state.cachedPurchaseOrders
  );

  // USESTATES
  const [monthSelect, setMonthSelect] = useState(12);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [matchedVisibility, setmatchedVisibility] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [addPOModalVisible, setAddEditPOModalVisibility] = useState(false);
  const [PendingPOModalVisible, setPendingPOModalVisible] = useState(false);
  const [historyModalVisible, setHistoryModalVisibility] = useState(false);
  const [moveConfirmationVisible, setMoveConfirmationVisible] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [addSeasonModalVisibility, setAddSeasonModalVisibility] =
    useState(false);
  const [Admin, setAdmin] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const { isPendingPOChanged } = useSelector(
    (state) => state.purchaseOrder
  );
  const [isRefresh, setIsRefresh] = useState(false)
  //use effect

  //------------------------ set default value --------------------//
  useEffect(() => {
    if (localStorage.getItem("USER_ROLE") === "Admin") setAdmin(true);
  }, []);

  const [filters, setFilters] = useState({
    searchString: params.get("searchString") || "",
    VendorId: params.get("VendorId") || "",
    PageNumber: Number(params.get("PageNumber")) || 1,
    PageSize: Number(params.get("PageSize")) || 40,
    PurchaseOrderId: params.get("PurchaseOrderId") || "",
    Description: params.get("Description") || "",
    AccountId: params.get("AccountId") || "",
    SortDir: params.get("SortDir") || "Ascending",
    SortField: params.get("SortField") || "",
    isReviewed: Number(params.get("isReviewed")) || "ALL",
    SportId: Number(params.get("SportId")) || "",
    TeamId: params.get("TeamId") || "",
    StartDate:
      dateSlashFormatToDateObject(params.get("StartDate")) ||
      new Date(new Date().setMonth(new Date().getMonth() - 12)),
    EndDate: dateSlashFormatToDateObject(params.get("endDate")) || new Date(),
    SeasonId: params.get("SeasonId") || "",
    SkyBoxPurchaseId: params.get("SkyBoxPurchaseId") || "",
    month: params.get("month") || "",
    statusId: 1,
  });

  const TAB_TO_STATUS_ID = {
    0: 1, // Pending Approvals
    1: 3, // Pending Corrections
    2: 2, // Rejected
  };

  // MEMOISED VALUES
  const { purchaseOrders, paging, timestamp } = useMemo(() => {
    const queryParams = new URLSearchParams(getValidFilters(filters));
    history.push(`/pendingPO?${queryParams}`);
    const data =
      cachedPurchaseOrders?.[
        JSON.stringify({
          ...filters,
          StartDate: formatDate(filters.StartDate),
          EndDate: formatDate(filters.EndDate),
          statusId: filters.statusId,
        })
      ];
    if (!data) {
      return { purchaseOrders: undefined, paging: undefined, timestamp: null };
    }
    return data;
  }, [cachedPurchaseOrders, filters, filters.statusId]);

  useEffect(() => {
    setCurrentUserEmail(localStorage.getItem(constants.KEY_USER_EMAIL));
  }, []);
  const { cards } = useMemo(() => {
    const stringifiedFilters = JSON.stringify({
      ...filters,
      StartDate: formatPlanDate(filters.StartDate),
      EndDate: formatPlanDate(filters.EndDate),
    });
    const data = cachedPurchaseOrdersCards?.[stringifiedFilters];
    if (!data) {
      return { data: undefined };
    }
    return data;
  }, [cachedPurchaseOrdersCards, filters]);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.PURCHASEORDERS,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.PURCHASEORDERS,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  const fetchAllPurchaseOrders = async () => {
    await dispatch(
      fetchPurchaseOrders({
        ...filters,
        StartDate: formatDate(filters.StartDate),
        EndDate: formatDate(filters.EndDate),
      })
    );
    setBusy(false)
    dispatch(setIsPendingPOChanged(false));
    setIsRefresh(isPendingPOChanged)
  }

  useEffect(() => {
    if (isPendingPOChanged != isRefresh) {
      fetchAllPurchaseOrders();
    }
    else {
      fetchAllPurchaseOrders();
    }
  }, [filters, isPendingPOChanged]);

  useEffect(() => {
    if (!cards && callPendingPoCardsApi != null) {
      dispatch(
        fetchPurchaseOrdersCards({
          ...filters,
          StartDate: formatPlanDate(filters.StartDate),
          EndDate: formatPlanDate(filters.EndDate),
        })
      );
    }
  }, [callPendingPoCardsApi]);

  const previousPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage - 1,
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage + 1,
    });
  };

  const refetchAccountsAndCards = async () => {
    dispatch(
      fetchPurchaseOrders({
        ...filters,
        StartDate: formatDate(filters.StartDate),
        EndDate: formatDate(filters.EndDate),
      })
    );
  };

  const resetHandler = async () => {
    dispatch(flushPurchaseOrders());
    dispatch(flushPurchaseOrdersCards());
    dispatch(setSelectedPurchaseOrders([]));
    setSelectedCheckboxes({});
    refetchAccountsAndCards();
  };

  useEffect(() => {
    resetHandler();
    dispatch(setisAddFromInvoice(false));
  }, []);

  // when the selected tab changes, change the global state of the tab index to reduce the inconsistency
  useEffect(() => {
    dispatch(setAllPendingPOTab(selectedTabIndex));
  }, [selectedTabIndex]);

  // DELETE
  const onDeletePurchaseOrder = async (id) => {
    try {
      setBusy(true);
      const response = await dispatch(deletePO(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteConfirmationVisibility(false);
        resetHandler();
        dispatch(successMessage("Purchase Order was deleted successfully"));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  const addSeason = async (data) => {
    if (data?.startDate > data?.endDate) {
      dispatch(errorMessage("Start Date Cannot be Greater Than End Date"));
    } else {
      try {
        const message = data.id
          ? `Season Updated Successfully`
          : `New Season Created Successfully`;
        setBusy(true);
        const action = createSeason(data);
        const response = await dispatch(action);
        await dispatch(getSeasonsList());
        if (response.error) {
          dispatch(errorMessage(response.error.message));
        } else {
          dispatch(successMessage(message));
          setAddSeasonModalVisibility(false);
          setAddEditPOModalVisibility(true);
        }
      } catch (e) {
        dispatch(errorMessage(e));
      } finally {
        setBusy(false);
      }
    }
  };

  const movePOHandler = async (id) => {
    try {
      setBusy(true);

      const response = await dispatch(togglePOSkyboxRef(id));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setMoveConfirmationVisible(false);
        resetHandler();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // ADD/UPDATE PO
  const addEditHandler = async (_data) => {
    const data = _data;
    data.showInNoSkyboxRef = !!_data.showInNoSkyboxRef;
    data.totalPaid = _data.totalPaid == null ? 0 : _data.totalPaid;
    try {
      setBusy(true);
      const message = data.id
        ? `Purchase Order Updated Successfully`
        : `New Purchase Order Created Successfully`;
      const action = data?.id
        ? updatePO({
            ...data,
            purchaseOrderId: String(data?.purchaseOrderId),
            totalScheduled:
              data?.totalScheduled != ""
                ? parseFloat(data?.totalScheduled)
                : parseFloat(0),
          })
        : createPO({
            ...data,
            purchaseOrderId: String(data?.purchaseOrderId),
            totalScheduled:
              data?.totalScheduled != ""
                ? parseFloat(data?.totalScheduled)
                : parseFloat(0),
            skyBoxRef:
              data?.skyBoxRef === "" ? null : data?.skyBoxRef?.toString(),
          });
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        setAddEditPOModalVisibility(false);
        resetHandler();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  //cards data
  const cardsData = [
    {
      head: "Scheduled",
      val:
        cards?.scheduled == null || cards?.scheduled == undefined
          ? "--"
          : formatMoney(cards?.scheduled),
    },
    {
      head: "Paid",
      val:
        cards?.paid == null || cards?.paid == undefined
          ? "--"
          : formatMoney(cards?.paid),
    },
    {
      head: "Receivables",
      val:
        cards?.receivables == null || cards?.receivables == undefined
          ? "--"
          : formatMoney(cards?.receivables),
    },
    {
      head: "Transaction",
      val:
        cards?.numberofTransaction == null ||
        cards?.numberofTransaction == undefined
          ? "--"
          : cards?.numberofTransaction,
    },
  ];

  const handleAddFromInvoicePO = (data) => {
    dispatch(setSelectedAccountDetails({}));
    dispatch(setSelectedTicketDetails({}));
    let step = 5;
    switch (data.mainInvoiceType) {
      case "Deposit":
        step = 4;
        break;
      default:
        break;
    }

    history.push(`/purchase/add-invoice/${data.id}/${step}`); // Use the id here
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: isChecked,
    }));
    const selectedOrders = purchaseOrders.filter((order) => {
      return { ...selectedCheckboxes, [id]: isChecked }[order.id];
    });

    dispatch(setSelectedPurchaseOrders(selectedOrders));
    const allSelected =
      Object.keys(selectedCheckboxes).length + 1 === purchaseOrders.length &&
      isChecked;

    setSelectAll(allSelected);
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    if (purchaseOrders.length === 0) {
      setSelectAll(false);
      return;
    }
    const newSelection = purchaseOrders.reduce((acc, order) => {
      if (order.createdBy == currentUserEmail) {
        return acc;
      }
      acc[order.id] = isChecked;
      return acc;
    }, {});

    const localPurchaseOrders = purchaseOrders?.filter((x) => {
      return x.createdBy != currentUserEmail;
    });

    setSelectedCheckboxes(newSelection);
    setSelectAll(isChecked);
    dispatch(setSelectedPurchaseOrders(isChecked ? localPurchaseOrders : []));
  };

  const unselectAllCheckboxes = () => {
    setSelectedCheckboxes({});
    setSelectAll(false);
    dispatch(setSelectedPurchaseOrders([]));
  };

  const anySelected = Object.values(selectedCheckboxes).some(Boolean);
  const allSelected =
    Object.keys(selectedCheckboxes).length > 0 &&
    Object.values(selectedCheckboxes).every(Boolean);

  const handleApprove = () => {
    setActionType("approve");
    setPendingPOModalVisible(true);
    setSelectAll(false);
  };

  const handleReject = () => {
    setActionType("reject");
    setPendingPOModalVisible(true);
    setSelectAll(false);
  };

  const handleCorrection = () => {
    setActionType("correction");
    setPendingPOModalVisible(true);
    setSelectAll(false);
  };

  // const selectedTabIndex = ISREVIEWED_STR_TO_NUM[filters.isReviewed];
  //table data
  const poCol = [
    {
      columnName: (
        <div
          style={{
            display: "flex",
            alignItems: "center", // Center vertically
            margin: "10px 0", // Space between items
          }}
        >
          {anySelected && !allSelected ? (
            <MdOutlineIndeterminateCheckBox
              onClick={() => {
                setSelectedCheckboxes({});
                setSelectAll(false);
                dispatch(setSelectedPurchaseOrders([]));
              }}
              style={{
                width: "18px",
                height: "18px",
                cursor: "pointer",
              }}
            />
          ) : (
            <input
              className="form-check-input"
              type="checkbox"
              id="flexCheckIndeterminate"
              checked={selectAll}
              onChange={handleSelectAllChange}
              disabled={!Admin}
              style={{
                width: "18px",
                height: "18px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
      ),
      flexGrow: 1,
      render: (data) => (
        <div className="form-check d-flex align-items-center">
          {" "}
          <input
            className="form-check-input me-2"
            type="checkbox"
            id={`checkbox-${data.id}`}
            disabled={
              !Admin ||
              purchaseOrders.length === 0 ||
              data?.createdBy == currentUserEmail
            }
            checked={selectedCheckboxes[data.id] || false}
            onChange={(e) => {
              handleCheckboxChange(e, data.id);
            }}
            style={{
              width: "18px",
              height: "18px",
              cursor: "pointer",
            }}
          />
        </div>
      ),
    },
    {
      columnName: "Purchase Order",
      sort: true,
      sortName: "PurchaseOrderId",
      flexGrow: 2,
      render: (data) => (
        <>
          <Link
            variant="link"
            className="ss-link"
            to={"/purchases/" + data.id}
            style={{ overflowWrap: "break-word" }}
          >
            {data.purchaseOrderId}
          </Link>
        </>
      ),
    },
    {
      columnName: "Date",
      sort: true,
      sortName: "PurchaseOrderDate",
      render: (data) => (
        <>{data?.purchaseOrderDate ? formatDate(data.purchaseOrderDate) : ""}</>
      ),
    },
    {
      columnName: "Vendor",
      render: (data) => (
        <Link
          variant="link"
          className="ss-link"
          to={"/vendors/" + data.vendorId}
          style={{ overflowWrap: "break-word" }}
        >
          {data.vendorName}
          <br />
          {data.vendorId ? data?.vendorId : ""}
        </Link>
      ),
      sort: true,
      sortName: "VendorId",
    },
    {
      columnName: "Name / Account ID",
      sort: true,
      sortName: "AccountId",
      render: (data) => (
        <>
          {" "}
          {data?.autoGenerated === false ? (
            <Link
              variant="link"
              className="ss-link"
              to={"/accounts/" + data.accountId + "?vendorId=" + data?.vendorId}
            >
              {data.accountName} <br />
              {data.accountId == 0 ? "" : data.accountId}
            </Link>
          ) : (
            <span>
              {data.accountName} <br />
              {data.accountId == 0 ? "" : data.accountId}
            </span>
          )}
        </>
      ),
    },
    {
      columnName: "Season",
      columnKey: "seasonName",
      render: (row) => <>{row?.seasonName}</>,
      //  sort: true,
      // sortName: 'SeasonName',
      // render: (data) => <>{data.}</>,
    },
    {
      columnName: "Description",
      sort: true,
      sortName: "Description",
      flexGrow: 2,
      render: (data) => <>{data.description}</>,
    },
    {
      columnName: (
        <>
          SkyBox <br /> Ref{" "}
        </>
      ),
      sort: true,
      sortName: "SkyBoxRef",
      render: (row, ind, open, setOpen, getPurchaseOrderRefsData, data) => (
        <div>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (open[ind]) {
                setOpen(Array(data?.length).fill(false));
              } else {
                const temp = Array(data?.length).fill(false);
                temp[ind] = !temp[ind];
                setOpen(temp);
              }
              if (!open[ind]) {
                getPurchaseOrderRefsData(row?.id);
              }
            }}
          >
            <span style={{ fontSize: "14px" }}>{row.skyBoxRef}</span>
            <img
              src={open[ind] ? Up : Down}
              style={{ marginLeft: "5px" }}
              alt="up down image"
            />
          </IconButton>
        </div>
      ),
    },
    {
      columnName: "SkyBox Value",
      render: (data) => <>{formatMoney(data?.skyboxRefAmount)}</>,
    },
    {
      columnName: "Total Scheduled",
      sort: true,
      sortName: "TotalScheduled",
      render: (data) => <>{formatMoney(data.totalScheduled)}</>,
    },
    {
      columnName: (
        <>
          {" "}
          Total <br /> Paid{" "}
        </>
      ),
      render: (data) => <>{formatMoney(data.totalPaid)}</>,
    },
    {
      columnName: "Actions",
      flexGrow: 2,
      render: (data) => (
        <>
          <small className="table-row-card-actions  d-flex gap-1">
            <Button
              variant="link"
              className="table-action-button p-0 blue_bg"
              onClick={() => {
                dispatch(setshowStepperPO(false));
                const pendingState = true;

                dispatch(setpendingPo(pendingState));
                dispatch(setIsPreview(false));

                if (data.mainInvoiceType) {
                  handleAddFromInvoicePO(data);
                }
              }}
            >
              <img
                style={{ height: "100%", width: "28px" }}
                src={Redirect}
                alt="Redirect to Invoice"
              />
            </Button>
            {selectedTabIndex === 2 ? (
              Admin ? (
                <Button
                  variant="link"
                  onClick={() => {
                    setSelectedRecordId(data.id);
                    setSelectedRecord(data);
                    setDeleteConfirmationVisibility(true);
                  }}
                >
                  <img
                    style={{
                      height: "100%",
                      width: "16px",
                      color: "red !important",
                    }}
                    src={DeleteIcon}
                    alt="Delete Purchase Orders"
                  />
                </Button>
              ) : null
            ) : selectedTabIndex !== 0 || Admin ? (
              <Button
                variant="link"
                className="table-action-button green_bg"
                onClick={() => {
                  const pendingState = true;
                  dispatch(setpendingPo(pendingState));
                  dispatch(setshowStepperPO(true));
                  dispatch(setIsPreview(false));

                  // Dispatch the action to set the pending state
                  if (data.mainInvoiceType) {
                    handleAddFromInvoicePO(data);
                  }
                }}
              >
                <img
                  style={{ height: "100%", width: "13px" }}
                  src={EditIcon}
                  alt="Edit Purchase Orders"
                />
              </Button>
            ) : null}

            <Button
              variant="link"
              className="table-action-button"
              style={{
                display: "grid",
                placeContent: "center",
                backgroundColor: "transparent",
              }}
              onClick={() => {
                setSelectedRecordId(data.id);
                setSelectedRecord(data);
                setHistoryModalVisibility(true);
              }}
            >
              <img
                style={{ height: "auto", width: "25px" }}
                src={HistoryIcon}
                alt="Edit Purchase Orders"
              />
            </Button>
          </small>
        </>
      ),
    },
  ];

  //table head options
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per Page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue={filters.PageSize}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  PageSize: e.target.value,
                });
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div>
          <div className="text-end mt-3 d-flex">
            {Admin && (
              <>
                <Button
                  variant="primary"
                  style={{ textWrap: "nowrap" }}
                  className="mx-2 ss-light-button"
                  onClick={handleApprove}
                  disabled={!anySelected}
                >
                  <span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                      }}
                    >
                      Approve
                    </div>
                  </span>
                </Button>

                <Button
                  variant="primary"
                  style={{ textWrap: "nowrap" }}
                  className="mx-2 ss-light-button"
                  onClick={handleCorrection}
                  disabled={!anySelected || selectedTabIndex == 1} // request changes button will be disabled when no entries selected,  or the tab is pending corrections
                >
                  <span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                      }}
                    >
                      Request Changes
                    </div>
                  </span>
                </Button>

                <Button
                  variant="primary"
                  style={{ textWrap: "nowrap" }}
                  className="mx-2 ss-light-button"
                  onClick={handleReject}
                  disabled={!anySelected || selectedTabIndex == 2} // disabling the button when no entry is selected or the selected tab is the reject one.
                >
                  <span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        color: "#FF0000",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                      }}
                    >
                      Reject
                    </div>
                  </span>
                </Button>
              </>
            )}

            <Button
              onClick={() => {
                resetHandler();
                dispatch(successMessage("Refresh successful"));
              }}
              variant="primary"
              className="button ss-light-button ms-auto"
            >
              <img className="filter-reset-svg" src={Reset} alt=" " />
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  Refresh
                </div>
              </span>
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.PURCHASEORDERS,
        });
      }}
      className="container-fluid pt-3"
    >
      <DateFilter
        monthArray={MONTH_ARRAY}
        monthSelect={monthSelect}
        setMonthSelect={setMonthSelect}
        setEndDate={(date) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            EndDate: date,
          }));
        }}
        setStartDate={(date) => {
          setFilters((prevFilters) => ({
            ...prevFilters,
            StartDate: date,
          }));
        }}
        startDate={filters.StartDate}
        endDate={filters.EndDate}
        loadButtonAction={loadPendingPurchaseOrderTransactionCards(
          !callPendingPoCardsApi
        )}
        showLoadButton={true}
        loadButtonText={"Load Card Values"}
      />
      {/* Summary Cards */}
      <CardPanel data={cardsData} loader={cardsLoading} />
      {/* Filter Panel */}
      <FilterPanelPO filters={filters} setFilters={setFilters} />
      {/* Data Table */}
      <CollapsibleTable
        loading={loading}
        align={"left"}
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="Pending Purchase Orders"
        paging={paging}
        pageType="purchase-orders"
        columns={poCol}
        data={purchaseOrders || []}
        headOptions={headOptions}
        tabs={["Pending Approvals", "Pending Corrections", "Rejected"]}
        selectedTabIndex={selectedTabIndex}
        onTabSelectionChange={(i) => {
          setSelectedTabIndex(i);
          setSelectedCheckboxes({});
          setSelectAll(false);
          dispatch(setSelectedPurchaseOrders([]));
          setFilters({
            ...filters,
            statusId: TAB_TO_STATUS_ID[i],
            PageNumber: 1,
          });
          resetHandler();
        }}
        setBusy={setBusy}
        onChangeFieldDirection={({ sortField, sortDirection }) => {
          setFilters({
            ...filters,
            SortField: sortField,
            SortDir: sortDirection ? "Ascending" : "Descending",
          });
        }}
        sortField={filters.SortField}
        sortDir={filters.SortDir === "Ascending" || filters.SortDir === ""}
        setSortField={() => {}}
        setSortDir={() => {}}
        setpageNumber={() => {}}
        unselectAllCheckboxes={unselectAllCheckboxes}
        renderRow={(rowData) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : poCol}
            rowData={rowData}
            isSelected={selectedCheckboxes[rowData.id]}
            onCheckboxChange={(e) => handleCheckboxChange(e, rowData.id)}
          />
        )}
      />
      {/* MODALS */}
      {addPOModalVisible && (
        <AddEditPOModal
          isVisible={addPOModalVisible}
          handleClose={() => setAddEditPOModalVisibility(false)}
          onSubmit={addEditHandler}
          selectedRecord={selectedRecord}
          setModalOpen={setAddSeasonModalVisibility}
          setSelectedRecord={setSelectedRecord}
          setBusy={setBusy}
        />
      )}
      {PendingPOModalVisible && (
        <PendingPOModal
          isVisible={PendingPOModalVisible}
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          purchaseOrders={purchaseOrders}
          actionType={actionType}
          handleClose={() => setPendingPOModalVisible(false)}
          resetHandler={resetHandler}
        />
      )}
      {/* {PendingPOModalVisible && (
        <POHistoryModal
          isVisible={PendingPOModalVisible}
          selectedCheckboxes={selectedCheckboxes}
          setSelectedCheckboxes={setSelectedCheckboxes}
          purchaseOrders={purchaseOrders}
          actionType={actionType}
          handleClose={() => setPendingPOModalVisible(false)}
          resetHandler={resetHandler}
        />
      )} */}
      {historyModalVisible && (
        <HistoryPO
          isVisible={historyModalVisible}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          handleClose={() => setHistoryModalVisibility(false)}
        />
      )}
      {deleteConfirmationVisible && (
        <DeleteConfirmationModal
          isVisible={deleteConfirmationVisible}
          handleClose={() => setDeleteConfirmationVisibility(false)}
          onConfirmation={() => onDeletePurchaseOrder(selectedRecordId)}
        />
      )}
      {moveConfirmationVisible && (
        <MovePOConfirmationModal
          isVisible={moveConfirmationVisible}
          handleClose={() => {
            setMoveConfirmationVisible(false);
          }}
          selectedRecord={selectedRecord}
          onConfirmation={movePOHandler}
        />
      )}
      {addSeasonModalVisibility && (
        <AddEditSeasonModal
          isVisible={addSeasonModalVisibility}
          onSubmit={addSeason}
          handleClose={() => {
            setAddSeasonModalVisibility(false);
            setAddEditPOModalVisibility(true);
          }}
        />
      )}
      {matchedVisibility && (
        <SelectMatch
          isVisible={matchedVisibility}
          setIsVisible={setmatchedVisibility}
          handleClose={() => setmatchedVisibility(false)}
          match={true}
          selectedRecord={selectedRecord}
        />
      )}
    </div>
  );
};

export default withLoader(PendingPODashboard);
