import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { withLoader } from "../../../utils/hoc/withLoader";
import EyeIcon from "../../../images/ShowEye.svg";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import DatePicker from "react-datepicker";
import AddBtn from "../../../images/add.svg";
import CreatableSelect from "react-select/creatable";
import {
  getAddressByID,
  getAddressList,
} from "../../../data/reducers/addressBook/addressBook.reducer";
import { getCardTypeDetails } from "../../../data/reducers/cards/cards.routes";
import Loader from "../../../shared/components/loader/Loader";

const AddEditAccountModal = ({
  setBusy,
  isVisible,
  handleThisClose,
  onSubmit,
  added,
  selectedRecord,
  setSelectedRecord,
  setModalOpen,
  minorLoading,
  modalOpen,
  timezone,
  onDateChange,
}) => {
  const dispatch = useDispatch();
  const [dropVal, setdropVal] = useState({
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    gender: "",
    dateOfBirth: "",
    cardType: "",
    address: "",
    zipcode: "",
    mobileNumber: "",
  });

  const [showCardType, setshowCardType] = useState(false);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");

  //If selected Record Exists aka Edit else Add
  const [isEdit, setIsEdit] = useState(false);
  const [newAddress, setNewAddress] = useState(null);
  const emailData = useSelector((state) => state.emails).emails;
  const genderData = useSelector((state) => state.emails).genderList;
  const addressDetails = useSelector((state) => state.addressBook).addressList;
  const selectedAddress = useSelector(
    (state) => state.addressBook
  ).selectedAddress;
  const recoveryEmailsList = useSelector(
    (state) => state.emails
  ).recoveryEmails;

  useEffect(() => {
    const res = dispatch(getAddressList());
  }, []);

  useEffect(async () => {
    if (added) {
      await getAddressByID(added);
    }
  }, [added, selectedAddress, addressDetails]);

  useEffect(async () => {
    if (selectedRecord) {
      setBusy(true);
      if (selectedRecord.addressId)
        await dispatch(getAddressByID(selectedRecord.addressId));
      setIsEdit(true);
      if (added) {
        await getAddressByID(added);
      }
      for (const [key, value] of Object.entries(selectedRecord)) {
        setValue(key, value, {
          shouldValidate: !selectedRecord.redirect,
          shouldDirty: true,
        });
      }
      setdropVal({
        ...dropVal,
        address: selectedAddress
          ? addressDetails.filter((det) => det.id == selectedAddress)[0]
          : addressDetails.filter(
            (det) => det.id == selectedRecord.addressId
          )[0],
        gender: genderData.filter((el) => el.id === selectedRecord.genderId)[0],
        cardType: cardTypeLookup.filter(
          (x) => x.id === selectedRecord?.cardTypeId
        )[0],
      });
      setBusy(false);
      setshowCardType(!!selectedRecord?.isCreateCardChecked);
    } else {
      setdropVal({
        firstname: "",
        lastname: "",
        username: "",
        password: "",
        gender: "",
        dateOfBirth: "",
        cardType: "",
        address: "",
        zipcode: "",
        mobileNumber: "",
      });
      setValue("");
      setshowCardType(false);
      reset();
    }
  }, [isVisible, added]);

  useEffect(() => {
    if (isVisible) {
      const recoveryEmail = recoveryEmailsList.find((x) => x.isDefault);
      if (recoveryEmail) {
        setValue("recoveryMail", recoveryEmail?.name, {
          shouldValidate: true,
          shouldDirty: true,
        });
      } else {
        setValue("recoveryMail", "forwarding@salemseats.com", {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
  }, [isVisible, recoveryEmailsList]);

  useEffect(() => {
    if (selectedRecord) {
      setdropVal({
        ...dropVal,
        address: addressDetails.filter(
          (det) => det.id == selectedRecord.addressId
        )[0],
        gender: genderData.filter((el) => el.id === selectedRecord.genderId)[0],
        cardType: cardTypeLookup.filter(
          (x) => x.id === selectedRecord?.cardTypeId
        )[0],
      });
      setValue("addressId", selectedRecord?.addressId, {
        shouldValidate: true,
        shouldDirty: true,
      });

      const zipCode = addressDetails?.find(
        (add) => add.id == selectedRecord?.addressId
      )?.postalCode;

      setValue("zipcode", zipCode ?? "", {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [addressDetails, selectedRecord]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    control,
    getValues,
    watch,
  } = useForm();

  const watchCreateCard = watch("isCreateCardChecked");
  const watchCreateTicketMasterChecked = watch(
    "isCreateTicketMasterAccountChecked"
  );

  useEffect(() => {
    if (watchCreateCard) {
      setshowCardType(true);
    } else {
      setshowCardType(false);
    }
  }, [watchCreateCard]);

  // //Handling Reset Button for moda;
  const handleReset = () => {
    for (const [key, value] of Object.entries(selectedRecord)) {
      setValue(key, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  //handling clear button for modal
  const handleClear = () => {
    setdropVal({
      firstname: "",
      lastname: "",
      username: "",
      password: "",
      gender: "",
      dateOfBirth: "",
      cardType: "",
      address: "",
      zipcode: "",
      mobileNumber: "",
    });
    setFormattedNumber("");
    setValue("addressId", "cardTypeId", "");
    reset();
    const recoveryEmail = recoveryEmailsList.find((x) => x.isDefault);
    if (recoveryEmail) {
      setValue("recoveryMail", recoveryEmail?.name, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else {
      setValue("recoveryMail", "forwarding@salemseats.com", {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    setValue("isCreateCardChecked", false);
    setValue("isCreateTicketMasterAccountChecked", false);
  };

  //styles for Select DropDown
  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  const [isShown, setIsShown] = useState(false);
  const togglePassword = () => {
    setIsShown(isShown ? false : true);
  };

  // Open Address Dialouge Box
  const openAddress = (e) => {
    e.preventDefault();
    setSelectedRecord({
      username: getValues("username"),
      email: getValues("email"),
      addressId: getValues("addressId"),
      redirect: true,
      firstName: getValues("firstName"),
      lastName: getValues("lastName"),
      password: getValues("password"),
      genderId: getValues("genderId"),
      isCreateCardChecked: getValues("isCreateCardChecked"),
      cardTypeId: getValues("cardTypeId"),
      isCreateTicketMasterAccountChecked: getValues(
        "isCreateTicketMasterAccountChecked"
      ),
      recoveryMail: getValues("recoveryMail"),
      mobileNumber: getValues("mobileNumber"),
    });
    setModalOpen(true);
  };

  // card type
  const [cardType, setCardType] = useState(null);
  const { cardTypeLookup } = useSelector((state) => state.cards);
  useEffect(() => {
    setCardType(null);
  }, []);

  useEffect(() => {
    dispatch(getCardTypeDetails());
  }, []);

  const getFormattedNumber = (number) => {
    if (!number) {
      return "";
    }
    const removeDividers = number.split("-").join("");

    if (removeDividers.length >= 10) {
      const firstThree = removeDividers.slice(0, 3);
      const secondThree = removeDividers.slice(3, 6);
      const lastRemaining = removeDividers.slice(6);
      return `${firstThree}-${secondThree}-${lastRemaining}`;
    } else if (removeDividers.length >= 7) {
      const firstThree = removeDividers.slice(0, 3);
      const secondThree = removeDividers.slice(3, 6);
      const lastRemaining = removeDividers.slice(6);
      return `${firstThree}-${secondThree}-${lastRemaining}`;
    } else if (removeDividers.length >= 4) {
      const firstThree = removeDividers.slice(0, 3);
      const lastRemaining = removeDividers.slice(3);
      return `${firstThree}-${lastRemaining}`;
    } else {
      return removeDividers;
    }
  };

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={handleThisClose}
      >
        <Form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ fontWeight: "500" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Emails</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/email",
                name: "Email",
                active: false,
              },
              {
                name: "New Email",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      First Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        autoComplete="nope"
                        isInvalid={!!errors.firstName}
                        {...register("firstName", { required: true })}
                        placeholder="Enter first name"
                        maxLength={30}
                      />
                      <Form.Control.Feedback type="invalid">
                        First name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Last Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        isInvalid={!!errors.lastName}
                        {...register("lastName", { required: true })}
                        placeholder="Enter last name"
                        maxLength={30}
                      />
                      <Form.Control.Feedback type="invalid">
                        Last name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Username
                    </Form.Label>
                    <Col sm="8">
                      <InputGroup>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          maxLength={30}
                          minLength={7}
                          placeholder="Enter username"
                          isInvalid={!!errors.username}
                          {...register("username", { required: true })}
                          required
                        />
                        <InputGroup.Prepend>
                          <InputGroup.Text>@gmail.com</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control.Feedback type="invalid">
                          Username is required.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Password
                    </Form.Label>
                    <Col sm="8">
                      <div style={{ display: "flex", position: "relative" }}>
                        <Form.Control
                          autoComplete="new-password"
                          placeholder="Enter password"
                          minLength={8}
                          type={isShown ? "text" : "password"}
                          name="password"
                          isInvalid={!!errors.password}
                          {...register("password", { required: true })}
                        />

                        <img
                          style={{
                            position: "absolute",
                            right: "8%",
                            top: "38%",
                            cursor: "pointer",
                          }}
                          src={EyeIcon}
                          alt=" "
                          onClick={togglePassword}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Password is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Gender
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="genderId"
                        control={control}
                        rules={{ required: true, message: "error message" }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required
                                isClearable
                                isInvalid={!!errors.genderId}
                                value={dropVal?.gender}
                                onChange={(e) => {
                                  field.onChange(e?.id);
                                  setdropVal({ ...dropVal, gender: e });
                                }}
                                styles={customStyles}
                                options={genderData}
                                getOptionLabel={(option) => `${option.name}`}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Gender is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }}
                      />

                      <Form.Control.Feedback type="invalid">
                        Gender is required.
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs={1}></Col>
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Birthdate
                    </Form.Label>
                    <Col sm="7">
                    <Form.Control
                        type="date"
                        isInvalid={!!errors.dateOfBirth}
                        {...register("dateOfBirth")}
                      />
                    </Col>
                  </Form.Group>
                </Col> */}
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Recovery Email (optional)
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="email"
                        placeholder="Enter Recovery Email"
                        disabled
                        {...register("recoveryMail")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Address
                    </Form.Label>
                    <Col sm="6">
                      <Controller
                        name="addressId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <CreatableSelect
                                required
                                isInvalid={!!errors.addressId}
                                value={dropVal?.address}
                                onChange={async (e) => {
                                  setNewAddress(e);
                                  if (e != undefined) {
                                    setValue("zipcode", e?.postalCode, {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    });
                                    field.onChange(e?.id);
                                    setdropVal({
                                      ...dropVal,
                                      address: e,
                                      zipcode: e?.postalCode,
                                    });
                                  }
                                }}
                                defaultInputValue={
                                  selectedRecord && selectedRecord?.address
                                }
                                styles={customStyles}
                                options={addressDetails}
                                getOptionLabel={(option) => {
                                  if (
                                    option.address1 != undefined ||
                                    option.address2 != undefined ||
                                    option.city != undefined ||
                                    option.state != undefined ||
                                    option.postalCode != undefined
                                  )
                                    return `${option.address1} ${option.address2} ${option.city} ${option.state} ${option.postalCode}`;
                                }}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Address is required.
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Col>
                    <Col xs={1}>
                      <Button onClick={openAddress}>
                        <img src={AddBtn} width={15} height={15} />
                      </Button>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Zip/Postal Code
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        isInvalid={!!errors.zipcode}
                        {...register("zipcode", {
                          required: watchCreateTicketMasterChecked
                            ? true
                            : false,
                        })}
                        placeholder="Enter code"
                        maxLength={5}
                        minLength={5}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid zip code.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Mobile Number
                    </Form.Label>
                    <Col sm="4">
                      <Controller
                        name="mobileNumber"
                        control={control}
                        rules={{ required: false }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <>
                              <InputGroup>
                                <InputGroup.Prepend>
                                  <InputGroup.Text>
                                    {countryCode}
                                  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                  type="text"
                                  placeholder="Mobile Number"
                                  value={formattedNumber}
                                  autoComplete="nope"
                                  onChange={(e) => {
                                    const number = getFormattedNumber(
                                      e?.target?.value
                                    );
                                    field.onChange(
                                      number ? countryCode + number : ""
                                    );
                                    setFormattedNumber(number);
                                  }}
                                />
                              </InputGroup>
                            </>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Check
                      className="remember-forgotpass"
                      type="checkbox"
                      label="Create Card"
                      {...register("isCreateCardChecked")}
                    />
                  </Form.Group>
                </Col>
                {showCardType && (
                  <Col sm={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Card Type
                      </Form.Label>
                      <Col sm="8">
                        <Controller
                          name="cardTypeId"
                          control={control}
                          rules={{ required: true, message: "error message" }}
                          render={({
                            field,
                            fieldState: { invalid, error },
                          }) => {
                            return (
                              <div>
                                <Select
                                  required
                                  isClearable
                                  value={dropVal?.cardType}
                                  onChange={(e) => {
                                    field.onChange(e?.id);
                                    setdropVal({ ...dropVal, cardType: e });
                                  }}
                                  styles={customStyles}
                                  options={cardTypeLookup}
                                  getOptionLabel={({ cardType }) => cardType}
                                />
                                {invalid && error ? (
                                  <div className="custom-invalid-feedback">
                                    Card Type is required.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Card type is required.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                    <br />
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Team Name (optional)
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          isInvalid={!!errors.teamName}
                          {...register("teamName")}
                          placeholder="Enter team name"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid team name.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Check
                      className="remember-forgotpass"
                      type="checkbox"
                      label="Create TicketMaster Account"
                      {...register("isCreateTicketMasterAccountChecked")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Check
                      className="remember-forgotpass"
                      type="checkbox"
                      label="Generic"
                      {...register("isGeneric")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              disabled={minorLoading === "add"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
              className="ss-modal-primary-btn mx-2"
            >
              {isEdit ? "Save" : "Add"}{minorLoading === "add" && <Loader />}
            </Button>
            <Button
              variant="light"
              onClick={() => handleClear()}
              className="ss-modal-secondary-btn mx-2"
            >
              {"Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default withLoader(AddEditAccountModal);
