/* eslint-disable no-mixed-spaces-and-tabs */
// COMPONENTS
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { Col, Button, Dropdown, Form, Container } from "react-bootstrap";
import PODetails from "./po-details";
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";
import { withLoader } from "../../utils/hoc/withLoader";
import {
  charges,
  paymentPlan,
} from "../../shared/components/table/data/purchaseOrderData";
import {
  getPOCardsById,
  updatePO,
} from "../../data/reducers/purchaseOrders/po.reducer";
import SelectMatch from "../transactions/modals/SelectMatch";
// REDUCERS
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import {
  getAllPOCharges,
  createPOCharges,
  updatePOCharges,
  deletePOCharges,
  exportCSVPurchaseCharges,
  exportPdfPurchaseCharges,
} from "../../data/reducers/purchaseOrders/po-charges.reducer";

import {
  getAllPOPayment,
  createPOPayment,
  updatePOPayment,
  exportCSVPayment,
  exportPdfPayment,
  uploadTemplatePOPP,
  downloadTemplatePOPP,
} from "../../data/reducers/purchaseOrders/po-paymentPlans.reducer";

import { getAllPOByID } from "../../data/reducers/purchaseOrders/po.reducer";

// MODALS
import AddEditPOCharges from "./modals/AddEditPOCharges";
import PaymentPlanModal from "./modals/paymentPlan";

import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";

// IMAGES
import Export from "../../images/export.svg";
import Add from "../../images/add_blue.svg";
import DownloadImg from "../../images/download.svg";
import Upload from "../../images/upload.svg";
import ModalBreadcrumb from "../../shared/components/modals/ModalBreadcrumb";
import { getAccountsList } from "../../data/reducers/accounts/accounts.reducers";
import { getPurchaseOrderRefs } from "../../data/reducers/skybox-purchases/skybox-purchases.reducer";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
  mixpanel_button_name,
} from "../../mixpanel";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { runRules } from "../../data/reducers/transactions/transactions.reducer";
import Run from "../../images/run.svg";
import { flushPurchaseOrders } from "../../data/reducers/purchaseOrders/cached-po.reducer";
import { flushPurchaseOrdersCards } from "../../data/reducers/purchaseOrders/cached-po-cards.reducer";
import Loader from "../../shared/components/loader/Loader";
import { fetchVendorsListForDropdown } from "../../data/reducers/vendors/cached-vendors.reducer";
import { success } from "toastr";
const SinglePurchaseOrderDetail = ({ setBusy }) => {
  const tabs = ["All Charges", "Payment Plan"];
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search);
  const tabIdx = queryParameters.get("tabIdx");
  const para = new URLSearchParams(location.tabIdx);

  const { id } = useParams();

  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedTabIndex, setTabIndex] = useState(tabIdx == 1 ? 1 : 0);
  const [poChargesModalVisible, setpoChargesModalVisible] = useState(false);
  const [paymentPlanModalVisible, setPaymentPlanModalVisible] = useState(false);

  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [paging, setpaging] = useState({});
  const [myMainId, setMainId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [column, setColumn] = useState(charges);
  const [data, setData] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [matchedVisibility, setmatchedVisibility] = useState(false);
  const [minorLoading, setMinorLoading] = useState(false);

  // SELECTORS
  const details = useSelector((state) => state.purchaseOrder).selectedPO;
  const chargesList = useSelector(
    (state) => state.purchaseOrderCharge
  ).purchaseOrderCharges;
  const paymentList = useSelector(
    (state) => state.purchaseOrderPayment
  ).purchaseOrderPayment;
  const selectedPayment = useSelector(
    (state) => state.purchaseOrderPayment
  ).selectedPayment;
  const chargesPaging = useSelector(
    (state) => state.purchaseOrderCharge
  ).paging;
  const paymentPaging = useSelector(
    (state) => state.purchaseOrderPayment
  ).paging;
  const cards = useSelector((state) => state.purchaseOrder).cards;
  const skyBoxRefDataList = useSelector((state) => state.skybox).skyBoxRefData;
  const fileRef = useRef();
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    getOrderRefs();
  }, []);

  const handleChange = async (e) => {
    try {
      setBusy(true);
      const [file] = e.target.files;
      const formData = new FormData();
      formData.append("file", file);
      const response = await dispatch(
        uploadTemplatePOPP({ file: formData, id })
      );
      if (response.error) {
        dispatch(errorMessage(response.error.message));
        e.target.value = "";
      } else {
        window.location.reload();
        dispatch(successMessage("Upload successful"));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };
  // SUBMIT
  const onEntrySubmitted = async (data, option) => {
    try {
      setMinorLoading("add")
      const message = data.id
        ? `Purchase Order ${tabs[selectedTabIndex]} Updated Successfully`
        : `New Purchase Order ${tabs[selectedTabIndex]} Created Successfully`;
      switch (option) {
        case 0: {
          const action = data.id
            ? updatePOCharges({
              ...data,
              purchaseOrdersId: details?.purchaseOrderId,
              purchaseOrderId: parseInt(details?.id),
            })
            : createPOCharges({
              ...data,
              accountId: details?.accountId,
              matched: data?.matched === "true" ? true : false,
              amount: parseFloat(data?.amount),
              purchaseOrdersId: details?.purchaseOrderId,
              purchaseOrderId: parseInt(details?.id),
              sourceId: null,
              vendorId: details?.vendorId,
            });
          const response = await dispatch(action);
          getAllPOByCharge()
          if (response.error) dispatch(errorMessage(response.error.message));

          else {
            dispatch(successMessage(message));
            setpoChargesModalVisible(false);
            await dispatch(getPOCardsById(id));
          }
          break;
        }
        case 1: {
          const plans = [];
          let sum = 0;
          for (let i = 0; i < parseInt(data?.period); i++) {
            const date = data["dateInterval" + i];
            let amount = data["amountPerInterval" + i];
            if (amount != "" && amount != 0) {
              amount = parseFloat(amount);
              sum += amount;
              plans.push({
                amount,
                paymentDate: date,
                purchaseOrdersId: parseInt(details?.id),
                purchaseOrderId: details?.purchaseOrderId,
                id: data['id' + i],
                matchingTransaction: 0,
              });
            }
          }
          if (plans?.length > 0) {
            const action =
              selectedRecord.length > 0
                ? updatePOPayment({ plans })
                : createPOPayment({ plans });
            const response = await dispatch(action);
            if (response.error) dispatch(errorMessage(response.error.message));
            else {
              const action = updatePO({
                ...details,
                listSkyboxRefs: [...skyBoxRefDataList],
                totalScheduled: parseFloat(sum),
              });
              dispatch(action);
              dispatch(successMessage(message));
            }
            setPaymentPlanModalVisible(false);
            await dispatch(getAllPOByID(id));
            await dispatch(getPOCardsById(id));
            await dispatch(getAllPOPayment({
              id,
              pageNumber: 1,
              pageSize,
              sortDir,
              sortField,
            }))
          } else {

            dispatch(errorMessage("Add a valid entry in the amount fields"));
          }
          break;
        }
      }
    dispatch(flushPurchaseOrders());
    dispatch(flushPurchaseOrdersCards());
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false)
    }
  };

  const getOrderRefs = async () => {
    try {
      setBusy(true);
      await dispatch(getPurchaseOrderRefs(id));
    } catch (error) {
      console.error(error);
    } finally {
      setBusy(false);
    }
  };

  //download csv file
  const ExportCSV = async (option) => {
    try {
      setBusy(true);
      let response;
      switch (option) {
        case 0:
          response = await dispatch(exportCSVPurchaseCharges(id));
          break;
        case 1:
          response = await dispatch(exportCSVPayment(id));
          break;
      }
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // Run Rules
  const callRunRules = async () => {
    try {
      setExportLoading("runRules");
      await dispatch(runRules());
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setExportLoading(false);
    }
  };

  const resetHandler = async () => {
    setBusy(true);
    dispatch(flushPurchaseOrders());
    dispatch(flushPurchaseOrdersCards());
    dispatch(getAllPOByID(id));
    setData(selectedPayment);
    dispatch(getPOCardsById(id));
    setBusy(false);
  };

  const onExportPdf = async (option) => {
    try {
      setBusy(true);
      let response;
      switch (option) {
        case 0:
          response = await dispatch(exportPdfPurchaseCharges(id));
          break;
        case 1:
          response = await dispatch(exportPdfPayment(id));
          break;
      }
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setBusy(false);
    }
  };

  // DELETE
  const onDelete = async (mainId, option) => {
    try {
      setMinorLoading("delete")
      let response;
      switch (option) {
        case 0:
          response = await dispatch(deletePOCharges({ mainId, id }));
          break;
      }
      if (response.error) dispatch(errorMessage(response.error.message));
      else {
        setDeleteConfirmationVisibility(false);
        dispatch(successMessage(`Purchase Order ${tabs[selectedTabIndex]} Deleted Successfully`))
      }
      dispatch(getPOCardsById(id));
      dispatch(flushPurchaseOrders());
      dispatch(flushPurchaseOrdersCards());
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false);
    }
  };

  // EDIT
  const onEditAction = (data) => {
    setMainId(data?.id);
    setSelectedRecord(data);
    if (selectedTabIndex === 0 && id) {
      setpoChargesModalVisible(true);
    } else if (selectedTabIndex === 1 && id) {
      setPaymentPlanModalVisible(true);
    }
  };

  const onDeleteAction = (data) => {
    setMainId(data.id);
    setDeleteConfirmationVisibility(true);
  };

  // USEEFFECTS
  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.SINGLE_PO,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.SINGLE_PO,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    dispatch(getAllPOByID(id));
    setData(selectedPayment);
    dispatch(getPOCardsById(id));
  }, [id]);

  useEffect(() => {
    dispatch(getAccountsList());
  }, []);

  const { vendorsListForDropdown } = useSelector(
    (state) => state.cachedVendors
  );
  useEffect(() => {
    if (!vendorsListForDropdown.length) {
      dispatch(fetchVendorsListForDropdown());
    }
  }, [vendorsListForDropdown]);

  const getAllPOByCharge = async () => {
    setBusy(true);
    const data = {
      id,
      pageNumber,
      pageSize,
      sortDir,
      sortField,
    };
    if (selectedTabIndex === 0 && id) {
      setColumn(
        charges(
          onEditAction,
          onDeleteAction,
          setSelectedRecord,
          setmatchedVisibility,
          matchedVisibility
        )
      );
      await dispatch(getAllPOCharges(data));
    } else if (selectedTabIndex === 1 && id) {
      setColumn(paymentPlan(onEditAction, onDeleteAction));
      await dispatch(getAllPOPayment(data));
    }
    setBusy(false);
  }
  useEffect(async () => {
    getAllPOByCharge();
  }, [selectedTabIndex, pageSize, pageNumber, sortField, sortDir]);

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setData(chargesList);
      setpaging(chargesPaging);
    } else if (selectedTabIndex === 1) {
      let tot = cards?.scheduled;
      let balance = 0;
      const arr = [];
      for (let i = 0; i < paymentList?.length; i++) {
        const amount = paymentList[i].amount;
        balance = parseFloat(tot) - parseFloat(amount);
        tot = balance.toFixed(2);
        arr.push({ ...paymentList[i], balance });
      }
      setData(arr);
      setpaging(paymentPaging);
    }
  }, [chargesList, paymentList, selectedTabIndex, details, cards]);

  const downloadTemplateTrans = async () => {
    setBusy(true);
    await dispatch(downloadTemplatePOPP());
    setBusy(false);
  };

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                setpageNumber(1);
                getAllPOByID({ page: 1, pageSize: e.target.value });
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          {selectedTabIndex === 0 && (
            <Button
              variant="primary"
              style={{ textWrap: "nowrap" }}
              className="mx-2 ss-light-button"
              disabled={exportLoading}
              onClick={async (e) => {
                e.currentTarget.blur();
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  buttonName: mixpanel_button_name.RUNRULES,
                  page: mixpanel_contants.TRANSACTIONS,
                });
                await callRunRules();
                resetHandler();
              }}
            >
              {exportLoading ? <Loader /> : <img src={Run} alt=" " />}
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Run Rules
                </div>
              </span>
            </Button>
          )}
          {selectedTabIndex == 1 && (
            <div className="d-flex align-items-center justify-content-end">
              <Button
                onClick={downloadTemplateTrans}
                variant="primary"
                className="mx-2 ss-light-button button"
              >
                <img className="filter-reset-svg" src={DownloadImg} alt=" " />
                <span>Download Template</span>
              </Button>
              <div>
                <Button
                  onClick={() => fileRef.current.click()}
                  variant="primary"
                  className="mx-2 ss-light-button"
                >
                  <img src={Upload} alt=" " />
                  <span>Upload</span>
                </Button>
                <input
                  ref={fileRef}
                  onChange={handleChange}
                  multiple={false}
                  type="file"
                  hidden
                />
              </div>
            </div>
          )}
          <Dropdown>
            <Dropdown.Toggle className="ss-light-button button" id="dropdown-basic">
              <img className="filter-reset-svg" src={Export} alt=" " />
              <span>Export</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => ExportCSV(selectedTabIndex)}>
                Export as CSV
              </Dropdown.Item>

              <hr className="hr-full" />
              <Dropdown.Item onClick={() => onExportPdf(selectedTabIndex)}>
                Export as PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {selectedTabIndex === 0 && (
            <Button
              onClick={async () => {
                await setSelectedRecord(undefined);
                await setMainId(undefined);
                {
                  selectedTabIndex === 0
                    ? setpoChargesModalVisible(true)
                    : setPaymentPlanModalVisible(true);
                }
              }}
              variant="primary"
              className="button ss-light-button"
            >
              <img className="filter-reset-svg" src={Add} alt=" " />
              <span>Add</span>
            </Button>
          )}
          {selectedTabIndex === 1 && (
            <Button
              onClick={async () => {
                await setSelectedRecord(data);
                await setMainId(data.purchaseOrdersId);
                setPaymentPlanModalVisible(true);
              }}
              variant="primary"
              className="ms-2 ss-light-button button"
            >
              <img className="filter-reset-svg" src={Add} alt=" " />
              <span>
                {data.length > 0 ? "Edit Payment Plan" : "Add Payment Plan"}
              </span>
            </Button>
          )}
        </div>
      </>
    );
  };
  return (
    <div className="container-fluid pt-3">
      {/* Upper Navigation */}

      <Container className="page-upper-navigation d-flex justify-content-between">
        <ModalBreadcrumb
          breadcrumbLinks={[
            {
              name: "Purchase Orders",
              active: true,
            },
            {
              name: details?.purchaseOrderId ? details?.purchaseOrderId : "",
              active: false,
            },
          ]}
        />
        <Col md={{ span: 3, offset: 5 }}>
          <NavLink className="ss-back-button" to="/purchases">
            <small>Back to Purchase Orders</small>
          </NavLink>
        </Col>
      </Container>

      {/* Account Details */}
      <PODetails details={details} cards={cards} />

      {/* Data Table */}
      <QuickTixTable
        headerName="PURCHASE ORDERS"
        paging={paging}
        tabs={tabs}
        selectedTabIndex={selectedTabIndex}
        onTabSelectionChange={(i) => setTabIndex(i)}
        columns={column}
        data={data || []}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        setSortField={setSortField}
        sortDir={sortDir}
        sortField={sortField}
        setSortDir={setSortDir}
        renderRow={(rowData) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : column}
            rowData={rowData}
          />
        )}
      />
      {/* Modal Popups */}
      {poChargesModalVisible && (
        <AddEditPOCharges
          isVisible={poChargesModalVisible}
          minorLoading={minorLoading}
          setMinorLoading={setMinorLoading}
          handleClose={() => setpoChargesModalVisible(false)}
          onSubmit={(data) => onEntrySubmitted(data, 0)}
          selectedRecord={selectedRecord}
          details={details}
        />
      )}
      {paymentPlanModalVisible && (
        <PaymentPlanModal
          preFilledData={details}
          isVisible={paymentPlanModalVisible}
          minorLoading={minorLoading}
          setMinorLoading={setMinorLoading}
          handleClose={() => setPaymentPlanModalVisible(false)}
          onSubmit={(data) => onEntrySubmitted(data, 1)}
          selectedRecord={selectedRecord}
          purchaseOrderId={id}
        />
      )}

      {matchedVisibility && (
        <SelectMatch
          isVisible={matchedVisibility}
          setIsVisible={setmatchedVisibility}
          handleClose={() => setmatchedVisibility(false)}
          match={true}
          selectedRecord={selectedRecord}
        />
      )}

      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        setMinorLoading={setMinorLoading}
        minorLoading={minorLoading}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDelete(myMainId, selectedTabIndex)}
      />
    </div>
  );
};

export default withLoader(SinglePurchaseOrderDetail);
