import React, { useCallback, useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { FaFileCircleQuestion } from "react-icons/fa6";
import styles from "../styles/uploadinvoice.module.css";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "../../../images/delete.svg";
import { cloneDeep } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
// import Loader from "../../shared/components/loader/Loader";
import Loader from "../../../shared/components/loader/Loader";
import {
  removeUploadedInvoice,
  setAdditionalDetails,
  setGlobalFeeInputs,
  setGlobalInvoiceType,
  setGlobalUploadingInvoiceLoader,
  setInvoiceDetailsManually,
  setInvoiceFile,
  setPurchasePaymentDetails,
  setUploadedInvoiceFileName,
  uploadInvoicePdf,
} from "../../../data/reducers/purchaseOrders/po.reducer";
import {
  setSelectedAccountDetails,
  setSelectedTicketDetails,
} from "../../../data/reducers/accounts/accounts.reducers";
import { formatSlashDate } from "../../../utils/formatter/dateFormatter";
import {
  formatDollarMoney,
  isDigitsOnly,
} from "../../../utils/formatter/currencyFormatter";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { withLoader } from "../../../utils/hoc/withLoader";
import { createAddress } from "../../../data/reducers/addressBook/addressBook.reducer";

const UploadInvoice = ({
  setSteps,
  isType,
  handleUpdateInvoicePO,
  setBusy,
}) => {
  const dispatch = useDispatch();
  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(
    purchaseOrderData?.UploadInvoiceFileName
  );

  const { selectedAccountDetails } = useSelector((state) => state.accounts);

  const { UploadInvoice } = useSelector((state) => state.purchaseOrder);
  const [isUploadingError, SetIsUploadingError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(
    purchaseOrderData?.UploadInvoiceFileName ? true : false
  );

  const { ticketDetails } = useSelector((state) => state.accounts);

  useEffect(() => {
    setIsUploaded(purchaseOrderData?.UploadInvoiceFileName ? true : false);
    setSelectedFile(purchaseOrderData?.UploadInvoiceFileName);
  }, [purchaseOrderData?.UploadInvoiceFileName]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0].name); // Set the selected file name
        getFiledetails(acceptedFiles);
        dispatch(setUploadedInvoiceFileName(acceptedFiles[0].name));
        dispatch(setInvoiceFile(acceptedFiles[0]));
        dispatch(setSelectedAccountDetails({}));
      }
    },
    [dispatch]
  );

  // Function to find the zip code from the parts
  const findZipCode = (parts) => {
    for (const part of parts) {
      const match = part.match(/\b\d{5}\b/); // Use a regex to find a 5-digit zip code
      if (match) {
        return match[0]; // Return the first match, which is the zip code
      }
    }
    return null; // Return null if no zip code is found
  };

  // Function to find the index of the part that contains a 5-digit zip code
  const findZipCodeIndex = (parts) => {
    return parts.findIndex((part) => {
      // Use a regular expression to check for a 5-digit zip code
      return /\b\d{5}\b/.test(part.trim());
    });
  };

  const createAddressForUploadInvoice = async (
    address,
    email,
    mobileNumber
  ) => {
    // first splitting the address by ","
    let splittedAddress = address?.split(",");
    for (let i = 0; i < splittedAddress?.length; i++) {
      splittedAddress[i] = splittedAddress[i]?.trim();
    }
    let address1 = "";
    let city = "";
    let zipCode = "";
    let state = "";

    //finding the index of that part which contains the zip code, we want to find it because the information before zip Code part is all address information.
    const zipCodeIdx = findZipCodeIndex(splittedAddress);

    if (splittedAddress?.length > 0) {
      address1 = splittedAddress[0];
    }
    if (!isNaN(zipCodeIdx)) {
      const stateOrCity =
        splittedAddress[zipCodeIdx]?.split(" ")?.length > 0
          ? splittedAddress[zipCodeIdx]?.split(" ")[0]
          : "";
      state =
        stateOrCity?.length > 2
          ? ""
          : !isDigitsOnly(stateOrCity)
            ? stateOrCity
            : ""; // it is going to be state if the string is of length two  only because the state abbreviation in USA is of length 2
      city =
        stateOrCity?.length > 2 && !isDigitsOnly(stateOrCity)
          ? stateOrCity
          : ""; // it is going to be city if the string is of length more than two.
    }

    if (!isNaN(zipCodeIdx) && zipCodeIdx == 2) {
      city = splittedAddress[1];
    }

    if (!isNaN(zipCodeIdx)) {
      zipCode = findZipCode(splittedAddress);
    }

    setBusy(true);
    //creating the address in the database.
    const createAddressResponse = await dispatch(
      createAddress({
        address1,
        address2: "",
        city,
        email,
        mobileNumber,
        postalCode: zipCode,
        state: state,
      })
    );

    setBusy(false);
    if (
      createAddressResponse?.error &&
      createAddressResponse?.error?.message?.includes("Address already exists")
    ) {
      return Number(
        createAddressResponse?.error?.message?.split(
          "Address already exists "
        )[1]
      );
    } else if (!createAddressResponse?.error) {
      return createAddressResponse?.payload?.id;
    }

    return 0;
  };

  function extractDigits(inputString) {
    // Use a regular expression to match all digits in the string
    const digits = inputString.match(/\d+/g);
    // Join the array of digits (if any) into a single string, or return an empty string if none are found
    return digits ? digits.join("") : "";
  }

  async function getUploadDetails() {
    // fee details is not gonna come necessarily
    if (
      UploadInvoice &&
      UploadInvoice?.data &&
      UploadInvoice.data?.personalDetails &&
      UploadInvoice?.data?.paymentPlan &&
      UploadInvoice.data.matchPlan
    ) {
      const feeDetails = UploadInvoice.data.feeDetails;
      const addressAsString = UploadInvoice.data?.personalDetails[2];
      const mobileNo = UploadInvoice.data?.personalDetails[3];
      const email = UploadInvoice.data?.personalDetails[1];
      const addressId = await createAddressForUploadInvoice(
        UploadInvoice.data?.personalDetails[2],
        email,
        mobileNo
      );

      if (selectedAccountDetails?.vendorId || selectedAccountDetails?.email) {
        dispatch(
          setSelectedAccountDetails({
            ...selectedAccountDetails,
          })
        );
      } else {
        dispatch(
          setSelectedAccountDetails({
            name: UploadInvoice.data?.personalDetails[0],
            accountId: UploadInvoice.data?.personalDetails[5]
              ? extractDigits(UploadInvoice.data?.personalDetails[5])
              : null,
            email: email,
            mobileNo: mobileNo?.replace(/\[/g, "(").replace(/\]/g, ")"),
            address: addressAsString,
            addressBookId: addressId,
          })
        );
      }
      // Transform the paymentPlan array to an array of objects
      const formattedPaymentPlan = UploadInvoice?.data?.paymentPlan.map(
        ([date, amount]) => ({
          date: formatSlashDate(date),
          amount: formatDollarMoney(amount),
        })
      );
      let integerPrice = UploadInvoice.data?.personalDetails[11];
      integerPrice = formatDollarMoney(integerPrice);
      let feeSum = feeDetails?.reduce((sum, [name, code]) => {
        // Remove any non-numeric characters from 'code' and parse the number
        const feeAmount = formatDollarMoney(code) || 0;

        // Add the fee amount to the sum
        return sum + feeAmount;
      }, 0);
      feeSum = feeSum ? formatDollarMoney(feeSum) : 0;

      let totalMatchPlanPrice = UploadInvoice?.data?.matchPlan?.reduce(
        (sum, ticket) => {
          const price = formatDollarMoney(ticket[4]) || 0; // Get the raw price or default to 0 if not available
          return sum + price; // Add the price to the sum
        },
        0
      );

      // Optionally, format the total price for display
      totalMatchPlanPrice = totalMatchPlanPrice
        ? formatDollarMoney(totalMatchPlanPrice)
        : 0;

      let totalMoney = UploadInvoice.data?.personalDetails[9];
      totalMoney = formatDollarMoney(totalMatchPlanPrice + feeSum);
      // Dispatch the action with the formatted data

      // if any data already exists in the paymentPlan global state then keep it same.
      if (
        purchaseOrderData?.PaymentPlan &&
        Object.keys(purchaseOrderData?.PaymentPlan).length > 0
      ) {
        dispatch(
          setPurchasePaymentDetails({
            calculated: purchaseOrderData?.PaymentPlan,
          })
        );
      } else {
        dispatch(
          setPurchasePaymentDetails({ calculated: formattedPaymentPlan })
        );
      }

      dispatch(
        setPurchasePaymentDetails({
          data: {
            ...purchaseOrderData?.purchasePaymentDetails,
            amount: integerPrice,
            totalScheduled: totalMoney,
          },
        })
      );

      //here we are adding a new entry in the masking global state.
      const oldMaskingList = cloneDeep(
        purchaseOrderData?.additionalDetails?.masking ?? []
      );
      const formattedTicketDetails = UploadInvoice.data.matchPlan
        .slice(1)
        .map((row) => {
          let [lowSeat, highSeat] = row[2].split("-"); // Split Seat into lowSeat and highSeat
          lowSeat = lowSeat?.trim();
          highSeat = highSeat?.trim();
          //default data for adding in the new masking entry which is initiated when the entry added inside the
          oldMaskingList.push({
            section: row[0],
            maskingRow: row[1],
            maximumRow: "",
            possibleMaskingRows: "",
            RowExclusion: "",
            maskinggenerated: false,
            generateMasking: {
              sunday: "",
              monday: "",
              tuesday: "",
              wednesday: "",
              thursday: "",
              friday: "",
              saturday: "",
            },
          });
          return {
            section: row[0],
            row: row[1],
            lowSeat: lowSeat,
            highSeat: highSeat ? highSeat : lowSeat,
            qty: row[3],
            price: formatDollarMoney(row[4]),
          };
        });
      if (
        ticketDetails &&
        Object.keys(ticketDetails).length > 0 &&
        ticketDetails?.TableData?.length > 0
      ) {
        dispatch(setSelectedTicketDetails({ ...ticketDetails }));
      } else {
        dispatch(
          setAdditionalDetails({
            ...purchaseOrderData?.additionalDetails,
            masking: oldMaskingList,
          })
        );
        dispatch(
          setSelectedTicketDetails({ TableData: formattedTicketDetails })
        );
      }
      if (feeDetails.length > 0) {
        dispatch(
          setGlobalFeeInputs(
            feeDetails.map(([name, code]) => ({
              name: name.trim(), // Use arr[0] for name and trim any extra spaces
              code: code.trim(), // Use arr[1] for code and trim as well
            }))
          )
        );
      }
    }
  }

  useEffect(() => {
    if (isUploaded) {
      getUploadDetails();
    }
  }, [UploadInvoice]);

  async function getFiledetails(acceptedFiles) {
    SetIsUploadingError(false);
    try {
      setLoading(true); // Start loading state
      dispatch(setGlobalUploadingInvoiceLoader(true));
      const response = await dispatch(
        uploadInvoicePdf(acceptedFiles[0])
      ).unwrap();
      if (response?.isSuccessful) {
        setIsUploaded(true);
        // getUploadDetails();
        dispatch(
          successMessage(
            `${acceptedFiles[0].name} file has been uploaded successfully and has auto-filled certain input fields.`
          )
        );
      } else {
        dispatch(errorMessage("File failed to upload"));
        setIsUploaded(false);
        dispatch(setGlobalUploadingInvoiceLoader(false));
        SetIsUploadingError(true);
        dispatch(setUploadedInvoiceFileName(null));
        setSelectedFile(null);
      }
      setLoading(false);
      dispatch(setGlobalUploadingInvoiceLoader(false));
    } catch (error) {
      setIsUploaded(false);
      SetIsUploadingError(true);
      setLoading(false);
      dispatch(errorMessage("File failed to upload"));
      dispatch(setUploadedInvoiceFileName(null));
      setSelectedFile(null);
    }
  }

  function removeUploadedFile() {
    setIsUploaded(false);
    setSelectedFile(null); // Reset the selected file name
    dispatch(setUploadedInvoiceFileName(null));
    dispatch(removeUploadedInvoice());
    SetIsUploadingError(false);
    dispatch(
      successMessage(`${selectedFile} file has been deleted successfully.`)
    );
    dispatch(setSelectedAccountDetails({}));
    dispatch(setSelectedTicketDetails({}));
    dispatch(setPurchasePaymentDetails({ calculated: {} }));
    dispatch(setPurchasePaymentDetails({ data: {} }));
    dispatch(setAdditionalDetails({}));
    dispatch(setGlobalFeeInputs([]));
    dispatch(setInvoiceDetailsManually(undefined));
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div>
      <div className="d-flex gap-2  flex-column align-items-center">
        {/* scan */}
        <div className="d-flex flex-column align-items-center mt-4">
          <span
            style={{ fontSize: "14px", fontWeight: "400", color: "#949494" }}
          >
            Scan Invoice
          </span>
          <div className="d-flex flex-column">
            <div
              className={styles.upload_container}
              style={{
                border: isUploadingError ? "1px solid red" : null,
                alignItems: "center",
              }}
              {...(isUploaded ? {} : getRootProps())}
            >
              <input {...getInputProps()} />
              {!loading && !isUploaded ? (
                <>
                  <div>
                    <FiUpload className={styles.upload_link} size={24} />
                  </div>
                  <br />
                </>
              ) : null}
              {loading ? (
                <>
                  <Loader />
                  Uploading...
                </>
              ) : isUploaded ? (
                <div className="w-100 d-flex align-items-center h-100">
                  {isUploadingError ? (
                    <div className="me-2">
                      <FaFileCircleQuestion color="gray" size={18} />
                    </div>
                  ) : null}
                  {selectedFile ? (
                    <div className="me-auto">{selectedFile}</div>
                  ) : (
                    <div className="mx-3 text-primary">
                      <span className="">Retry</span>
                    </div>
                  )}
                  <div>
                    <img
                      src={DeleteIcon}
                      style={{
                        cursor: "pointer",
                        width: "16px",
                        height: "16px",
                        objectFit: "contain",
                      }}
                      className="me-2"
                      onClick={removeUploadedFile}
                      alt="deleteicon"
                    />
                  </div>
                </div>
              ) : (
                <div style={{ color: "#707070" }}>
                  Drop PDF File Here or{" "}
                  <u className={styles.upload_link} >Click to Upload</u>
                </div>
              )}
            </div>
            {isUploadingError && (
              <span
                style={{ marginLeft: "50px", color: "red" }}
                className="my-1 small"
              >
                Upload failed
              </span>
            )}
          </div>
        </div>

        <span>OR</span>

        {/* manual button */}
        <button
          className="btn btn-primary"
          disabled={isUploaded == true ? true : loading}
          onClick={async (e) => {
            e.preventDefault();
            if (!isType) {
              return dispatch(errorMessage("Please Select the Invoice Type"));
            }
            if (!isNaN(Number(id))) {
              setBusy(true);
              await handleUpdateInvoicePO();
              setBusy(false);
            } else {
              setSteps((prev) => prev + 1);
            }
          }}
        >
          Enter Details Manually
        </button>
      </div>
    </div>
  );
};

export default withLoader(UploadInvoice);
