import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import EyeIcon from "../../../images/ShowEye.svg";
import { useDispatch, useSelector } from "react-redux";
import { createUploadEmail } from "../../../data/reducers/email/upload.email.reducer";
import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
import { getAddressList } from "../../../data/reducers/addressBook/addressBook.reducer";
import Select from "react-select";
import { getCardTypeDetails } from "../../../data/reducers/cards/cards.routes";
import Loader from "../../../shared/components/loader/Loader";
const AddEmailModal = ({
  isVisible,
  handleThisClose,
  setMinorLoading,
  onCompleted,
  minorLoading,
}) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch,
    control,
  } = useForm();

  const [dropVal, setdropVal] = useState({});

  const [isShown, setIsShown] = useState(false);
  const togglePassword = () => {
    setIsShown(!isShown);
  };

  const addressList = useSelector((state) => state.addressBook).addressList;
  const { cardTypeLookup } = useSelector((state) => state.cards);

  const watchCard = watch("isCreateCardChecked");
  const watchSelectedAddress = watch("selectedAddress");
  const watchCreateTicketMasterChecked = watch(
    "isCreateTicketMasterAccountChecked"
  );
  const clear = () => {
    reset({
      username: "",
      password: "",
      firstName: "",
      lastName: "",
      recoveryEmail: "",
    });
  };

  const customStyles = {
    option: (provided, { isFocused }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  useEffect(() => {
    setTimeout(clear, 1000);
  }, [isVisible]);

  useEffect(() => {
    dispatch(getAddressList());
    dispatch(getCardTypeDetails());
  }, []);

  const onSubmit = async (data) => {
    try {
      if (
        data.isCreateTicketMasterAccountChecked == true &&
        ((data.zipCode && data.zipCode.length < 5) || !data.zipCode)
      ) {
        dispatch(errorMessage("Please provide valid zip code"));
        return;
      }
      setMinorLoading("add");
      const response = await dispatch(createUploadEmail(data));
      if (response?.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage("Added Successfully"));
      }
      setMinorLoading(false);
      handleThisClose();
      onCompleted();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        dialogClassName="modal-xl"
        show={isVisible}
        onHide={handleThisClose}
        className="mt-5"
      >
        <Form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ fontWeight: "500" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Emails</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/upload/email",
                name: "Upload Email",
                active: false,
              },
              {
                name: "New Email",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <br />
            <Container>
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      First Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        autoComplete="nope"
                        isInvalid={!!errors.firstName}
                        {...register("firstName", {
                          required: watchCreateTicketMasterChecked || watchCard,
                        })}
                        placeholder="Enter first name"
                        maxLength={30}
                      />
                      <Form.Control.Feedback type="invalid">
                        First name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Last Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        isInvalid={!!errors.lastName}
                        {...register("lastName", {
                          required: watchCreateTicketMasterChecked || watchCard,
                        })}
                        placeholder="Enter last name"
                        maxLength={30}
                      />
                      <Form.Control.Feedback type="invalid">
                        Last name is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Username
                    </Form.Label>
                    <Col sm="8">
                      <InputGroup>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          maxLength={30}
                          minLength={7}
                          placeholder="Enter username"
                          isInvalid={!!errors.username}
                          {...register("username", { required: true })}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Username is required.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Recovery Mail
                    </Form.Label>
                    <Col sm="8">
                      <InputGroup>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          maxLength={60}
                          minLength={7}
                          placeholder="Enter Recovery Mail"
                          isInvalid={!!errors.recoveryEmail}
                          {...register("recoveryEmail", { required: true })}
                          required
                        />

                        <Form.Control.Feedback type="invalid">
                          Recovery Mail is required.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Password
                    </Form.Label>
                    <Col sm="8">
                      <div style={{ display: "flex", position: "relative" }}>
                        <Form.Control
                          autoComplete="off"
                          placeholder="Enter password"
                          minLength={8}
                          type={isShown ? "text" : "password"}
                          name="password"
                          isInvalid={!!errors.password}
                          {...register("password", { required: true })}
                        />

                        <img
                          style={{
                            position: "absolute",
                            right: "8%",
                            top: "38%",
                            cursor: "pointer",
                          }}
                          src={EyeIcon}
                          alt=" "
                          onClick={togglePassword}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Password is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Address
                    </Form.Label>
                    <Col sm="8">
                      <Controller
                        name="addressId"
                        control={control}
                        rules={{
                          required: watchCreateTicketMasterChecked || watchCard,
                          message: "error message",
                        }}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                required
                                isClearable
                                value={watchSelectedAddress}
                                onChange={(e) => {
                                  field.onChange(e?.id);

                                  setValue("zipCode", e?.postalCode, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  });
                                }}
                                styles={customStyles}
                                options={addressList}
                                getOptionLabel={(option) => {
                                  if (
                                    option.address1 != undefined ||
                                    option.address2 != undefined ||
                                    option.city != undefined ||
                                    option.state != undefined ||
                                    option.postalCode != undefined
                                  )
                                    return `${option.address1} ${option.address2} ${option.city} ${option.state} ${option.postalCode}`;
                                }}
                              />
                              {invalid && error ? (
                                <div className="custom-invalid-feedback">
                                  Address is required
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Address is required for card creation.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Zip/Postal Code
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        isInvalid={!!errors.zipCode}
                        {...register("zipCode", {
                          required:
                            watchCreateTicketMasterChecked || watchCard
                              ? true
                              : false,
                        })}
                        placeholder="Enter Zip Code"
                      />
                      <Form.Control.Feedback type="invalid">
                        Zip Code is required
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Check
                      className="remember-forgotpass"
                      type="checkbox"
                      label="Create Ticketmaster Account"
                      {...register("isCreateTicketMasterAccountChecked")}
                    />
                  </Form.Group>
                </Col>
                <br />
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Check
                      className="remember-forgotpass"
                      type="checkbox"
                      label="Create Card"
                      {...register("isCreateCardChecked")}
                    />
                  </Form.Group>
                </Col>
                {watchCard && (
                  <Col sm={6}>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        Card Type
                      </Form.Label>
                      <Col sm="8">
                        <Controller
                          name="cardTypeId"
                          control={control}
                          rules={{ required: true, message: "error message" }}
                          render={({
                            field,
                            fieldState: { invalid, error },
                          }) => {
                            return (
                              <div>
                                <Select
                                  required
                                  isClearable
                                  value={dropVal?.cardType}
                                  onChange={(e) => {
                                    field.onChange(e?.id);
                                    setdropVal({ ...dropVal, cardType: e });
                                  }}
                                  styles={customStyles}
                                  options={cardTypeLookup}
                                  getOptionLabel={({ cardType }) => cardType}
                                />
                                {invalid && error ? (
                                  <div className="custom-invalid-feedback">
                                    Card Type is required.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            );
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          Card type is required.
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </Container>
          </Modal.Body>

          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              disabled={minorLoading === "add"}
              className="ss-modal-primary-btn mx-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}

            >
              Add{minorLoading === "add" && <Loader />}
            </Button>
            <Button
              variant="light"
              onClick={clear}
              className="ss-modal-secondary-btn mx-2"
            >
              Clear
            </Button>
          </div>
          <br />
        </Form>
      </Modal>
    </>
  );
};

export default AddEmailModal;
