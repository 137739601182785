import ModalBreadcrumb from "../../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm, } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountSportList } from "../../../../data/reducers/accounts/accounts.reducers";
import Select from "react-select";
import Loader from "../../../../shared/components/loader/Loader";

const AddEditSportsModal = ({
    isVisible,
    handleClose,
    onSubmit,
    selectedRecord,
    minorLoading
}) => {
    const dispatch = useDispatch();
    const [dropVal, setdropVal] = useState({
        sport: ""
    });
    const [isEdit, setEdit] = useState(false);
    // setup react hook form

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        control
    } = useForm();
    const sportDetails = useSelector((state) => state.accounts).sportList;

    const handleReset = () => {
        for (const [key, value] of Object.entries(selectedRecord)) {
            setValue(key, value, {
                shouldValidate: true,
                shouldDirty: true,
            });
        }
    };

    // reset form on visibility toggle
    useEffect(() => {
        reset();
        dispatch(getAccountSportList());
    }, [isVisible]);
    // update values in form if selected record is changed
    useEffect(() => {
        if (selectedRecord) {
            // as soon as selected record is changed, set values in modal popup
            setEdit(true);
            for (const [key, value] of Object.entries(selectedRecord)) {
                setValue(key, value, {
                    shouldValidate: true,
                    shouldDirty: true,
                });
            }
            setValue('sportId', selectedRecord?.sport)
        } else {
            // if selected record is undefined, reset
            setEdit(false);
            reset();
        }
    }, [selectedRecord, isVisible]);

    return (
        <>
            <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Sports</Modal.Title>
                    </Modal.Header>
                    <ModalBreadcrumb
                        breadcrumbLinks={[
                            {
                                to: "/settings/sports-and-teams",
                                name: "Sports & Teams",
                                active: false,
                            },
                            {
                                name: isEdit ? "Edit Sport" : "New Sport",
                                active: true,
                            },
                        ]}
                    />
                    <Modal.Body>
                        <div className="container-fluid p-2 w-100 h-100">
                            <Row>
                                <p className="title-accounts">Details</p>
                                <Col sm={5}>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="3">
                                            Sport
                                        </Form.Label>
                                        <Col>
                                            <Form.Control
                                                isInvalid={!!errors.sportName}
                                                {...register("sportName", { required: true })}
                                                placeholder="Sport"
                                            />
                                            <Form.Control.Feedback type="invalid">Sport Name is required.</Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </div>
                    </Modal.Body>
                    <div className="d-flex justify-content-center my-4">
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={minorLoading === "add"}
                            className="ss-modal-primary-btn mx-2"
                        >
                            {isEdit ? "Save" : "Add"}{minorLoading === "add" && (
                                <Loader style={{ marginLeft: "8px", display: "inline-block" }} />
                            )}
                        </Button>
                        <Button
                            variant="light"
                            onClick={() => isEdit ? handleReset() : reset()}
                            className="ss-modal-secondary-btn mx-2"
                        >
                            {isEdit ? "Reset" : "Clear"}
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default AddEditSportsModal;
