import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getInvoiceDetails,
  getInvoiceFeeDropdown,
  removeUploadedInvoice,
  setGlobalFeeInputs,
} from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import UploadDetailsPreview from "./1-upload-details/UploadDetailsPreview";
import AccountDetailsPreview from "./2-account-details/AccountDetailsPreview";
import TicketDetailsPreview from "./3-ticket-details/TicketDetailsPreview";
import PurchaseDetailsPreview from "./4-purchase-details/PurchaseDetailsPreview";
import AdditionalDetailsPreview from "./5-additional-details/AdditionalDetailsPreview";
import AddNote from "./AddNote";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const custom_styles = {
  font_label: {
    fontSize: "14px",
    color: "#404040",
    minWidth: "120px",
  },
  details: {
    fontWeight: "600",
    fontSize: "14px",
  },
  titles: {
    fontSize: "20px",
    fontWeight: "500",
  },
  font_label2: {
    fontSize: "14px",
    color: "#a7a7a7",
  },
  border1: {
    backgroundColor: "#c1c1c1",
    height: "1px",
    opacity: "0.8",
  },
  border2: {
    backgroundColor: "#dfdfdf",
    height: "1px",
    opacity: "0.5",
  },
};

const InvoicePreview = ({ setSteps, typeName }) => {
  const data = useSelector((state) => state.accounts);
  const { id } = useParams();
  const dispatch = useDispatch();
  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const { globalInvoiceType, globalFeeDropdown } = useSelector(
    (state) => state.purchaseOrder
  );

  useEffect(() => {
    //to fetch PO details when Id changes
    dispatch(getInvoiceDetails(id));
    dispatch(getInvoiceFeeDropdown(id));
  }, [id]);

  useEffect(() => {
    dispatch(
      setGlobalFeeInputs(
        globalFeeDropdown
          .filter((x) => x.fees != null)
          .map((item) => ({
            name: item.feeLabel,
            code: item.fees,
          }))
      )
    );
  }, [globalFeeDropdown]);
  return (
    <div className="d-flex gap-2 flex-column">
      {/* 1. Upload Details */}
      <UploadDetailsPreview
        custom_styles={custom_styles}
        setSteps={setSteps}
        removeUploadedInvoice={removeUploadedInvoice}
        purchaseOrderData={purchaseOrderData}
      />

      {/* 2. Account Details */}
      <div style={{ ...custom_styles.border1 }}></div>
      <AccountDetailsPreview
        custom_styles={custom_styles}
        setSteps={setSteps}
        data={data}
      />

      {/* 3. Ticket Details */}
      <div style={{ ...custom_styles.border1 }}></div>
      <TicketDetailsPreview
        data={data}
        custom_styles={custom_styles}
        setSteps={setSteps}
      />

      {/* 4. Purchase Details */}

      <div style={{ ...custom_styles.border1 }}></div>
      <PurchaseDetailsPreview
        custom_styles={custom_styles}
        setSteps={setSteps}
        purchaseOrderData={purchaseOrderData}
      />

      {/* 5. Additional Details */}
      {globalInvoiceType != 3 && (
        <>
          <div style={{ ...custom_styles.border1 }}></div>
          <AdditionalDetailsPreview
            custom_styles={custom_styles}
            setSteps={setSteps}
            data={data}
            purchaseOrderData={purchaseOrderData}
            typeName={typeName}
          />
        </>
      )}
      {/* <AddNote/> */}
    </div>
  );
};

export default InvoicePreview;
