import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import React from "react";
import Loader from "../../../shared/components/loader/Loader";

const AddVendorModal = ({ isVisible, handleClose, handleAddVendor, minorLoading }) => {
  const categories = useSelector((state) => state.vendors).category;
  const [type, setType] = useState("");
  useEffect(() => {
    reset();
  }, [isVisible]);

  useEffect(() => {
    setValue("categoryId", type?.id);
    setValue("type", type?.type);
  }, [type]);

  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
  } = useForm();

  const customStyles = {
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused ? "#329BEF" : "white",
      color: isFocused ? "white" : "black",
    }),
  };

  return (
    <>
      <Modal dialogClassName="modal-xl" show={isVisible} onHide={handleClose}>
        <Form onSubmit={handleSubmit(handleAddVendor)}>
          <Modal.Header closeButton>
            <Modal.Title>ADD VENDOR</Modal.Title>
          </Modal.Header>
          <ModalBreadcrumb
            breadcrumbLinks={[
              {
                to: "/vendors",
                name: "Vendors",
                active: false,
              },
              {
                name: "New Vendor",
                active: true,
              },
            ]}
          />
          <Modal.Body>
            <div className="container-fluid p-2 w-100 h-100">
              <Row>
                <p className="title-accounts">Basic Information</p>
                <Col md="6">
                  <Form.Group as={Row}>
                    <Form.Label column md="4 d-flex gap-1">
                      Vendor Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Col md="7">
                      <Form.Control
                        isInvalid={!!errors.name}
                        {...register("name", {
                          required: true,
                        })}
                        placeholder="Vendor Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Vendor Name is required
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row}>
                    <Form.Label column md="5">
                      Type
                    </Form.Label>
                    <Col md="7">
                      <Controller
                        name="categoryId"
                        control={control}
                        render={({ field, fieldState: { invalid, error } }) => {
                          return (
                            <div>
                              <Select
                                value={type}
                                onChange={(e) => {
                                  field.onChange(e.id);
                                  setType(e);
                                }}
                                styles={customStyles}
                                // isClearable
                                options={categories}
                                getOptionLabel={(option) => `${option.type}`}
                              />
                            </div>
                          );
                        }}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={8}>
                  <Form.Group as={Row}>
                    <Form.Label column md="3">
                      Ticketmaster URL
                    </Form.Label>
                    <Col
                      md="3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      am.ticketmaster.com/
                    </Col>
                    <Col md="5">
                      <Form.Control
                        {...register("accountUrl")}
                        placeholder="Vendor URL"
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}></Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group as={Row}>
                    <Form.Label column md="4">
                      URL
                    </Form.Label>

                    <Col md="7">
                      <Form.Control
                        {...register("otherUrl")}
                        placeholder="URL"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <div className="d-flex justify-content-center my-4">
            <Button
              type="submit"
              variant="primary"
              className="ss-modal-primary-btn mx-2"
              disabled={minorLoading === "add"}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px", // Adds space between the text and the loader
              }}
            >
              {"Add"}
              {minorLoading === "add" && <Loader />}

            </Button>


            <Button
              variant="light"
              onClick={() => {
                reset();
                setType("");
                setValue("categoryId", null);
              }}
              className="ss-modal-secondary-btn mx-2"
            >
              {"Clear"}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddVendorModal;
