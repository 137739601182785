import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import Add from "../../images/add_blue.svg";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";

import AddEditAddressModal from "./modals/AddEditAddress";
import { withLoader } from "../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Loader from "../../shared/components/loader/Loader";

//components
import FilterPanel from "../../shared/components/panels/filter-panel/FilterPanel";
import QuickTixTable from "../../shared/components/table/QuickTixTable";
import TableRowCard from "../../shared/components/table/TableRowCard";
import { columnError } from "../../shared/components/table/data/error";

// images
import Export from "../../images/export.svg";

import DeleteIcon from "../../images/delete.svg";
import EditIcon from "../../images/edit.svg";

import "../../shared/styles/ModalStyles.scss";

//reducers
import {
  getAllAddress,
  createAddress,
  updateAddress,
  deleteAddress,
  filterAddress,
  exportSelectedAsCSVAddress,
  exportSelectedAsPdfAddress,
  exportCSVAddressBook,
  exportPdfAddressBook,
  getCardsByAddressId,
  getAccountsByAddressId,
} from "../../data/reducers/addressBook/addressBook.reducer";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Mixpanel,
  mixpanel_button_name,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";

import Down from "../../images/downarrow.svg";
import Up from "../../images/uparrow.svg";
import CollapsibleTable from "./collapsible-table";

const AddressBook = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);

  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "email", value: filterUrlData?.email },
        { key: "mobileNumber", value: filterUrlData?.mobileNumber },
        { key: "address", value: filterUrlData?.address },
        { key: "city", value: filterUrlData?.city },
        { key: "postalCode", value: filterUrlData?.postalCode },
        { key: "state", value: filterUrlData?.state },
      ]);
    }
  }, [filterUrlData]);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.ADDRESSBOOK,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.ADDRESSBOOK,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);
  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        email: queryParameters.get("email"),
        mobileNumber: queryParameters.get("mobileNumber"),
        address: queryParameters.get("address"),
        city: queryParameters.get("city"),
        postalCode: queryParameters.get("postalCode"),
        state: queryParameters.get("state"),
      });
      // setDropdownState("");

    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/addressbook?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(
        `/addressbook?page=${Number(pageNumber) - 1}${searchQuery()}`
      );
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(`/addressbook?page=${Number(pageNumber) + 1}${searchQuery()}`);
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//

  const filterNavigation = (data) => {
    // var url = `/addressbook?page=${data.searchText ? 1 : page}`;
    var url = `/addressbook?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.email) url += `&&email=${data.email}`;
    if (data.mobileNumber) url += `&&mobileNumber=${data.mobileNumber}`;
    if (data.address) url += `&&address=${data.address}`;
    if (data.city) url += `&&city=${data.city}`;
    if (data.postalCode) url += `&&postalCode=${data.postalCode}`;
    if (data.state) url += `&&state=${data.state}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    const search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//
  const [dropdownState, setDropdownState] = useState("");
  //selectors
  const addressList = useSelector((state) => state.addressBook).address;
  const paging = useSelector((state) => state.addressBook).paging;

  //use states
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);
  const [addAccountModalVisible, setAddEditAccountModalVisibility] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [minorLoading, setMinorLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [dropVal, setdropVal] = useState({
    vendor: "",
    team: "",
    sport: "",
  });

  const [searchData, setsearchData] = useState(null);

  const fetchAddressData = async () => {
    try {
      setloading(true)
      const data = {
        pageNumber: page,
        pageSize: pageSize,
        sortDir,
        sortField,
      };
      const response = await dispatch(getAllAddress(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setloading(false)
    }
  };

  const onDeleteAccount = async (accountId) => {
    try {
      setMinorLoading("delete")
      const response = await dispatch(deleteAddress(accountId));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setDeleteConfirmationVisibility(false);
        dispatch(successMessage("Address was deleted successfully"));
        if (searchData) {
          onSubmit(searchData);
        } else {
          fetchAddressData();
        }
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      pageNumber: page,
      pageSize,
      sortField,
      sortDir,
    };
    setloading(true)

    if (
      data?.searchText ||
      data?.email ||
      data?.mobileNumber ||
      data?.address ||
      data?.city ||
      data?.postalCode ||
      data?.state
    ) {
      setsearchData(formData);
      await dispatch(filterAddress(formData));
    } else {
      setsearchData(null);
      await fetchAddressData();
    }

    setloading(false)
  };

  const onEntrySubmitted = async (data) => {
    try {
      const message = data.id
        ? `Address Updated Successfully`
        : `New Address Created Successfully`;
      setMinorLoading("add")
      const action = data?.id ? updateAddress(data) : createAddress(data);
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        dispatch(successMessage(message));
        setAddEditAccountModalVisibility(false);
        fetchAddressData();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(true)
    }
  };

  const addressCol = [
    {
      columnName: "Address",
      render: (data) => (
        <>
          {data.address1}
          <br />
          {data.address2}
        </>
      ),
    },
    {
      columnName: "City",

      render: (data) => <>{data.city}</>,

      flexGrow: 0.5,
    },

    {
      columnName: "State",
      render: (data) => <>{data.state}</>,

      flexGrow: 0.5,
    },
    {
      columnName: (
        <>
          Postal <br /> Code{" "}
        </>
      ),
      render: (data) => <>{data.postalCode}</>,

      flexGrow: 0.5,
    },

    {
      columnName: "Email",
      render: (data) => <>{data.email}</>,
    },
    {
      columnName: "Account",
      render: (data, ind, open, setOpen) => (
        <>
          <small className="table-row-card-actions d-flex justify-content-center">
            {data.accountCount}

            <img
              onClick={() => {
                setDropdownState("account");
                dispatch(getAccountsByAddressId(data.id));
                if (open[ind]) {
                  setOpen(Array(data?.length).fill(false));
                } else {
                  const temp = Array(data?.length).fill(false);
                  temp[ind] = !temp[ind];
                  setOpen(temp);
                }

                if (!open[ind]) {
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    page: mixpanel_contants.ACCOUNT,
                    buttonName: mixpanel_button_name.COLLAPSE,
                  });
                }
              }}
              src={dropdownState === "account" && open[ind] ? Up : Down}
              alt="up down image"
              className="mx-2"
              style={{ cursor: "pointer" }}
            />
          </small>
        </>
      ),
    },

    {
      columnName: "Card",
      render: (data, ind, open, setOpen) => (
        <>
          <small className="table-row-card-actions d-flex justify-content-center">
            {data.cardCount}

            <img
              onClick={() => {
                setDropdownState("card");
                dispatch(getCardsByAddressId(data.id));
                if (open[ind]) {
                  setOpen(Array(data?.length).fill(false));
                } else {
                  const temp = Array(data?.length).fill(false);
                  temp[ind] = !temp[ind];
                  setOpen(temp);
                }

                if (!open[ind]) {
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    page: mixpanel_contants.ACCOUNT,
                    buttonName: mixpanel_button_name.COLLAPSE,
                  });
                }
              }}
              src={open[ind] && dropdownState === "card" ? Up : Down}
              alt="up down image"
              className="mx-2"
              style={{ cursor: "pointer" }}
            />
          </small>
          {/* )} */}
        </>
      ),
    },
    {
      columnName: (
        <>
          Mobile <br /> Number{" "}
        </>
      ),
      render: (data) => <>{data.mobileNumber}</>,
      //   columnKey: "mobileNumber",
      flexGrow: 0.5,
    },
    {
      columnName: "Actions",
      flexGrow: 0.5,
      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setAddEditAccountModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt=" " />
          </Button>
          <Button
            variant="link"
            className="table-action-button"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord(data);
              setDeleteConfirmationVisibility(true);
            }}
          >
            <img src={DeleteIcon} alt=" " />
          </Button>
        </small>
      ),
    },
  ];

  const exportSelectedAsCSV = async () => {
    setMinorLoading(true)
    if (searchData) {
      await dispatch(exportSelectedAsCSVAddress(searchData));
    } else {
      await dispatch(exportCSVAddressBook());
    }
    setMinorLoading(false)
  };

  const onExportSelectedAsPdf = async () => {
    setMinorLoading(true);
    if (searchData) {
      await dispatch(exportSelectedAsPdfAddress(searchData));
    } else {
      await dispatch(exportPdfAddressBook());
    }
    setMinorLoading(false)
  };

  //table head
  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="text-end mt-3 d-flex">
          <Dropdown onClick={(e) => e.currentTarget.blur()}>
            <Dropdown.Toggle
              disabled={minorLoading}
              className="ms-2 ss-light-button button"
              id="dropdown-basic"
            >
              <img className="filter-reset-svg" src={Export} alt=" " />
              <span>
              <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Export
                    {minorLoading && (
                      <Loader   />
                    )}
                  </div>
                
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  exportSelectedAsCSV();
                }}
              >
                Export as CSV
              </Dropdown.Item>
              <hr className="hr-full" />
              <Dropdown.Item onClick={onExportSelectedAsPdf}>
                Export as PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            onClick={async (e) => {
              e.currentTarget.blur();
              await setSelectedRecord(undefined);
              await setSelectedRecordId(undefined);
              await setAddEditAccountModalVisibility(true);
            }}
            variant="primary"
            className="button ss-light-button"
          >
            <img className="filter-reset-svg" src={Add} alt=" "
            />

            <span>Add</span>
          </Button>
        </div>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.ADDRESSBOOK,
        });
      }}
      className="container-fluid pt-3"
    >
      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            defaultValues={filterArray}
            filtersCount={
              filterArray.filter(
                (value) => {
                  return value?.value !== null
                }
              ).length
            }
            setPageNumber={setpageNumber}
            searchPlaceholder="Search for Address, Mobile Number or Email..."
            dashboard={mixpanel_contants.ADDRESSBOOK}
            onReset={() => {
              setpageNumber(1);
              setsearchData(null);
              navigateToMainPage();
              setdropVal({
                vendor: "",
                sport: "",
                team: "",
              });
            }}
            onSubmit={filterNavigation}
            renderAdvancedPanel={(register, handleSubmit, errors, control) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Address
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Address"
                        {...register("address")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="pt-3" as={Row}>
                    <Form.Label column sm="4">
                      Mobile Number
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Mobile Number"
                        {...register("mobileNumber")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      City
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="City"
                        {...register("city")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Postal Code
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Postal Code"
                        {...register("postalCode")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      State
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="State"
                        {...register("state")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Email
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        {...register("email")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      <CollapsibleTable
        loading={loading}
        nextPage={nextPage}
        previousPage={previousPage}
        paramerterNavigation={true}
        headerName="ADDRESS BOOK"
        paging={paging}
        pageType="accounts"
        columns={addressCol}
        page={page}
        data={addressList || []}
        sortDir={sortDir}
        sortField={sortField}
        setSortField={setSortField}
        setSortDir={setSortDir}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        tabs={[]}
        dropdownState={dropdownState}
        setDropdownState={setDropdownState}
      />

      <AddEditAddressModal
        isVisible={addAccountModalVisible}
        minorLoading={minorLoading}
        setMinorLoading={setMinorLoading}
        handleClose={() => setAddEditAccountModalVisibility(false)}
        onSubmit={onEntrySubmitted}
        selectedRecord={selectedRecord}
      />

      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        minorLoading={minorLoading}
        setMinorLoading={setMinorLoading}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDeleteAccount(selectedRecordId)}
      />
    </div>
  );
};

export default withLoader(AddressBook);
