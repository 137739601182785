import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import "../../shared/styles/ModalStyles.scss";
import { Link } from "react-router-dom";
import { Button, Dropdown, Form } from "react-bootstrap";
import Reset from "../../images/reset.svg";
//reducers
import {
  syncSkyboxVendors,
  exportSelectedAsCSVVendor,
  updateVendors,
  exportSelectedAsPdfVendor,
  addVendor,
  downloadTemplateVendor,
  deleteVendor,
  exportPdfVendors,
  exportCSVVendors,
} from "../../data/reducers/vendors/vendors.reducer";
import {
  errorMessage,
  successMessage,
} from "../../data/reducers/alerts/alert.reducer";

//components
import { formatMoney } from "../../utils/formatter/currencyFormatter";
import { withLoader } from "../../utils/hoc/withLoader";
import AddEditVendorModal from "./modals/AddEditVendorModal";
import DeleteIcon from "../../images/delete.svg";
// images
import Export from "../../images/export.svg";
import EditIcon from "../../images/edit.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import AddVendorModal from "./modals/AddVendorModal";
import Add from "../../images/add_blue.svg";
import UploadEmail from "../../images/UploadEmail.svg";
import Download from "../../images/download.svg";
import UploadFileModal from "./modals/UploadFileModal";
import DeleteConfirmationModal from "../../shared/components/modals/DeleteConfirmationModal";
import {
  Mixpanel,
  mixpanel_button_name,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../mixpanel";
import {
  fetchVendors,
  fetchVendorsListForDropdown,
  flushVendors,
} from "../../data/reducers/vendors/cached-vendors.reducer";
import VendorsFilterPanel from "./VendorsFilterPanel";
import CollapsibleTable from "../accounts/collapsible-table";
import Loader from "../../shared/components/loader/Loader";
import { runRules } from "../../data/reducers/transactions/transactions.reducer";
import Run from "../../images/run.svg";
import VendorDashboardCards from "./VendorDashboardCards";
import { flushVendorsCards } from "../../data/reducers/vendors/cached-vendors-cards.reducer";
const getValidFilters = (filters) => {
  const arr = Object.entries(filters).filter(([key, value]) => {
    if (key === "PageNumber") {
      return value !== 1;
    }
    if (key === "PageSize") {
      return value !== 40;
    }
    if (key === "CategoryId") {
      return value !== 0;
    }
    if (key === "SortDir") {
      return value !== "Ascending";
    }
    if (key === "SortField" || key === "searchString" || key === "Id") {
      return value !== "";
    }
    return true;
  });
  return Object.fromEntries(arr);
};
const searchQuery = () => {
  var array = window.location.search.slice(1).split("&&");
  var query = "";
  for (let i = 1; i < array.length; i++) {
    query += "&&" + array[i];
  }
  return query;
};

const VendorsDashboard = ({ setBusy }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [filters, setFilters] = useState({
    searchString: params.get("searchString") || "",
    Id: Number(params.get("Address")) || "", //Vendor ID
    CategoryId: Number(params.get("categoryId")) || 0,
    PageNumber: Number(params.get("PageNumber")) || 1,
    PageSize: Number(params.get("PageSize")) || 40,
    SortDir: params.get("SortDir") || "Ascending",
    SortField: params.get("SortField") || "",
  });

  const previousPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage - 1,
    });
  };

  const nextPage = () => {
    setFilters({
      ...filters,
      PageNumber: paging.currentPage + 1,
    });
  };

  const {
    data: cachedVendorsObject,
    error: vendorsFetchError,
    loading,
  } = useSelector((state) => state.cachedVendors);

  useEffect(() => {
    if (vendorsFetchError) {
      dispatch(flushVendors());
    }
  }, []);

  const { vendors, paging, timestamp } = useMemo(() => {
    const queryParams = new URLSearchParams(getValidFilters(filters));
    history.push(`/vendors?${queryParams}`);
    const data = cachedVendorsObject?.[JSON.stringify(filters)];
    if (!data) {
      return { vendors: undefined, paging: undefined };
    }
    return data;
  }, [cachedVendorsObject, filters]);

  useEffect(() => {
    if (vendorsFetchError) {
      dispatch(
        errorMessage(vendorsFetchError?.message ?? "Something went wrong")
      );
    } else if (!vendors) {
      dispatch(fetchVendors(filters));
      history.push(`/vendors?page=${Number(pageNumber) + 1}${searchQuery()}`);
      setpageNumber(Number(pageNumber + 1));
    }
  }, [vendorsFetchError, vendors, filters]);

  //---------------------------------------------------------------------------------------------------------------//

  const [minorLoading, setMinorLoading] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  //usestates
  const [searchData, setsearchData] = useState(null);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [addEditVendorModal, setAddEditVendorModal] = useState(false);
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  //selectors
  const typeList = useSelector((state) => state.vendors).category;
  const syncDate = useSelector((state) => state.vendors).syncDate;
  const [syncLoading, setSyncLoading] = useState(false);
  const convertUTCDateTimeToLocalDateTime = (dateString, options = {}) => {
    const date = new Date(`${dateString} UTC`); // Append 'Z' to treat as UTC
    return date.toLocaleString();
  };
  const localTime = convertUTCDateTimeToLocalDateTime(syncDate);

  const [cardError, setCardError] = useState(null);
  const onEntrySubmitted = async (data) => {
    try {
      setMinorLoading("edit")
      const response = await dispatch(updateVendors(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setAddEditVendorModal(false);
        dispatch(successMessage("Vendor Updated Successfully"));
        resetHandler();
        // dispatch(fetchVendorsListForDropdown());
        // await fetchVendorsData();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false)
    }
  };

  // add custom vendor data
  const handleAddVendor = async (data) => {
    setMinorLoading("add");
    const payload = {
      name: data?.name,
      accountUrl: data?.accountUrl ?? null,
      otherUrl: data.otherUrl ?? null,
      categoryId: data?.categoryId ?? typeList.find((x) => x.type === "No Category")?.id,
    };

    try {
      const response = await dispatch(addVendor(payload));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        setAddVendorModal(false);
        dispatch(successMessage("Vendor Created Successfully"));
        resetHandler();
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false);
    }
  };


  const onDeleteVendor = async (vendorId) => {
    try {
      setBusy(true);
      const response = await dispatch(deleteVendor(vendorId));
      if (response.error) {
        await dispatch(errorMessage(response.error.message));
      } else {
        await dispatch(successMessage("Vendor Deleted Successfully"));
        resetHandler();
      }
    } catch (err) {
      await dispatch(errorMessage(err.message));
    } finally {
      setBusy(false);
      setDeleteConfirmationVisibility(false);
    }
  };

  //export  functions
  const exportSelectedAsCSV = async () => {
    setMinorLoading("export");

    const { PageNumber, SortDir, PageSize, SortField, ...exportFilters } =
      filters;

    const hasFilters = Object.values(exportFilters).some(
      (val) => val !== "" && val !== 0
    );

    if (hasFilters) {
      await dispatch(exportSelectedAsCSVVendor(exportFilters));
    } else {
      await dispatch(exportCSVVendors());
    }
    setMinorLoading(false);
  };

  const onExportSelectedAsPdf = async () => {
    setMinorLoading("export");
    const { PageNumber, SortDir, PageSize, SortField, ...exportFilters } =
      filters;

    const hasFilters = Object.values(exportFilters).some(
      (val) => val !== "" && val !== 0
    );

    if (hasFilters) {
      await dispatch(exportSelectedAsPdfVendor(exportFilters));
    } else {
      await dispatch(exportPdfVendors());
    }
    setMinorLoading(false);
  };

  const downloadTemplate = async () => {
    setMinorLoading("downloadTemplate");
    try {
      await dispatch(downloadTemplateVendor());
    } catch (error) {
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
    } finally {
      setDeleteConfirmationVisibility(false);
    }

    setMinorLoading(false);
  };

  // Run rules
  const callRunRules = async () => {
    try {
      setExportLoading("runRules");
      await dispatch(runRules());
    } catch (e) {
      dispatch(errorMessage("Something went wrong"));
    } finally {
      setExportLoading(false);
    }
  };

  //resetHandler

  //vendor table data
  const vendorsCols = [
    {
      columnName: "Name",
      sortName: "Name",
      sort: true,
      render: (data) => (
        <>
          <Link variant="link" className="ss-link" to={"/vendors/" + data.id}>
            {data?.name}
          </Link>
        </>
      ),
    },
    {
      columnName: "ID",
      columnKey: "id",
      sortName: "Id",
      sort: true,
      render: (data) => <>{data?.id}</>,
    },
    {
      columnName: (
        <>
          Total <br /> Charges
        </>
      ),
      render: (data) => <>{formatMoney(data?.totalCharges)}</>,
    },
    {
      columnName: (
        <>
          Total <br /> Expected
        </>
      ),
      render: (data) => <>{formatMoney(data?.totalExpected)}</>,
    },
    {
      columnName: (
        <>
          Total <br /> Refunds
        </>
      ),
      render: (data) => <>{formatMoney(data?.totalRefunds)}</>,
    },
    {
      columnName: (
        <>
          Past <br /> Charges{" "}
        </>
      ),
      render: (data) => <>{formatMoney(data?.pastCharges)}</>,
    },
    {
      columnName: "Inventory",
      render: (data) => <>{formatMoney(data?.inventory)}</>,
    },
    {
      columnName: "Type",
      render: (data) => {
        return <>{data?.categoryId == 9999 ? "--" : data?.type}</>;
      },
    },
    {
      columnName: "Actions",
      render: (data) => {
        return (
          <small className="table-row-card-actions d-flex">
            <Button
              variant="link"
              className="table-action-button green_bg"
              onClick={() => {
                setSelectedRecordId(data.id);
                setSelectedRecord(data);
                setAddEditVendorModal(true);
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  page: mixpanel_contants.VENDORS,
                  buttonName: mixpanel_button_name.EDIT,
                });
              }}
            >
              <img src={EditIcon} alt="Edit Purchase Orders" />
            </Button>
            {data?.source?.toLowerCase() === "quicktix" && (
              <Button
                onClick={() => {
                  setSelectedRecordId(data.id);
                  setSelectedRecord(data);
                  setDeleteConfirmationVisibility(true);
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    page: mixpanel_contants.VENDORS,
                    buttonName: mixpanel_button_name.DELETE,
                  });
                }}
                variant="link"
                className="table-action-button"
              >
                <img
                  style={{ height: "100%", width: "13px" }}
                  src={DeleteIcon}
                  alt="Delete Purchase Orders"
                />
              </Button>
            )}
          </small>
        );
      },
    },
  ];

  const resetHandler = () => {
    dispatch(flushVendors());
    dispatch(flushVendorsCards());
  };

  const headOptions = () => {
    return (
      <div className="d-flex align-items-center justify-content-end">
        <small className="mx-2">Results per page</small>
        <Form.Group className="">
          <Form.Control
            as="select"
            defaultValue={filters.PageSize}
            onChange={(e) => {
              setFilters({
                ...filters,
                PageSize: Number(e.target.value),
                PageNumber: 1,
              });
            }}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </Form.Control>
        </Form.Group>
      </div>
    );
  };

  //table head options
  const bottomOptions = () => {
    return (
      <div className="p-2 d-flex justify-content-between gap-2">
        {/* left hand side options */}

        <div>
          <Button
            onClick={() => {
              resetHandler();
              dispatch(successMessage("Refresh successful"));
            }}
            variant="primary"
            className="button ss-light-button"
          >
            <img className="filter-reset-svg" src={Reset} alt=" " />
            <span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                Refresh
              </div>
            </span>
          </Button>
          <p
            style={{
              visibility: timestamp == null && "hidden",
              textAlign: "end",
              fontSize: "12px",
              lineHeight: 0,
              paddingTop: "16px",
              color: "#00000090",
            }}
          >
            Last sync {new Date(timestamp).toLocaleDateString()}{" "}
            {new Date(timestamp).toLocaleTimeString()}
          </p>
        </div>

        {/* right hand side options */}
        <div>
          <div className=" d-flex align-items-center justify-content-end">
            <Button
              variant="primary"
              style={{ textWrap: "nowrap", width: "auto" }}
              className="mx-2 ss-light-button"
              disabled={exportLoading === "runRules"}
              onClick={async (e) => {
                e.currentTarget.blur();
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  buttonName: mixpanel_button_name.RUNRULES,
                  page: mixpanel_contants.TRANSACTIONS,
                });
                await callRunRules();
                resetHandler();
              }}
            >
              {exportLoading === "runRules" ? (
                <Loader style={{ marginLeft: "8px" }} />
              ) : (
                <img src={Run} alt=" " />
              )}
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Run Rules
                </div>
              </span>
            </Button>

            <Button
              onClick={() => {
                downloadTemplate();
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  page: mixpanel_contants.VENDORS,
                  buttonName: mixpanel_button_name.DOWNLOAD_TEMPLATE,
                });
              }}
              disabled={minorLoading === "downloadTemplate"}
              className="button ss-light-button"
            >
              <img className="filter-reset-svg" src={Download} alt=" " />

              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Download template
                  {minorLoading === "downloadTemplate" && (
                    <Loader style={{ marginLeft: "8px" }} />
                  )}
                </div>
              </span>
            </Button>

            {/* upload vandor  */}
            <Button
              onClick={async (e) => {
                e.currentTarget.blur();
                setUploadModalVisible(true);
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  page: mixpanel_contants.VENDORS,
                  buttonName: mixpanel_button_name.UPLOAD,
                });
              }}
              className="button ss-light-button"
            >
              <img className="filter-reset-svg" src={UploadEmail} alt=" " />
              <span>Upload</span>
            </Button>

            <Button
              onClick={() => {
                setAddVendorModal(true);
                Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                  page: mixpanel_contants.VENDORS,
                  buttonName: mixpanel_button_name.ADD_VENDOR,
                });
              }}
              variant="primary"
              className="button ss-light-button"
            >
              <img className="filter-reset-svg" src={Add} alt=" " />
              <span>Add </span>
            </Button>

            <Dropdown>
              <Dropdown.Toggle
                className="ss-light-button button"
                id="dropdown-basic"
                disabled={minorLoading === "export"}
              >
                <img className="filter-reset-svg" src={Export} alt=" " />
                <span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    Export
                    {minorLoading === "export" && (
                      <Loader style={{ marginLeft: "8px" }} />
                    )}
                  </div>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    exportSelectedAsCSV();
                    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                      page: mixpanel_contants.VENDORS,
                      buttonName: mixpanel_button_name.EXPORT_SELECTED_AS_CSV,
                    });
                  }}
                >
                  Export as CSV
                </Dropdown.Item>
                <hr className="hr-full" />
                <Dropdown.Item
                  onClick={() => {
                    onExportSelectedAsPdf();
                    Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                      page: mixpanel_contants.VENDORS,
                      buttonName: mixpanel_button_name.EXPORT_SELECTED_AS_PDF,
                    });
                  }}
                >
                  Export as PDF
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Button
              variant="secondary"
              className="ms-2"
              onClick={async () => {
                try {
                  setSyncLoading(true);
                  Mixpanel.track(mixpanel_event_constants.BUTTON_CLICK, {
                    page: mixpanel_contants.VENDORS,
                    buttonName: mixpanel_button_name.SYNC_SKYBOX,
                  });
                  await dispatch(syncSkyboxVendors());
                  await flushVendors();
                } catch (e) {
                  dispatch(errorMessage(e.message ?? "Something went wrong"));
                } finally {
                  setSyncLoading(false);
                }
              }}
            >
              <span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  Sync to SkyBox
                  {syncLoading && <Loader style={{ marginLeft: "8px" }} />}
                </div>
              </span>
            </Button>
          </div>
          <div className="text-end ">
            <small style={{ color: "green" }}>
              {syncLoading
                ? "Synching..."
                : syncDate
                  ? "Last synced on " + localTime
                  : "Not synced yet"}
            </small>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.VENDORS,
        });
      }}
      className="container-fluid pt-3"
    >
      <VendorDashboardCards
        filters={filters}
        totalVendors={paging?.totalItemsCount}
        setCardError={setCardError}
      />

      <VendorsFilterPanel
        filters={filters}
        setFilters={setFilters}
        resetHandler={resetHandler}
        error={vendorsFetchError}
        cardError={cardError}
      />

      <CollapsibleTable
        loading={loading}
        rowLoading={minorLoading}
        nextPage={nextPage}
        previousPage={previousPage}
        paramerterNavigation={true}
        headerName="VENDORS"
        paging={paging}
        pageType="vendors"
        columns={vendorsCols}
        data={vendors || []}
        sortDir={filters.SortDir === "Ascending" || filters.SortDir === ""}
        sortField={filters.SortField}
        onChangeFieldDirection={({ sortField, sortDirection }) => {
          setFilters({
            ...filters,
            SortField: sortField,
            SortDir: sortDirection ? "Ascending" : "Descending",
          });
        }}
        setSortField={() => { }}
        setSortDir={() => { }}
        headOptions={headOptions}
        bottomOptions={bottomOptions}
        setpageNumber={() => { }}
      />

      <AddEditVendorModal
        isVisible={addEditVendorModal}
        minorLoading={minorLoading}
        setMinorLoading={setMinorLoading}
        handleClose={() => setAddEditVendorModal(false)}
        onSubmit={onEntrySubmitted}
        selectedRecord={selectedRecord}
      />

      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() => onDeleteVendor(selectedRecordId)}
        customMessage={
          "Matched charges will be reset for this vendor, are you sure you want to delete?"
        }
      />

      <AddVendorModal
        isVisible={addVendorModal}
        minorLoading={minorLoading}
        setMinorLoading={setMinorLoading}
        handleClose={() => setAddVendorModal(false)}
        handleAddVendor={handleAddVendor}
      />
      {uploadModalVisible && (
        <UploadFileModal
          setBusy={setBusy}
          isVisible={uploadModalVisible}
          handleThisClose={() => setUploadModalVisible(false)}
          onUploadCompleted={() => {
            // onSubmit(searchData);
          }}
        />
      )}
    </div>
  );
};

export default withLoader(VendorsDashboard);
