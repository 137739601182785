import React from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Container,
  InputGroup,
  Table,
} from "react-bootstrap";
import DeleteIcon from "../../../../../../images/delete.svg";
import { useEffect, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import warninginfo from "../../../../../../images/warninginfo.svg"
import right from "../../../../../../images/right.svg"
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import _, { cloneDeep } from "lodash";
import {
  formatDate,
  formatPlanDate,
  inputDate,
} from "../../../../../../utils/formatter/dateFormatter";
import { getTransactionsForPaymentPlan, setPurchasePaymentDetails } from "../../../../../../data/reducers/purchaseOrders/po.reducer";
import { formatMoney, formatMoneyWithCommas, handleMoneyChangeWithCommansForValue, handleMoneyChangeWithCommas } from "../../../../../../utils/formatter/currencyFormatter";
import ChargesForPaymentPlanModal from "../../../../modals/ChargesForPaymentPlanModal";
import { withLoader } from "../../../../../../utils/hoc/withLoader";

const custom_styles = {
  font_label: {
    fontSize: "14px",
    color: "#404040",
  },
  add_btn: {
    width: "32px",
    height: "32px",
  },
  form_input: {
    height: "32px",
    zIndex: 1, // Set zIndex to 1
    boxShadow: "none", // Remove box shadow
    // borderColor: "initial", // Keep the border color unchanged
  },
};

const InvoicePurchaseDetails = ({ selectedInvoice, setBusy }) => {
  // setup react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control
  } = useForm();

  const dispatch = useDispatch();

  const purchaseOrderData = useSelector((state) => state.purchaseOrder);
  const { selectedAccountDetails } = useSelector((state) => state.accounts);
  const { globalInvoiceType, allPurchaseOrderDetails, globalFeeInputs } = useSelector(
    (state) => state.purchaseOrder
  );
  const { ticketDetails } = useSelector((state) => state.accounts);
  const [chargesModalVisible, setChargesModalVisible] = useState(false);
  const [purchaseDetails, setPurchaseDetails] = useState(
    purchaseOrderData?.purchasePaymentDetails?.purchaseDetails ?? []
  );
  const [isEdit, setIsEdit] = useState(false);
  const [countZero, setCountZero] = useState({});
  const [totalAmount, setTotalAmount] = useState("");
  const [focusedindex, setFocusedIndex] = useState(-1);
  const [array, setArray] = useState([]);
  const [unmatchedCharges, setUnmatchedCharges] = useState([])
  const [shouldPaymentPlanIdBeShown, setShouldPaymentPlanIdBeShown] =
    useState(false);
  const [isPaymentPlanVisible, setPaymentPlanVisible] = useState(null);
  const [paymentPlanIndex, setPaymentPlanIndex] = useState(null)
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(null);

  useEffect(() => {
    const totalTicketPrice = ticketDetails.TableData?.reduce((sum, ticket) => sum + (parseFloat(ticket.price) || 0), 0);

    // Calculate the sum of 'code' from the second object (fees)
    const totalFees = globalFeeInputs?.reduce((sum, fee) => sum + (fee.code || 0), 0);
    if (!allPurchaseOrderDetails?.purchaseOrder?.description) {
      dispatch(
        setPurchasePaymentDetails({
          data: {
            ...purchaseOrderData?.purchasePaymentDetails,
            description: `${selectedAccountDetails?.name}  ${ticketDetails?.sport?.label}  ${ticketDetails?.team?.label}  ${ticketDetails?.season?.label}`,
            totalScheduled: totalFees + totalTicketPrice,
          },
        })
      );
    }
    else {

      dispatch(
        setPurchasePaymentDetails({
          data: {
            ...purchaseOrderData?.purchasePaymentDetails,
            totalScheduled: totalFees + totalTicketPrice,
          },
        })
      );
    }



  }, []);

  // settings the payment paln when there is no data already in the payment plan state
  useEffect(() => {
    if (
      purchaseOrderData?.PaymentPlan?.length > 0 &&
      isPaymentPlanVisible == null
    ) {
      setPaymentPlanVisible(true);
    }

    if (
      array &&
      array.length == 0 &&
      purchaseOrderData?.PaymentPlan?.length > 0
    ) {
      setArray(purchaseOrderData?.PaymentPlan);
    }
  }, [purchaseOrderData?.PaymentPlan]);

  useEffect(() => {
    for (let i = 0; i < array.length; i++) {
      let amountValue = array[i]?.amount;
      if (typeof amountValue === "string") {
        const cleanedAmountString = amountValue
          .replace("USD ", "")
          .replace(/,/g, "")
          .replace("$", "")
          .trim();

        amountValue = cleanedAmountString;
      }
      setValue(`amountPerInterval${i}`, amountValue);
      setValue(`dateInterval${i}`, array[i]?.date?.substr(0, 10));

      if (amountValue > 0) {
        setCountZero((prev) => {
          const updatedCountZero = { ...prev };
          delete updatedCountZero[i];

          return updatedCountZero;
        });
      }
    }
    setValue(
      "period",
      Math.max(0, array.length - (isAmountPaidNotNan() ? 1 : 0))
    );

    calculateTotal();
    dispatch(setPurchasePaymentDetails({ calculated: array }));

    //updating if we want to show the paymentPlansId or not

    if (array?.filter((x) => x?.paymentPlansId).length > 0) {
      setShouldPaymentPlanIdBeShown(true);
    } else {
      setShouldPaymentPlanIdBeShown(false);
    }
  }, [array]);

  useEffect(() => {
    const purchasePaymentDetails = purchaseOrderData?.purchasePaymentDetails;
    if (purchasePaymentDetails) {
      setValue(
        `amount`,

        isNaN(purchasePaymentDetails.amount)
          ? 0
          : purchasePaymentDetails.amount

      );
      setValue(`dateInterval`, purchasePaymentDetails.startDate);
      setValue("period", purchasePaymentDetails.period);
      setValue("installment", purchasePaymentDetails.installment);
    }
  }, [purchaseOrderData.purchasePaymentDetails]);

  const handleInputChange = (name, value) => {
    setValue(name, value); // Update the form value in the hook form
    const updatedValues = getValues();
    dispatch(
      setPurchasePaymentDetails({
        data: {
          ...purchaseOrderData?.purchasePaymentDetails,
          ...updatedValues,
        },
      })
    ); // Dispatch with updated form values
  };

  const handleDateChange = (index, value) => {
    let arr = _.cloneDeep(array);
    arr[index].date = value;
    setArray(arr);
  };

  const isAmountPaidNotNan = () => {
    return !isNaN(
      parseFloat(purchaseOrderData?.purchasePaymentDetails?.amountPaid)
    );
  };

  const onDetailSubmit = () => {
    const data = purchaseOrderData?.purchasePaymentDetails;
    // Date
    let startDate;
    // Amount
    const sum = 0;
    const arr = [];
    startDate = data?.startDate;
    for (
      let i = 0;
      i < parseFloat(data?.period) + (isAmountPaidNotNan() ? 1 : 0);
      i++
    ) {
      if (i == 0) {
        //if amount paid today given then keep today's date as the first payment plan date.
        if (isAmountPaidNotNan()) {
          startDate = new Date();
        } else {
          startDate = new Date(data?.startDate);
        }
        startDate = startDate.toLocaleDateString("en-US", {
          timezone: "America/New_York",
        });
      } else {
        startDate = new Date(startDate);
        // if (data?.paymentInterval === "start") {
        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          1
        );
        // } else if (data?.paymentInterval === "mid") {
        //   startDate = new Date(
        //     startDate.getFullYear(),
        //     startDate.getMonth() + 1,
        //     15
        //   );
        // } else if (data?.paymentInterval === "end") {
        //   startDate = new Date(
        //     startDate.getFullYear(),
        //     startDate.getMonth() + 2,
        //     0
        //   );
        //   startDate = new Date(
        //     startDate.getTime() - startDate.getTimezoneOffset() * 60000
        //   );
        // }
      }
      arr.push({
        amount: parseFloat(
          isAmountPaidNotNan() && i == 0
            ? purchaseOrderData?.purchasePaymentDetails?.amountPaid
            : data?.installment
        ),
        date: formatPlanDate(startDate),
      });
      setValue(`amountPerInterval${i}`, data?.installment);
      setValue(`dateInterval${i}`, startDate);
    }
    setArray([...arr]);
    setTotalAmount(parseFloat(data?.amount) - sum);
    setPaymentPlanVisible(true);
  };

  // When any data in the form changes
  const calculateTotal = () => {
    let sum = 0;
    for (let j = 0; j < parseFloat(array.length); j++) {
      if (getValues(`amountPerInterval${j}`) == "") sum += 0;
      else sum += parseFloat(getValues(`amountPerInterval${j}`));
    }
    if (isNaN(sum)) setValue("amount", "");
    else setValue("amount", Math.round(sum));
  };

  const onAdd = () => {
    const arr = _.cloneDeep(array);
    let startDate;
    const paymentInterval = getValues("paymentInterval");
    startDate = new Date(getValues(`dateInterval${array.length - 1}`));
    if (paymentInterval === "start") {
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        1
      );
    } else if (paymentInterval === "mid") {
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        15
      );
    } else if (paymentInterval === "end") {
      startDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 2,
        0
      );
      startDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      );
    }
    setValue(`dateInterval${array.length}`, formatPlanDate(startDate));
    setValue(`amountPerInterval${array.length}`, 0);
    calculateTotal();
    arr.push({
      amount: parseFloat(0),
      date: formatPlanDate(startDate),
    });
    setArray([...arr]);
  };

  const onDelete = (i) => {
    const arr = _.cloneDeep(array);
    if (i !== -1) arr.splice(i, 1);
    setArray([...arr]);
    // calculateTotal()
  };

  const validateSubmit = async (data) => {
    const plans = [];
    let sum = 0;
    for (let i = 0; i < parseInt(data?.period); i++) {
      const date = data["dateInterval" + i];
      let amount = data["amountPerInterval" + i];
      if (amount != "" && amount != 0) {
        amount = parseFloat(amount);
        sum += amount;
        plans.push({
          amount,
          paymentDate: date,
        });
      }
    }

    plans.sort((a, b) => a.paymentDate.localeCompare(b.paymentDate));
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (purchaseOrderData?.purchasePaymentDetails?.amountPaid) {
      handleInputChange("remember", true);
    }
  }, [purchaseOrderData?.purchasePaymentDetails?.amountPaid]);

  return (
    <div className="p-4 pb-0 d-flex flex-column gap-4">
      <Form>
        <div className="d-flex flex-column gap-3">
          <Row className="">
            <Col>
              <Form.Group as={Row}>
                <Form.Label
                  column
                  sm="4"
                  style={{ ...custom_styles.font_label }}
                >
                  Description<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col sm="7">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      value={
                        purchaseOrderData?.purchasePaymentDetails
                          ?.description ?? ""
                      }
                      onChange={(e) => {
                        dispatch(
                          setPurchasePaymentDetails({
                            data: {
                              ...purchaseOrderData?.purchasePaymentDetails,
                              description: e.target.value,
                            },
                          })
                        );
                      }}
                      className="rounded-end"
                      style={{ ...custom_styles.form_input }} // Handle onChange event
                    />
                    <Form.Control.Feedback type="invalid">
                      Description is required.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row}>
                <Form.Label
                  column
                  sm="5"
                  style={{ ...custom_styles.font_label }}
                >
                  Purchase Order Date<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    type="date"
                    defaultValue={inputDate()}
                    style={{
                      ...custom_styles.form_input,
                      textTransform: "uppercase",
                    }}
                    value={
                      purchaseOrderData?.purchasePaymentDetails
                        ?.purchaseOrderDate ??
                      dispatch(
                        setPurchasePaymentDetails({
                          data: {
                            ...purchaseOrderData?.purchasePaymentDetails,
                            purchaseOrderDate: inputDate(),
                          },
                        })
                      )
                    }
                    onChange={(e) => {
                      dispatch(
                        setPurchasePaymentDetails({
                          data: {
                            ...purchaseOrderData?.purchasePaymentDetails,
                            purchaseOrderDate: e.target.value,
                          },
                        })
                      );
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Purchase Order Date is required.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          <Row className="">
            <Col>
              <Form.Group as={Row}>
                <Form.Label
                  column
                  sm="4"
                  style={{ ...custom_styles.font_label }}
                >
                  Total Invoice Amount<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col sm="7">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text
                        style={{ ...custom_styles.form_input }}
                        className="rounded-0 rounded-start"
                      >
                        $
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="text"
                      // step="0.0001"
                      placeholder="0.00"
                      onBlur={() =>
                        purchaseOrderData?.purchasePaymentDetails
                          ?.totalScheduled
                          ? dispatch(
                            setPurchasePaymentDetails({
                              data: {
                                ...purchaseOrderData?.purchasePaymentDetails,
                                totalScheduled: parseFloat(
                                  purchaseOrderData?.purchasePaymentDetails
                                    ?.totalScheduled
                                ).toFixed(2),
                              },
                            })
                          )
                          : null
                      }
                      className="rounded-end"
                      style={{ ...custom_styles.form_input }}
                      value={
                        formatMoneyWithCommas(purchaseOrderData?.purchasePaymentDetails
                          ?.totalScheduled) ?? ""
                      }
                      onChange={(e) => {
                        let value = e.target.value.replace(/^\$/, ''); // Remove $ if pasted

                        value = handleMoneyChangeWithCommansForValue(value);
                        dispatch(
                          setPurchasePaymentDetails({
                            data: {
                              ...purchaseOrderData?.purchasePaymentDetails,
                              totalScheduled: value,
                            },
                          })
                        );
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Total Scheduled is required.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Label
                column
                sm="5"
                style={{ ...custom_styles.font_label }}
              ></Form.Label>
              <Form.Group as={Row}>
                <Col sm="7"></Col>
              </Form.Group>
            </Col>
          </Row>
          {globalInvoiceType != 4 &&
            globalInvoiceType != 3 &&
            globalInvoiceType != 5 ? (
            <>
              <Row>
                <Col>
                  <Form.Group className="d-flex align-items-center">
                    <Form.Check
                      className="remember-forgotpass"
                      type="checkbox"
                      id="pmt"
                      {...register("remember")}
                      defaultValue={getValues("remember")}
                      onChange={(e) => {
                        handleInputChange("remember", e.target.checked);
                        if (!e.target.checked) {
                          setArray([]);
                          handleInputChange("amountPaid", "");
                          setPaymentPlanVisible(false);
                        }
                      }}
                    />
                    <label
                      htmlFor="pmt"
                      style={{ ...custom_styles.font_label, cursor: "pointer" }}
                    >
                      Payment made today
                    </label>
                  </Form.Group>
                </Col>
                <Col>
                  {getValues("remember") && (
                    <Form.Group as={Row}>
                      <Form.Label
                        as={Row}
                        column
                        sm="5"
                        style={{ ...custom_styles.font_label }}
                      >
                        Amount Paid
                      </Form.Label>
                      <Col sm="7">
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text
                              style={{ ...custom_styles.form_input }}
                              className="rounded-0 rounded-start"
                            >
                              $
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            // step="0.0001"
                            {...register("amountPaid")}
                            placeholder="0.00"
                            style={{ ...custom_styles.form_input }}
                            onBlur={() =>
                              purchaseOrderData?.purchasePaymentDetails
                                ?.amountPaid
                                ? handleInputChange(
                                  "amountPaid",
                                  Math.round(
                                    purchaseOrderData?.purchasePaymentDetails
                                      ?.amountPaid.toFixed(2)
                                  )
                                )
                                : null
                            }
                            defaultValue={getValues("amountPaid")} // Display the current value
                            onChange={(e) => {
                              // const regex = /^\d+(\.\d*)?$/;
                              // if (
                              //   !regex.test(e.target.value) &&
                              //   e.target.value != ""
                              // ) {
                              //   return;
                              // }
                              let value = e.target.value.replace(/^\$/, '');
                              value = handleMoneyChangeWithCommansForValue(value);
                              handleInputChange("amountPaid", value);
                              setArray([]);
                              setPaymentPlanVisible(false);
                            }} // Handle onChange event
                            value={
                              isAmountPaidNotNan()
                                ? formatMoneyWithCommas(purchaseOrderData?.purchasePaymentDetails
                                  ?.amountPaid)
                                : null
                            }
                          />
                        </InputGroup>
                      </Col>
                    </Form.Group>
                  )}
                </Col>
              </Row>
            </>
          ) : null}
        </div>
      </Form>
      {globalInvoiceType != 4 &&
        globalInvoiceType != 3 &&
        globalInvoiceType != 5 ? (
        <>
          <div
            style={{
              backgroundColor: "#dfdfdf",
              height: "1px",
              opacity: "0.5",
              margin: "0",
            }}
          ></div>

          <Form>
            <div className="d-flex flex-column gap-3">
              <span className="text-muted small">Create Payment Plan</span>
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm="4"
                      style={{ ...custom_styles.font_label }}
                    >
                      Amount
                    </Form.Label>
                    <Col sm="7">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            style={{ ...custom_styles.form_input }}
                            className="rounded-0 rounded-start"
                          >
                            $
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          // step="0.0001"
                          {...register("amount")}
                          placeholder="0.00"
                          style={{ ...custom_styles.form_input }}
                          defaultValue={getValues("amount")} // Display the current value
                          onChange={(e) => {
                            const regex = /^\d+(\.\d{0,2})?$/;
                            if (
                              regex.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              handleInputChange(
                                "amount",
                                parseFloat(e.target.value)
                              );
                            } else {
                              let str = e.target.value.toString();
                              let newStr = str.slice(0, -1);
                              let newNum = parseFloat(newStr);
                              handleInputChange("amount", parseFloat(newNum));
                            }
                          }} // Handle onChange event
                        />
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm="5"
                      style={{ ...custom_styles.font_label }}
                    >
                      Start Date
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="date"
                        defaultValue={inputDate()}
                        isInvalid={!!errors.startDate}
                        {...register("startDate", { required: true })}
                        style={{
                          ...custom_styles.form_input,
                          textTransform: "uppercase",
                        }}
                        value={getValues("startDate")} // Display the current value
                        onChange={(e) =>
                          handleInputChange("startDate", e.target.value)
                        } // Handle onChange event
                      />
                      <Form.Control.Feedback type="invalid">
                        Start Date is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="">
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm="4"
                      style={{ ...custom_styles.font_label }}
                    >
                      Installment Amount
                    </Form.Label>
                    <Col sm="7">
                      <InputGroup>
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            style={{ ...custom_styles.form_input }}
                            className="rounded-0 rounded-start"
                          >
                            $
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          type="number"
                          // step="0.0001"
                          isInvalid={!!errors.installment}
                          {...register("installment", { required: !isEdit })}
                          placeholder="0"
                          className="rounded-end"
                          style={{ ...custom_styles.form_input }}
                          defaultValue={getValues("installment")} // Display the current value
                          onChange={(e) => {
                            const regex = /^\d+(\.\d{0,2})?$/;
                            if (
                              regex.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              handleInputChange("installment", e.target.value);
                            } else {
                              let str = e.target.value.toString();
                              let newStr = str.slice(0, -1);
                              let newNum = parseFloat(newStr);
                              handleInputChange("installment", newNum);
                            }
                          }} // Handle onChange event
                        />
                        <Form.Control.Feedback type="invalid">
                          Installment Amount is required.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      column
                      sm="5"
                      style={{ ...custom_styles.font_label }}
                    >
                      Period
                    </Form.Label>
                    <Col sm="7">
                      <Form.Control
                        type="number"
                        min="1"
                        isInvalid={!!errors.period}
                        {...register("period", { required: true })}
                        placeholder="0"
                        style={{ ...custom_styles.form_input }}
                        defaultValue={getValues("period")} // Display the current value
                        onChange={(e) =>
                          handleInputChange("period", e.target.value)
                        } // Handle onChange event
                      />
                      <Form.Control.Feedback type="invalid">
                        Period is required.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                variant="primary"
                className="col-xl-1 gap-2  d-flex align-items-center justify-content-center text-center"
                onClick={(e) => {
                  e.preventDefault();
                  onDetailSubmit();
                }}
                style={{
                  ...custom_styles.form_input,
                  maxWidth: "120px",
                  fontSize: "14px",
                }}
                disabled={
                  purchaseOrderData?.purchasePaymentDetails.installment &&
                    purchaseOrderData?.purchasePaymentDetails.amount &&
                    purchaseOrderData?.purchasePaymentDetails.period
                    ? false
                    : true
                }
              >
                Create
              </Button>
            </div>
          </Form>

          {isPaymentPlanVisible && (
            <div
              style={{
                backgroundColor: "#dfdfdf",
                height: "1px",
                opacity: "0.5",
                margin: "0",
              }}
            ></div>
          )}

          {isPaymentPlanVisible && (
            <div className="d-flex flex-column gap-3">
              <span className="text-muted small">
                Payment Plan{" "}
                {selectedInvoice === 2 ? <label>(Optional)</label> : ""}
              </span>

              <Form onSubmit={handleSubmit(validateSubmit)}>
                <div>
                  <table
                    style={{
                      width: shouldPaymentPlanIdBeShown ? "80%" : "70%",
                      borderColor: "#0000001a",
                    }}
                  >
                    <thead style={{ backgroundColor: "#f5f5f5" }}>
                      <tr style={{ border: "1px solid #0000001a" }}>
                        {shouldPaymentPlanIdBeShown && (
                          <th
                            className="text-center"
                            style={{
                              fontSize: "14px",
                              border: "1px solid #0000001a",
                              width: "33%",
                            }}
                          >
                            Payment Plan Id
                          </th>
                        )}
                        <th
                          className="text-center"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #0000001a",
                            width: shouldPaymentPlanIdBeShown ? "33%" : "50%",
                          }}
                        >
                          Date
                        </th>
                        <th
                          className="text-end px-2 py-2"
                          style={{
                            fontSize: "14px",
                            border: "1px solid #0000001a",
                          }}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isPaymentPlanVisible &&
                        array.map((x, i) => {
                          return (
                            <tr
                              className="position-relative"
                              // style={{ border: "1px solid" }}
                              key={i}
                            >
                              {shouldPaymentPlanIdBeShown && (
                                <td
                                  className="text-center"
                                  style={{
                                    ...custom_styles.font_label,
                                    position: "relative",
                                    border: "1px solid #0000001a",
                                  }}
                                >
                                  <div
                                    style={{
                                      textTransform: "uppercase",
                                      border: "none",
                                      borderRadius: "0px",
                                    }}
                                    readOnly={true}
                                  >
                                    {x?.paymentPlansId
                                      ? x?.paymentPlansId
                                      : "-"}
                                  </div>

                                  {/* {formatDate(x?.date)} */}
                                </td>
                              )}
                              <td
                                className="text-center"
                                style={{
                                  ...custom_styles.font_label,
                                  position: "relative",
                                  border: "1px solid #0000001a",
                                }}
                              >
                                <Form.Control
                                  type="date"
                                  style={{
                                    textTransform: "uppercase",
                                    border: "none",
                                    borderRadius: "0px",
                                  }}
                                  readOnly={i == 0 && isAmountPaidNotNan() || x?.chargesId}
                                  value={x?.date} // Display the current value
                                  onChange={(e) => {
                                    handleDateChange(i, e.target.value);
                                  }} // Handle onChange event
                                />

                                {/* {formatDate(x?.date)} */}
                              </td>

                              <td
                                className="text-end "
                                style={{
                                  ...custom_styles.font_label,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  border:
                                    focusedindex === i
                                      ? "2px solid #022E6F"
                                      : "1px solid #0000001a",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <Controller
                                  name={`amountPerInterval${i}`}
                                  control={control}
                                  defaultValue=""
                                  render={({ field: { onChange, onBlur, value } }) => (
                                    <Form.Group className="position-relative w-100">
                                      <Form.Control
                                        value={formatMoneyWithCommas(value)}
                                        min={0}
                                        key={i}
                                        disabled={
                                          i == 0 &&
                                          purchaseOrderData?.purchasePaymentDetails
                                            ?.amountPaid
                                          ||
                                          x?.chargesId
                                        }
                                        // value={array[i].amount}
                                        onChange={(e) => {
                                          const value = handleMoneyChangeWithCommas(e);
                                          onChange(value);
                                          let arr = _.cloneDeep(array);
                                          arr[i].amount =
                                            value;
                                          setArray(arr);
                                          calculateTotal();
                                        }}
                                        type="text"
                                        className="border-0 bg-transparent rounded-0 text-end"
                                        style={{ outline: "0", boxShadow: "none" }}
                                        onBlur={() => {
                                          const result = formatMoneyWithCommas(value) ? formatMoneyWithCommas(value) : 0;
                                          onChange(result);
                                          let arr = _.cloneDeep(array);
                                          arr[i].amount =
                                            result;
                                          setArray(arr);
                                          calculateTotal();
                                          setFocusedIndex(-1)
                                        }}
                                        onFocus={() => setFocusedIndex(i)}
                                      />
                                      <i className="fas fa-dollar-sign position-absolute top-50 start-0 translate-middle-y ms-2"></i>
                                    </Form.Group>)}
                                />
                              </td>

                              {isAmountPaidNotNan() && i == 0 ? (
                                ""
                              ) : (
                                <div
                                  style={{
                                    position: "absolute",
                                    right: x?.chargesId ? "-205px" : !parseFloat(x?.amount) ? "-130px" : "-26px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                  }}
                                  className="text-center"
                                >
                                  <img
                                    onClick={() => onDelete(i)}
                                    src={DeleteIcon}
                                    alt="Delete"
                                    style={{
                                      cursor: "pointer",
                                      width: "20px",
                                      height: "20px",
                                      objectFit: "contain",
                                    }}
                                  />
                                  {
                                    (!parseFloat(x?.amount) || parseFloat(x?.amount) == 0 || x?.chargesId) ? (
                                      <img
                                        src={x?.chargesId ? right : warninginfo}
                                        alt="success"
                                        style={{
                                          cursor: "pointer",
                                          width: "20px",
                                          height: "20px",
                                          objectFit: "contain",
                                        }}
                                        className="mx-2"
                                      />
                                    )
                                      :
                                      null
                                  }

                                  {
                                    !x?.amount || x?.amount == 0 || x?.chargesId ? (
                                      <span
                                        style={{
                                          color: x.chargesId ? "gray" : "rgb(2, 80, 160)",
                                          fontWeight: "500",
                                          cursor: "pointer",
                                        }}
                                        className="mx-2"
                                        onClick={async () => {
                                          if (x.chargesId) {
                                            return;
                                          }
                                          setChargesModalVisible(true);
                                          setPaymentPlanIndex(i);
                                          setSelectedPaymentPlan(x);
                                          if (x?.chargesId) {
                                            setBusy(true)
                                            await dispatch(
                                              getTransactionsForPaymentPlan({
                                                accountId: selectedAccountDetails?.accountId,
                                                vendorId: selectedAccountDetails?.vendorId,
                                                chargeId: [...unmatchedCharges, x?.chargesId ?? ''],
                                                paymentPlanId: x?.id ?? '',
                                                date: x?.date,
                                                searchStr: ''
                                              })
                                            );
                                            setBusy(false)

                                          }
                                          else {
                                            setBusy(true)
                                            await dispatch(
                                              getTransactionsForPaymentPlan({
                                                accountId: selectedAccountDetails?.accountId,
                                                vendorId: selectedAccountDetails?.vendorId,
                                                chargeId: [...unmatchedCharges, ''],
                                                paymentPlanId: x?.id ?? '',
                                                date: x?.date,
                                                searchStr: ''
                                              })
                                            );
                                            setBusy(false)
                                          }

                                        }}
                                      >
                                        {
                                          x?.chargesId ?
                                            'Matched'
                                            :
                                            'Match'
                                        }
                                      </span>
                                    ) : null
                                  }
                                  {
                                    x?.chargesId ? (
                                      <span
                                        style={{
                                          color: "rgb(2, 80, 160)",
                                          fontWeight: "500",
                                          cursor: "pointer",
                                        }}
                                        className="mx-2"
                                        onClick={async () => {
                                          setChargesModalVisible(true);
                                          setPaymentPlanIndex(i);
                                          setSelectedPaymentPlan(x);
                                          if (x?.chargesId) {
                                            setBusy(true)
                                            await dispatch(
                                              getTransactionsForPaymentPlan({
                                                accountId: selectedAccountDetails?.accountId,
                                                vendorId: selectedAccountDetails?.vendorId,
                                                chargeId: [...unmatchedCharges, x?.chargesId ?? ''],
                                                paymentPlanId: x?.id ?? null,
                                                date: x?.date,
                                                searchStr: ''
                                              })
                                            );
                                            setBusy(false)

                                          }
                                          else {
                                            setBusy(true)
                                            await dispatch(
                                              getTransactionsForPaymentPlan({
                                                accountId: selectedAccountDetails?.accountId,
                                                vendorId: selectedAccountDetails?.vendorId,
                                                chargeId: [...unmatchedCharges, ''],
                                                paymentPlanId: x?.id ?? null,
                                                date: x?.date,
                                                searchStr: ''
                                              })
                                            );
                                            setBusy(false)
                                          }
                                        }}
                                      >
                                        View
                                      </span>
                                    ) : null
                                  }

                                </div>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <span style={{ fontSize: "12px", color: "#a7a7a7" }}>
                    Note: Click on the value in the cell to make adjustments;
                    changes will be auto-saved.
                  </span>
                </div>
              </Form>
              <div className="d-flex justify-content-start ">
                <Button
                  type="submit"
                  variant="primary"
                  className="col-xl-1 gap-2  d-flex align-items-center justify-content-center text-center"
                  onClick={onAdd}
                  style={{
                    ...custom_styles.form_input,
                    minWidth: "120px",
                    fontSize: "14px",
                  }}
                >
                  <span className="text-center align-items-center">+</span> Add
                </Button>
              </div>
              {Object.keys(countZero).length > 0 && (
                <div
                  style={{
                    backgroundColor: "#FCF6D7",
                    padding: "8px 12px 8px 12px",
                    fontSize: "12px",
                  }}
                >
                  <span
                    style={{ fontSize: "20px", color: "#F2994A" }}
                    className=""
                  >
                    <IoWarningOutline />
                  </span>
                  <span>
                    There are <b>{Object.keys(countZero).length} payments</b>{" "}
                    showing <b>$0.00</b>. Please verify and update them by
                    clicking on the specific Amount cell.
                  </span>
                </div>
              )}
            </div>
          )}
        </>
      ) : null}

      {chargesModalVisible && (
        <ChargesForPaymentPlanModal
          isVisible={chargesModalVisible}
          handleClose={() => setChargesModalVisible(false)}
          setArray={setArray}
          paymentPlanIndex={paymentPlanIndex}
          selectedPaymentPlan={selectedPaymentPlan}
          unmatchedCharges={unmatchedCharges}
          setUnmatchedCharges={setUnmatchedCharges}
          array={array}
        />
      )}
    </div>
  );
};

export default withLoader(InvoicePurchaseDetails);
