import Done from "../../../../images/done.svg";
import Not from "../../../../images/notDone.svg";
import { formatMoney } from "../../../../utils/formatter/currencyFormatter";
import { formatDate, formatTableDate } from "../../../../utils/formatter/dateFormatter";
import { formatTime } from "../../../../utils/formatter/timeFormatter";
import { Button } from "react-bootstrap";
import EditIcon from "../../../../images/edit.svg";
import DeleteIcon from "../../../../images/delete.svg";
import { Link } from "react-router-dom";
import TransactionImg from "../../../../images/transPayment.svg";

const charges = (onEdit, onDelete, setSelectedRecord, setmatchedVisibility, matchedVisibility) => {
  return (
    [
      {
        columnName: "Date/Time",
        sort: true,
        sortName: 'Date',
        render: (data) => (
          <>
            {formatDate(data?.dateAndTime)}
            <br />
            {formatTime(data?.dateAndTime)}
          </>
        )
      },
      {
        columnName: "Source Type/Id",
        render: (data) => (
          <>
            {data?.sourceType}
            <br />
            {data?.sourceId}
          </>
        )
      },
      {
        columnName: "Description",
        columnKey: "description",
        sort: true,
        sortName: "Description",
      },
      {
        columnName: "Notes",
        render: (data) => (
          <div>
            {data?.notes?.length > 15 ? data?.notes.substr(0, 7) + '...' : data?.notes}
          </div>
        )
      },
      {
        columnName: "Vendor",
        sort: true,
        sortName: "VendorId",
        render: (data) => (
          <Link
            variant='link'
            className='ss-link'
            to={'/vendors/' + data?.vendorId}>
            {data?.vendorName}
            <br />
            {data?.vendorId ? data?.vendorId : ""}
          </Link>
        ),
      },

      {
        columnName: "Account",
        sort: true,
        sortName: 'AccountId',
        render: (data) => (
          <>
            {data?.autoGenerated === false ?
              <Link
                variant='link'
                className='ss-link'
                to={'/accounts/' + data?.accountId + "?vendorId=" + data?.vendorId}>
                {data?.accountName}<br />
                {data?.accountId == 0 ? "" : data?.accountId}
              </Link> : <span>
                {data?.accountName}<br />
                {data?.accountId == 0 ? "" : data?.accountId}
              </span>}
          </>
        ),
      },
      {
        columnName: "Purchase Order",
        sort: true,
        sortName: 'PurchaseOrderId',
        render: (data) => (
          <>
            {
              data?.id != 0 ?
                < Link
                  variant='link'
                  className='ss-link'
                  to={'/purchases/' + data?.id} >
                  {data?.purchaseOrdersId}
                </Link > : ""
            }
          </>
        ),
      },
      {
        columnName: "Amount",
        sort: true,
        sortName: "Amount",
        render: (data) => (
          <>
            {formatMoney(data?.amount)}
          </>
        )
      },
      {
        columnName: "Matched",
        render: (data) => (
          <small>
            <img src={data?.skyBoxRef != null || data?.skyBoxRef != undefined || data?.skyBoxRef != 0 ? Done : Not} alt="" />
          </small>
        )
      },
      {
        columnName: "Actions",
        render: (data) => (
          <small className="table-row-card-actions d-flex">
            {!data?.autoGenerated && <>
              <Button
                variant='link'
                className='table-action-button align-items-center red_bg'
                onClick={() => {
                  setSelectedRecord(data);
                  setmatchedVisibility(!matchedVisibility);
                }}>
                <img src={TransactionImg} alt='delete' />
              </Button>
              {data?.sourceType == "MANUAL" && <><Button variant="link" className="table-action-button green_bg" onClick={() => onEdit(data)}>
                <img src={EditIcon} alt=" " />
              </Button>
                <Button variant="link" className="table-action-button" onClick={() => onDelete(data)}>
                  <img src={DeleteIcon} alt=" " />
                </Button></>}</>}
          </small>
        )
      },
    ]
  );
};



const paymentPlan = (onEdit, onDelete) => {
  return (
    [
      {
        columnName: "Payment Plan Id",
        sort: true,
        sortName: "PaymentPlan Id",
        render: (data) => (
          <>
            {data?.paymentPlansId ? data?.paymentPlansId : '-'}
          </>
        )
      },
      {
        columnName: "Payment Date",
        sort: true,
        sortName: "PaymentDate",
        render: (data) => (
          <>
            {data?.paymentDate && formatDate(data?.paymentDate)}
          </>
        )
      },
      {
        columnName: "Payment",
        sort: true,
        sortName: "Amount",
        render: (data) => (
          <>
            {data?.amount && formatMoney(data?.amount)}
          </>
        )
      },
      {
        columnName: "Balance",
        render: (data) => (
          <>
            {formatMoney(data?.balance)}
          </>
        )
      },
      {
        columnName: "Matched",
        render: (data) => (
          <>
            <img src={data?.status ? Done : Not} />
          </>
        )
      },
    ]

  );
};

export { charges, paymentPlan };