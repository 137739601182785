import { useEffect, useState } from "react";
import InvoiceProgressBar from "../components/InvoiceProgressBar";
import Loader from "../../../../shared/components/loader/Loader";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  errorMessage,
  successMessage,
} from "../../../../data/reducers/alerts/alert.reducer";
import {
  createInvoicePO,
  removeUploadedInvoice,
  saveInvoiceFile,
  setAdditionalDetails,
  setGlobalMaxStep,
  setIsPOSubmitted,
  setPurchasePaymentDetails,
  setUploadedInvoiceFileName,
  uploadInvoicePoAttachment,
} from "../../../../data/reducers/purchaseOrders/po.reducer";
import {
  createAccount,
  setSelectedAccountDetails,
  setSelectedTicketDetails,
} from "../../../../data/reducers/accounts/accounts.reducers";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { StepComponent, Steps } from "../SectionByType";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { updateAccount } from "../../../../data/reducers/purchaseOrders/cached-po.reducer";
import AddNote from "./tabs/7-preview/AddNote";
import SavePendingCorrectionModal from "../../modals/SavePendingCorrectionModal";
import { constants } from "../../../../config";
import {
  cleanPhoneNumber,
  formatDollarMoney,
} from "../../../../utils/formatter/currencyFormatter";

const Content = ({
  selectedOption1,
  selectedOption2,
  selectedInvoice,
  setStep,
  step,
  purchaseOrderData,
  handleUpdateInvoicePO,
  handleSubmitPoInvoice,
  subtitle,
  nextButtonLoader,
  submitLoading,
  setNextButtonLoader,
}) => {
  const [isPdfUploaded, setIsPdfUploaded] = useState(false);
  const { globalInvoiceType, maxCompletedStep } = useSelector(
    (state) => state.purchaseOrder
  );

  const { id } = useParams();
  const data = useSelector((state) => state.accounts);
  const { isAddFromInvoice } = useSelector((state) => state.purchaseOrder);
  const { accountRejectedData } = useSelector((state) => state.accounts);
  const { selectedAccountDetails } = useSelector((state) => state.accounts);
  const { UploadInvoice, invoiceFile, globalUploadingInvoiceLoader } =
    useSelector((state) => state.purchaseOrder);
  const [isUploadedInvoice, setIsUploadedInvoice] = useState(
    UploadInvoice ? true : false
  );
  const [isUploading, setIsUploading] = useState(globalUploadingInvoiceLoader);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [isPreviewSkipped, setIsPreviewSkipped] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");

  const [isAdmin, setAdmin] = useState(false);
  useEffect(() => {
    const userRole = localStorage.getItem("USER_ROLE");
    setAdmin(userRole === "Admin");
    setCurrentUserEmail(localStorage.getItem(constants.KEY_USER_EMAIL));
  }, []);

  useEffect(() => {
    setIsUploading(globalUploadingInvoiceLoader);
  }, [globalUploadingInvoiceLoader]);

  useEffect(() => {
    setIsUploadedInvoice(UploadInvoice ? true : false);
  }, [UploadInvoice]);

  const handlePoAttachment = () => {
    const id = 4;

    if (!data.poAttachment) {
      return setStep((prev) => prev + 1);
    }
    if (data.poAttachment.type !== "application/pdf") {
      const message = "Please select the pdf only.";
      dispatch(errorMessage(message));
      return;
    }
    dispatch(uploadInvoicePoAttachment({ file: data.poAttachment, id }))
      .then(() => {
        setStep((prev) => prev + 1);
      })
      .catch((error) => {
        dispatch(errorMessage(error?.message ?? "Something went wrong"));
      });
  };

  useEffect(() => {
    if (accountRejectedData) {
      dispatch(
        updateAccount({
          ...accountRejectedData,
          name: selectedAccountDetails?.name,
          accountId: selectedAccountDetails?.accountId,
          email: selectedAccountDetails?.email,
          mobileNo: selectedAccountDetails?.mobileNo,
          addressBookId: selectedAccountDetails?.addressBookId,
          vendorId: selectedAccountDetails?.vendorId,
          dontShowError: true,
        })
      );
    }
  }, [accountRejectedData]);

  const addUpdateHandler = async () => {
    const selectedAccountDetails = data.selectedAccountDetails;

    try {
      setNextButtonLoader(true);
      const accountCreationResponse = await dispatch(
        createAccount({
          vendorId: Number(selectedAccountDetails.vendorId),
          vendorName: selectedAccountDetails.vendorName,
          accountId: Number(selectedAccountDetails.accountId),
          name: selectedAccountDetails.name,
          addressBookId: selectedAccountDetails.addressBookId,
          email: selectedAccountDetails.email,
          // sportId: parseInt(selectedAccountDetails.sportId),
          categoryId: selectedAccountDetails?.categoryId
            ? selectedAccountDetails.categoryId
            : 1,
          accountCredits: selectedAccountDetails?.accountCredits
            ? selectedAccountDetails.accountCredits
            : 0,
          mobileNo: cleanPhoneNumber(selectedAccountDetails?.mobileNo),
          shouldUpdate: true,
        })
      );
      setNextButtonLoader(false);
      dispatch(
        successMessage(
          `Account was ${accountCreationResponse?.payload?.id == 0 ? "updated" : "created"
          } successfully`
        )
      );
    } catch (error) {
      dispatch(errorMessage(error?.message ?? "Something went wrong"));
    }
  };

  const handleCancelPO = () => {
    dispatch(removeUploadedInvoice());
    dispatch(setUploadedInvoiceFileName(null));
    dispatch(setSelectedAccountDetails({}));
    dispatch(setSelectedTicketDetails({}));
    dispatch(setPurchasePaymentDetails({ calculated: {} }));
    dispatch(setPurchasePaymentDetails({ data: {} }));
    dispatch(setAdditionalDetails({}));
    navigate.push("/purchases");
  };

  const handleSaveChanges = () => {
    setIsModalVisible(true); // Show the modal
  };

  const handleModalClose = () => {
    setIsModalVisible(false); // Hide the modal
  };

  const handleConfirm = () => {
    handleSubmitPoInvoice();
  };

  const handleSubmit = (data, confirm) => {
    if (confirm) {
      handleConfirm();
    }
  };

  const handleSkipPreview = (id) => {
    if (globalInvoiceType === 3) {
      handleUpdateInvoicePO(true);
      setStep(4);
    } else {
      handleUpdateInvoicePO(true);
      setStep(5);
    }
  };

  const checkAllRequiredFieldsGiven = () => {
    const account = data?.selectedAccountDetails;
    if (
      !account?.name ||
      !account?.accountId ||
      !account?.email ||
      !account?.addressBookId ||
      !account?.vendorId
    ) {
      return false;
    }

    //if the number of fields in the selectedAccountDetails global state is less then 7 then give a toast to fill all the required fields.
    if (Object.keys(data?.selectedAccountDetails).length < 7) {
      return false;
    }

    //looping over all the fields in the selectedAccountDetails global state.
    for (const [key, value] of Object.entries(data?.selectedAccountDetails)) {
      //we added that key should not be sportId, teamName,sportName and teamId, because these are the fields that are not on the account details page, but we are setting them to be undefined in the global states.
      if (
        key != "sportId" &&
        key != "teamName" &&
        key != "sportName" &&
        key != "teamId" &&
        key != "mobileNo" &&
        (value === undefined || data?.selectedAccountDetails?.address == "")
      ) {
        return false;
      }
    }

    return true;
  };

  const callCreateAccountFunctionWithFieldsCheck = async () => {
    if (!checkAllRequiredFieldsGiven()) {
      return false;
    }
    await addUpdateHandler();
    return true;
  };
  const handleCreatePO = async () => {
    if (data?.selectedAccountDetails) {
      if (!checkAllRequiredFieldsGiven()) {
        return dispatch(errorMessage("Please Fill all the required Fields"));
      }
      await addUpdateHandler(); // in this function we are creating a new account with accountId and vendorId which are there in the selectedAccountDetail global state.
      const feeDetailsInformation = purchaseOrderData?.globalFeeInputs;
      const obj = {
        // description: "string",
        vendorId: data?.selectedAccountDetails?.vendorId,
        accountId: Number(data?.selectedAccountDetails?.accountId),
        PurchaseOrderFeesRequest: feeDetailsInformation
          ? feeDetailsInformation.map((item) => {
            return {
              label: item?.name,
              fees: formatDollarMoney(item?.code),
            };
          })
          : [],

        // totalScheduled: 0,
        // purchaseOrderDate: "2024-08-21T06:10:08.150Z",
        seasonId: 0,
        listSkyboxRefs: [],
        mainInvoiceType: selectedOption1
          ? "Regular Season-Full"
          : selectedOption2
            ? "Post Season"
            : selectedInvoice == 3
              ? "Deposit"
              : selectedInvoice == 4
                ? "Group Purchase"
                : selectedInvoice == 5
                  ? "PSL"
                  : selectedInvoice == 6
                    ? "Ticket Associates"
                    : null,
        subInvoiceType: selectedOption1?.label
          ? selectedOption1?.value
          : selectedOption2?.value
            ? selectedOption2?.value
            : selectedInvoice == 3
              ? "Deposit"
              : selectedInvoice == 4
                ? "Group Purchase"
                : selectedInvoice == 5
                  ? "PSL"
                  : selectedInvoice == 6
                    ? "Ticket Associates"
                    : null,
      };

      const response = await dispatch(createInvoicePO(obj));
      if (response.payload.data.id) {
        navigate.push(
          `/purchase/add-invoice/${response.payload.data.id}/${step + 1}`
        );
        if (purchaseOrderData?.UploadInvoiceFileName) {
          dispatch(
            saveInvoiceFile({ file: invoiceFile, id: response.payload.data.id })
          );
        }
      } else
        return dispatch(
          errorMessage("Failed to create invoice purchase order")
        );

      setStep((prev) => prev + 1);
    }
  };

  const handlePreview = () => {
    const option =
      selectedOption1?.label || selectedOption2?.label || selectedInvoice;
    if (option == -1) {
      return dispatch(errorMessage("Please select the Invoice Type"));
    }

    if (step === 0) {
      setStep(
        Steps(
          selectedOption1?.label
            ? selectedOption1?.value
            : selectedOption2?.label
              ? selectedOption2?.value
              : selectedInvoice
        ).length - 1
      );
    }
  };

  useEffect(() => {
    // set max step when step changes
    if (maxCompletedStep < step) {
      dispatch(setGlobalMaxStep(step));
    }
  }, [step]);

  const HandleNext = () => {
    const option =
      selectedOption1?.label || selectedOption2?.label || selectedInvoice;
    if (option == -1) {
      return dispatch(errorMessage("Please select the Invoice Type"));
    }
    if (step === 0) {
      if (!isNaN(parseInt(id))) {
        handleUpdateInvoicePO();
      } else {
        setStep((prev) => prev + 1);
      }
    }
    if (step === 1) {
      if (!isNaN(parseInt(id))) {
        handleUpdateInvoicePO();
        callCreateAccountFunctionWithFieldsCheck();
      } else {
        handleCreatePO();
      }
    }
    if (step == 2) {
      handleUpdateInvoicePO();
    }
    if (step == 3) {
      handleUpdateInvoicePO();
    }
    if (step == 5) {
      handleUpdateInvoicePO();
      setIsPreviewSkipped(true);
    }
    if (step == 4) {
      // handlePoAttachment();

      handleUpdateInvoicePO();
      setIsPreviewSkipped(true);
    }
  };
  function generateMaskingString(maskingData) {
    let string = "";
    if (!maskingData) {
      return string;
    }

    for (const [key, value] of Object.entries(maskingData)) {
      string += `${key} @ROW[${value}]\n`;
    }

    return string;
  }

  const { ispendingPo } = useSelector((state) => state.purchaseOrder);
  const handleEdit = (id) => {
    history.push(`/purchase/add-invoice/${id}/0`);
  };
  const handleCancel = () => {
    history.push("/pendingPO");
  };

  const [note, setNote] = useState("");

  return (
    <>
      <div
        style={{
          boxShadow: "0px 4px 12px 0px #CCCCCC3D",
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        {/* Breadcrumb */}
        <InvoiceProgressBar
          steps={Steps(
            selectedOption1?.label
              ? selectedOption1?.value
              : selectedOption2?.label
                ? selectedOption2?.value
                : selectedInvoice
          )}
          current={step}
          handleSteps={setStep}
          handleUpdateInvoicePO={handleUpdateInvoicePO}
          callCreateAccountFunctionWithFieldsCheck={
            callCreateAccountFunctionWithFieldsCheck
          }
          setIsPreviewSkipped={setIsPreviewSkipped}
        />

        {/* Content */}
        <div
          style={{
            backgroundColor: "#dfdfdf",
            height: "1px",
            opacity: "0.5",
          }}
        ></div>
        <div
          style={{
            padding: "32px 0",
            backgroundColor: "white",
          }}
        >
          <div className="px-4">
            <label style={{ fontSize: "20px", fontWeight: "600" }}>
              {
                Steps(
                  selectedOption1?.label
                    ? selectedOption1?.value
                    : selectedOption2?.label
                      ? selectedOption1?.value
                      : selectedInvoice
                )[step]
              }
            </label>
            <br />
            <label style={{ fontSize: "14px", color: "#707070" }}>
              {subtitle[step]}
            </label>
          </div>

          {
            StepComponent(
              selectedOption1?.label
                ? selectedOption1?.value
                : selectedOption2?.label
                  ? selectedOption2?.value
                  : selectedInvoice,
              handleUpdateInvoicePO
            )(setStep, selectedInvoice, isPdfUploaded)[step]
          }
        </div>

        <>
          {/* Navigation button */}
          <div
            style={{
              backgroundColor: "#dfdfdf",
              height: "1px",
              opacity: "0.5",
            }}
          ></div>

          <div
            style={{
              padding: "16px",
              backgroundColor: "white",
              borderBottomRightRadius: "12px",
              borderBottomLeftRadius: "12px",
            }}
          >
            {ispendingPo && !isAdmin ? (
              <AddNote id={id} setSteps={setStep} />
            ) : ispendingPo &&
              isAdmin &&
              (step === 5 || (globalInvoiceType === 3 && step === 4)) ? (
              <AddNote id={id} setSteps={setStep} />
            ) : null}

            {ispendingPo &&
              (step === 5 || (globalInvoiceType === 3 && step === 4)) &&
              !isPreviewSkipped ? null : (
              <div className="d-flex w-100 justify-content-between py-2 px-3 bottom-0">
                {/* Back button */}
                {step > 0 &&
                  !(
                    ispendingPo &&
                    isAdmin &&
                    (step === 5 || (globalInvoiceType === 3 && step === 4)) &&
                    currentUserEmail !=
                    purchaseOrderData?.allPurchaseOrderDetails?.account
                      ?.createdBy
                  ) && (
                    <button
                      style={{
                        fontWeight: "500",
                        height: "36px",
                      }}
                      className="btn btn-outline-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setStep((prev) => prev - 1);
                      }}
                    >
                      Back to{" "}
                      {
                        Steps(
                          selectedOption1?.label
                            ? selectedOption1?.value
                            : selectedOption2?.label
                              ? selectedOption2?.value
                              : selectedInvoice
                        )[step - 1]
                      }
                    </button>
                  )}

                {/* Empty div to maintain spacing when no back button */}
                {!step > 0 && <div></div>}

                <div className="ml-auto d-flex">
                  {/* Cancel button - visible for admins only, and not on last step */}
                  {!(
                    ispendingPo &&
                    isAdmin &&
                    (step === 5 || (globalInvoiceType === 3 && step === 4)) &&
                    currentUserEmail !=
                    purchaseOrderData?.allPurchaseOrderDetails?.account
                      ?.createdBy
                  ) && (
                      <button
                        onClick={handleCancelPO}
                        className="btn border-0 me-2 bg-transparent text-primary"
                      >
                        Cancel
                      </button>
                    )}

                  {/* Skip To Preview button */}
                  {step < 5 &&
                    !(globalInvoiceType === 3 && step === 4) &&
                    ispendingPo &&
                    (!isAdmin ||
                      currentUserEmail ==
                      purchaseOrderData?.allPurchaseOrderDetails?.account
                        ?.createdBy) && (
                      <button
                        onClick={() => {
                          handleSkipPreview(id);
                          setIsPreviewSkipped(true);
                        }}
                        className="btn btn-secondary border-3 me-2 bg-transparent text-primary"
                      >
                        Skip To Preview
                      </button>
                    )}

                  {/* Next button */}
                  {step < 5 && !(globalInvoiceType === 3 && step === 4) ? (
                    <button
                      className="btn btn-primary d-flex"
                      onClick={(e) => {
                        e.preventDefault();
                        HandleNext();
                      }}
                      style={{ height: "36px" }}
                      disabled={
                        step === 0
                          ? !purchaseOrderData?.UploadInvoiceFileName ||
                          isUploading
                          : nextButtonLoader
                      }
                    >
                      <span className="me-2">
                        Next:{" "}
                        {
                          Steps(
                            selectedOption1?.label
                              ? selectedOption1?.value
                              : selectedOption2?.label
                                ? selectedOption2?.value
                                : selectedInvoice
                          )[step + 1]
                        }
                      </span>
                      {nextButtonLoader && <Loader className="me-2" />}
                    </button>
                  ) : isPreviewSkipped && ispendingPo ? (
                    !(
                      isAdmin &&
                      (step === 5 || (globalInvoiceType === 3 && step === 4)) &&
                      currentUserEmail !=
                      purchaseOrderData?.allPurchaseOrderDetails?.account
                        ?.createdBy
                    ) && (
                      <button
                        onClick={handleSaveChanges}
                        className="btn btn-primary d-flex"
                      >
                        Save Changes
                      </button>
                    )
                  ) : globalInvoiceType === 3 && step === 4 ? (
                    <button
                      style={{ textWrap: "nowrap" }}
                      onClick={async (e) => {
                        e.preventDefault();
                        await handleSubmitPoInvoice();
                        dispatch(setIsPOSubmitted(true));
                      }}
                      className="btn btn-primary d-flex"
                    >
                      <span className="mx-2">Confirm & Submit</span>
                      {submitLoading && <Loader className="me-2" />}
                    </button>
                  ) : (
                    !ispendingPo && (
                      <button
                        style={{ textWrap: "nowrap" }}
                        onClick={async (e) => {
                          e.preventDefault();
                          await handleSubmitPoInvoice();
                          dispatch(setIsPOSubmitted(true));
                        }}
                        className="btn btn-primary d-flex"
                      >
                        <span className="mx-2">Confirm & Submit</span>
                        {submitLoading && <Loader className="me-2" />}
                      </button>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      </div>
      <SavePendingCorrectionModal
        isVisible={isModalVisible}
        handleVisibility={handleModalClose}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default Content;
