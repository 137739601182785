import {
  errorMessage,
  successMessage,
} from "../../../data/reducers/alerts/alert.reducer";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Container,
} from "react-bootstrap";

import AddEditCardModal from "./modals/AddEditCards";
import { withLoader } from "../../../utils/hoc/withLoader";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

//components
import FilterPanel from "../../../shared/components/panels/filter-panel/FilterPanel";
import CardPanel from "../../../shared/components/panels/card-panel/CardPanel";
import { formatMoney } from "../../../utils/formatter/currencyFormatter";
import QuickTixTable from "../../../shared/components/table/QuickTixTable";
import TableRowCard from "../../../shared/components/table/TableRowCard";
import { columnError } from "../../../shared/components/table/data/error";
import DeleteConfirmationModal from "../../../shared/components/modals/DeleteConfirmationModal";
import WexDetailsModal from "./modals/WexDetailsModal";
import AddEditAddress from "../../addressBook/modals/AddEditAddress";

// images
import Export from "../../../images/export.svg";
import Add from "../../../images/add_blue.svg";
import DeleteIcon from "../../../images/delete.svg";
import EditIcon from "../../../images/edit.svg";

import "../../../shared/styles/ModalStyles.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
  createCard,
  createCardWEX,
  updateCard,
  updateCardWEX,
  deleteCard,
  deleteCardWEX,
  getAllCards,
  filterCard,
  getCardById,
  exportCSV,
} from "../../../data/reducers/cards/cards.routes";
import ModalBreadcrumb from "../../../shared/components/modals/ModalBreadcrumb";
import { createAddress } from "../../../data/reducers/addressBook/addressBook.reducer";
import {
  Mixpanel,
  mixpanel_contants,
  mixpanel_event_constants,
} from "../../../mixpanel";
import { Tune } from "@mui/icons-material";

const CardsDashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(true);

  //---------------------------------------------------------------------------------------------------------------//
  const queryParameters = new URLSearchParams(window.location.search);
  const page = queryParameters.get("page");

  const [filterUrlData, setFilterUrlData] = useState(null);
  const [filterArray, setFilterArray] = useState([]);

  useEffect(() => {
    const cleanup = () => {
      Mixpanel.track(mixpanel_event_constants.PAGE_EXIT, {
        page: mixpanel_contants.CARDS,
      });
    };

    Mixpanel.track(mixpanel_event_constants.PAGE_VIEW, {
      page: mixpanel_contants.CARDS,
    });
    Mixpanel.time_event(mixpanel_event_constants.PAGE_EXIT);
    window.addEventListener("beforeunload", cleanup);
    return () => {
      window.removeEventListener("beforeunload", cleanup);
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (filterUrlData) {
      onSubmit(filterUrlData);
      setFilterArray([
        { key: "searchText", value: filterUrlData?.searchText },
        { key: "ccAccount", value: filterUrlData?.ccAccount },
        { key: "ccNumber", value: filterUrlData?.ccNumber },
        { key: "company", value: filterUrlData?.company },
        { key: "firstName", value: filterUrlData?.firstName },
        { key: "lastName", value: filterUrlData?.lastName },
        { key: "notes", value: filterUrlData?.notes },
        { key: "status", value: filterUrlData?.status },
      ]);
    }
  }, [filterUrlData, sortDir, sortField]);

  //-------------------- Param navigation ---------------//

  useEffect(() => {
    if (page) {
      setpageNumber(page);
      setFilterUrlData({
        searchText: queryParameters.get("searchText"),
        ccAccount: queryParameters.get("ccAccount"),
        ccNumber: queryParameters.get("ccNumber"),
        company: queryParameters.get("company"),
        firstName: queryParameters.get("firstName"),
        lastName: queryParameters.get("lastName"),
        notes: queryParameters.get("notes"),
        status: queryParameters.get("status"),
      });
    } else {
      navigateToMainPage();
    }
  }, [page, location]);

  //--------------------------- filter queries ------------------------//
  const searchQuery = () => {
    var array = window.location.search.slice(1).split("&&");
    var query = "";
    for (let i = 1; i < array.length; i++) {
      query += "&&" + array[i];
    }
    return query;
  };

  //-------------- on page size change --------------//
  const navigateToMainPage = () => {
    history.push(`/fundSources/cards?page=1`);
  };

  const previousPage = () => {
    if (Number(pageNumber) > 1) {
      history.push(
        `/fundSources/cards?page=${Number(pageNumber) - 1}${searchQuery()}`
      );
      setpageNumber(Number(pageNumber - 1));
    }
  };

  const nextPage = () => {
    history.push(
      `/fundSources/cards?page=${Number(pageNumber) + 1}${searchQuery()}`
    );
    setpageNumber(Number(pageNumber + 1));
  };

  //--------------------- filter Navigation -------------------//

  const filterNavigation = (data) => {
    // var url = `/fundSources/cards?page=${data.searchText ? 1 : page}`;
    var url = `/fundSources/cards?page=1`;

    if (data.searchText) {
      url += `&&searchText=${data.searchText}`;
      setpageNumber(1);
    }
    if (data.ccAccount) url += `&&ccAccount=${data.ccAccount}`;
    if (data.ccNumber) url += `&&ccNumber=${data.ccNumber}`;
    if (data.company) url += `&&company=${data.company}`;
    if (data.firstName) url += `&&firstName=${data.firstName}`;
    if (data.lastName) url += `&&lastName=${data.lastName}`;
    if (data.notes) url += `&&notes=${data.notes}`;
    if (data.status) url += `&&status=${data.status}`;

    history.push(url);
  };

  //---------------------- change Page Size and make page=1 by default ---------------------//
  const changePageSize = () => {
    const search = String(window.location.search).slice(1);
    const str = search.split("&&");
    let remainStr = "";
    for (let i = 1; i < str.length; i++) {
      remainStr += "&&" + str[i];
    }
    history.push(window.location.pathname + "?page=1" + remainStr);
  };

  //---------------------------------------------------------------------------------------------------------------//

  //selectors
  const cardsList = useSelector((state) => state.cards).Cards;
  const paging = useSelector((state) => state.cards).paging;

  const [addAccountModalVisible, setAddEditAccountModalVisibility] =
    useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisibility] =
    useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(undefined);
  const [selectedRecord, setSelectedRecord] = useState(undefined);
  const [pageSize, setpageSize] = useState(40);
  const [pageNumber, setpageNumber] = useState(1);
  const [minorLoading, setMinorLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [searchData, setsearchData] = useState(null);
  const [isWEX, setIsWEX] = useState(null);
  const [WexDetailModal, setWexDetailModal] = useState(false);
  const [addEditAddressModal, setAddEditAddressModal] = useState(false);
  const [added, setAdded] = useState(null);

  const fetchCardsData = async () => {
    try {
      setloading(true);
      const data = {
        pageNumber: page,
        pageSize: pageSize,
        sortDir,
        sortField,
      };
      const response = await dispatch(getAllCards(data));
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setloading(false);
    }
  };

  // delete card
  const onDeleteCard = async (cardId) => {
    try {
      setMinorLoading("delete")
      const response = isWEX
        ? dispatch(deleteCardWEX(cardId))
        : dispatch(deleteCard(cardId));

      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        // close modal
        setDeleteConfirmationVisibility(false);

        if (isWEX) {
          setTimeout(() => {
            onSubmit(searchData);
          }, 1000);
        } else onSubmit(searchData);
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false)
    }
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      pageNumber,
      pageSize,
      sortField,
      sortDir,
      searchText: data?.searchText?.trim(),
      teamName: data?.teamName?.trim() || "",
      firstName: data?.firstName?.trim() || "",
      lastName: data?.lastName?.trim() || "",
      ccNumber: data?.ccNumber?.trim() || "",
      ccAccount: data?.ccAccount?.trim() || "",
      notes: data?.notes?.trim() || "",
      status: data?.status?.trim() || "",
      company: data?.company?.trim() || "",
    };
    setloading(true);
    if (
      formData?.searchText ||
      formData?.firstName ||
      formData?.lastName ||
      formData?.ccNumber ||
      formData.ccAccount ||
      formData?.notes ||
      formData?.status ||
      formData?.company
    ) {
      setsearchData(formData);
      await dispatch(filterCard(formData));
    } else {
      setsearchData(null);
      await fetchCardsData();
    }
    setloading(false);
  };

  const addAddress = async (data) => {
    try {
      const message = data.id
        ? `Address Updated Succesfully`
        : `New Address Created Succesfully`;
      setloading(true);
      const action = createAddress(data);
      const response = await dispatch(action);
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      } else {
        if (response.payload.id) {
          setAdded(true);
        }
        dispatch(successMessage(message));
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setloading(false);
      setAddEditAddressModal(false);
      setAddEditAccountModalVisibility(true);
    }
  };

  // // add or update card details
  const onEntrySubmitted = async (data) => {
    try {
      setMinorLoading("add")
      const message = data.id
        ? `Card Updated Successfully`
        : `New Card Created Successfully`;

      if (isWEX) {
        delete data["addressBookId"];
        if (JSON.stringify(selectedRecord) === JSON.stringify(data)) {
          dispatch(successMessage(message));
          setAddEditAccountModalVisibility(false);
        } else {
          const action = data?.id
            ? updateCardWEX({
              id: data.id,
              teamName: data?.teamName?.trim(),
              cardId: data?.cardId?.trim(),
              firstName: data?.firstName?.trim(),
              lastName: data?.lastName?.trim(),
              emailId: data?.emailId?.trim(),
              addressId: data?.addressId,
              notes: data?.notes?.trim(),
              company: data?.company?.trim(),
              nickName: data?.nickName?.trim(),
              triaL387: data?.triaL387?.trim(),
              active: data?.active.toString() === "true" ? true : false,
              cardTypeId: data?.cardTypeId,
            })
            : createCardWEX({
              teamName: data?.teamName.trim(),
              firstName: data?.firstName?.trim(),
              lastName: data?.lastName?.trim(),
              emailId: data?.emailId?.trim(),
              addressId: data?.addressId,
              cardTypeId: data?.cardTypeId,
            });
          const response = await dispatch(action);
          if (response.error) {
            dispatch(errorMessage(response.error.message));
          } else {
            dispatch(successMessage(message));
            dispatch(getCardById(response.payload.id));
            setAddEditAccountModalVisibility(false);
            setWexDetailModal(true);
            onSubmit(searchData);
          }
        }
      } else {
        if (data.addressBookId) delete data.addressBookId;

        if (JSON.stringify(selectedRecord) === JSON.stringify(data)) {
          dispatch(successMessage(message));
          setAddEditAccountModalVisibility(false);
        } else {
          const action = data?.id
            ? updateCard({
              id: data.id,
              teamName: data?.teamName.trim(),
              cardId: data?.cardId?.trim(),
              ccNumber: data?.ccNumber?.trim(),
              ccAccount: data?.ccAccount?.trim(),
              firstName: data?.firstName?.trim(),
              lastName: data?.lastName?.trim(),
              notes: data?.notes?.trim(),
              company: data?.company?.trim(),
              nickName: data?.nickName?.trim(),
              securityCode: parseInt(data?.securityCode),
              triaL387: data?.triaL387?.trim(),
              active: data?.active.toString() === "true" ? true : false,
            })
            : createCard({
              teamName: data?.teamName.trim(),
              cardId: data?.cardId?.trim(),
              ccNumber: data?.ccNumber?.trim(),
              ccAccount: data?.ccAccount?.trim(),
              firstName: data?.firstName?.trim(),
              lastName: data?.lastName?.trim(),
              notes: data?.notes?.trim(),
              company: data?.company?.trim(),
              nickName: data?.nickName?.trim(),
              securityCode: parseInt(data?.securityCode?.trim()),
              triaL387: data?.triaL387?.trim(),
              active: data?.active.toString() === "true" ? true : false,
            });
          const response = await dispatch(action);
          if (response.error) {
            dispatch(errorMessage(response.error.message));
          } else {
            dispatch(successMessage(message));
            setAddEditAccountModalVisibility(false);
            fetchCardsData();
          }
        }
      }
    } catch (e) {
      dispatch(errorMessage(e));
    } finally {
      setMinorLoading(false)
    }
  };

  const cardsCol = [
    {
      columnName: "ID",
      render: (data) => (
        <Link
          variant="link"
          // className="ss-link overflow-hidden"
          className="ss-link "
          to={"/fundSources/cards/" + data.id}
        >
          {data?.cardId}
          {/* {data.cardId?.length > 15
            ? data.cardId.slice(0, 10) + "..."
            : data.cardId} */}
        </Link>
      ),
      sort: true,
      sortName: "CardId",
    },
    {
      columnName: "Name / CC Number",
      columnKey: "name",
      sort: true,
      sortName: "FirstName",
      render: (data) => (
        <>
          {data?.name}
          <br />
          {data?.ccNumber.slice(-4)}
        </>
      ),
    },
    {
      columnName: "Company / Account",
      sort: true,
      sortName: "Company",
      render: (data) => (
        <>
          {data?.company}
          {data?.company !== "" &&
            data?.company !== null &&
            data?.ccAccount !== "" &&
            data?.ccAccount !== null && <br />}
          {data?.ccAccount}
        </>
      ),
    },
    // {
    //   columnName: (
    //     <p className="mb-0">
    //       {" "}
    //       CC <br /> Number{" "}
    //     </p>
    //   ),
    //   columnKey: "ccNumber",
    //   sort: true,
    //   sortName: "CcNumber",
    // },
    {
      columnName: (
        <>
          Security <br /> Code
        </>
      ),
      columnKey: "securityCode",
      sort: true,
      sortName: "securityCode",
    },
    {
      columnName: (
        <>
          Exp <br /> Date{" "}
        </>
      ),
      columnKey: "expDate",
    },
    {
      columnName: (
        <>
          Billing <br /> Address
        </>
      ),
      render: (data) => (
        <>
          {data?.address1} {data?.address2} {data?.city} {data?.state}{" "}
          {data?.postalCode}
        </>
      ),
    },
    // {
    //   columnName: "Team",
    //   columnKey: "teamName",
    //   sort: false,
    //   sortName: "Team",
    // },

    {
      columnName: "Actions",
      render: (data) => (
        <small className="table-row-card-actions d-flex">
          <Button
            variant="link"
            className="table-action-button green_bg"
            onClick={async () => {
              setSelectedRecordId(data.id);

              setSelectedRecord({ ...data, entered: false });

              setAddEditAccountModalVisibility(true);
            }}
          >
            <img src={EditIcon} alt=" " />
          </Button>
          <Button
            variant="link"
            className="table-action-button"
            onClick={() => {
              setSelectedRecordId(data.id);
              setSelectedRecord({ ...data, entered: false });
              setDeleteConfirmationVisibility(true);
            }}
          >
            <img src={DeleteIcon} alt=" " />
          </Button>
        </small>
      ),
    },
  ];

  const headOptions = () => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-end">
          <small>Results per page</small>
          <Form.Group className="ms-2">
            <Form.Control
              as="select"
              defaultValue="40"
              onChange={(e) => {
                setpageSize(e.target.value);
                changePageSize();
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
            </Form.Control>
          </Form.Group>
          <Button
            onClick={async (e) => {
              e.currentTarget.blur();
              await setSelectedRecord(undefined);
              await setSelectedRecordId(undefined);
              await setAddEditAccountModalVisibility(true);
            }}
            variant="primary"
            className="button ss-light-button"
          >
            <img className="filter-reset-svg" src={Add} alt="add icon button" />
            <span>Add</span>
          </Button>
        </div>
      </>
    );
  };

  return (
    <div
      onClick={() => {
        Mixpanel.track(mixpanel_event_constants.RANDOM_BUTTON_CLICK, {
          page: mixpanel_contants.CARDS,
        });
      }}
      className="container-fluid"
    >
      <ModalBreadcrumb
        breadcrumbLinks={[
          {
            name: "Fund Sources",
            active: true,
          },
          {
            name: "Cards",
            active: false,
          },
        ]}
      />

      {/* Filter Panel */}
      <Row>
        <Col>
          <FilterPanel
            dashboard={mixpanel_contants.CARDS}
            defaultValues={filterArray}
            setPageNumber={setpageNumber}
            searchPlaceholder="Search for Name, Account, Card or  CC Number..."
            onReset={() => {
              setpageNumber(1);
              setsearchData(null);
              navigateToMainPage();
              fetchCardsData();
            }}
            onSubmit={filterNavigation}
            renderAdvancedPanel={(register, handleSubmit, errors) => (
              <Row>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      First Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="First Name"
                        {...register("firstName")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Last Name
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Last Name"
                        {...register("lastName")}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      CC Number
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="CC Number"
                        {...register("ccNumber")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Account
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        placeholder="Account"
                        {...register("ccAccount")}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Notes
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Notes"
                        {...register("notes")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label column sm="4">
                      Status
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as={"select"}
                        placeholder="Status"
                        {...register("status")}
                        defaultValue=""
                      >
                        <option value={""}>Select</option>
                        <option value={1}>Active</option>
                        <option value={0}>InActive</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="pt-3">
                    <Form.Label column sm="4">
                      Company
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="Company"
                        {...register("company")}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            )}
          />
        </Col>
      </Row>

      {/* Data Table */}
      <QuickTixTable
        loading={loading}
        previousPage={previousPage}
        nextPage={nextPage}
        paramerterNavigation={true}
        headerName="Cards"
        paging={paging}
        columns={cardsCol}
        data={cardsList || []}
        headOptions={headOptions}
        setpageNumber={setpageNumber}
        sortDir={sortDir}
        setSortDir={setSortDir}
        sortField={sortField}
        setSortField={setSortField}
        renderRow={(rowData, index) => (
          <TableRowCard
            key={rowData.id}
            columns={rowData.nodata ? columnError : cardsCol}
            rowData={rowData}
          />
        )}
      />

      {/* Modal Popups */}
      <AddEditCardModal
        isVisible={addAccountModalVisible}
        handleClose={() => {
          setAddEditAccountModalVisibility(false);
          setAdded(false);
        }}
        minorLoading={minorLoading}
        setMinorLoading={setMinorLoading}
        onSubmit={onEntrySubmitted}
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        isWEX={isWEX}
        setIsWEX={setIsWEX}
        setAddEditAddressModal={setAddEditAddressModal}
        added={added}
        setAdded={setAdded}
      />

      <WexDetailsModal
        isVisible={WexDetailModal}
        handleClose={() => {
          setWexDetailModal(false);
        }}
      />

      <AddEditAddress
        isVisible={addEditAddressModal}
        onSubmit={addAddress}
        handleClose={() => {
          setAddEditAddressModal(false);
          setAddEditAccountModalVisibility(true);
        }}
      />

      <DeleteConfirmationModal
        isVisible={deleteConfirmationVisible}
        minorLoading={minorLoading}
        setMinorLoading={setMinorLoading}
        handleClose={() => setDeleteConfirmationVisibility(false)}
        onConfirmation={() =>
          onDeleteCard(isWEX ? selectedRecord?.cardId : selectedRecordId)
        }
      />
    </div>
  );
};

export default withLoader(CardsDashboard);
